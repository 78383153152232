import React, { Component, Fragment } from 'react';
import './UserView.css';
import Layout from '../../../components/Layout/Layout';
import BaseUser from '../BaseUser';
import Breadcrumb from '../../../components/Breadcrumb';
import { ROUTE_USER_MODULE } from '../../../constant/AppRoutes';

class UserView extends Component
{
    render ()
    {
        const breadcrumbData = [
            {
                label: "Gestión de usuarios",
                to: ROUTE_USER_MODULE,
                isActive: false,
            },
            {
                label: "Detalle de usuario",
                isActive: true,
            }
        ]

        return (
            <>
                <Layout active={ROUTE_USER_MODULE}>
                    <Breadcrumb data={breadcrumbData} />
                    <div className='row my-3'>
                        <div className='col'>
                            <h4 className='mb-0'>Detalle de usuario</h4>
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col'>
                            <div className="card search-filters-container">
                                <div className="card-body">
                                    <BaseUser userId={this.props.params.userId ?? 0} mode="view" navigate={this.props.navigate} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Layout>
            </>
        );
    }
}

export default UserView;