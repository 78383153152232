export const API_EXTERNAL_SYSTEM_BASE = 'api/v1/external-system';
export const API_EXTERNAL_SYSTEM_DETAILS_REL = 'details';
export const API_EXTERNAL_SYSTEM_FETCH_REL = 'fetch';

export const API_EXTERNAL_SYSTEM_LIST = 'api/v1/external-system/list';
export const API_EXTERNAL_SYSTEM_SAVE = 'api/v1/external-system/save';
export const API_EXTERNAL_SYSTEM_SEARCH = 'api/v1/external-system/search';
export const API_EXTERNAL_SYSTEM_RULES = 'api/v1/external-system/rules';

export const API_RULE_BASE = 'api/v1/rule';
export const API_RULE_DETAILS_REL = 'details';
export const API_RULE_FETCH_REL = 'fetch';
export const API_RULE_LIST = 'api/v1/rule/list';
export const API_RULE_SAVE = 'api/v1/rule/save';
export const API_RULE_SEARCH = 'api/v1/rule/search';
export const API_RULE_SYSTEMS = 'api/v1/rule/systems';
export const API_RULE_SYNC_HISTORY = 'api/v1/verification/list-sync-history	';
export const API_RULE_SYNC_VERIFICATION_DB2 = '/api/v1/image-scheduler/process-image-db2';
export const API_RULE_SYNC_VERIFICATION_SYNOLOGY = '/api/v1/image-scheduler/process-image-synology';

export const API_USER_BASE = 'api/v1/user';
export const API_USER_DETAILS_REL = 'details';
export const API_USER_FETCH_REL = 'fetch';
export const API_USER_AUTHENTICATE = 'api/v1/user/authenticate';
export const API_USER_ROLES = 'api/v1/user/roles';
export const API_USER_SAVE = 'api/v1/user/save';
export const API_USER_VERIFY_USERNAME = 'api/v1/user/verify-username';
export const API_USER_CHANGE_PASSWORD = 'api/v1/user/change-password';
export const API_USER_PROFILE = 'api/v1/user/profile';
export const API_USER_SEARCH = 'api/v1/user/search';
export const API_USER_SYSTEMS = 'api/v1/user/systems';

export const API_VERIFICATION_STATES = 'api/v1/verification/states';
export const API_VERIFICATION_STATES_DB2 = 'api/v1/verification/db2-states';
export const API_VERIFICATION_STATES_SYNOLOGY = 'api/v1/verification/synology-states';
export const API_VERIFICATION_FIND_PERSON_BY_DOCUMENT = 'api/v1/verification/find-person-by-document';
export const API_VERIFICATION_DETAILS = 'api/v1/verification/details';
export const API_VERIFICATION_DETAILS_EXTERNAL = 'api/v1/verification/details/external';
export const API_VERIFICATION_RULES = 'api/v1/verification/rules';
export const API_VERIFICATION_RULE_SYSTEMS= 'api/v1/verification/rule/systems';
export const API_VERIFICATION_SAVE = 'api/v1/verification/save';
export const API_VERIFICATION_SAVE_B64 = 'api/v1/verification/save';
export const API_VERIFICATION_SEARCH = 'api/v1/verification/search';
export const API_VERIFICATION_EXCEL_EXPORT = 'api/v1/verification/export';
export const API_VERIFICATION_VERIFY = 'api/v1/verification/verify';
export const API_VERIFICATION_DOCUMENT_TYPES = 'api/v1/verification/document-types';


export const API_IMAGE_FORMAT_LIST = 'api/v1/image-format/list';
export const API_PARAMETER_LIST = 'api/v1/parameter/list';
export const API_IMAGE_GET = 'api/v1/image/get';


export const API_SECURITY_AUTHENTICATE_EXTERNAL = 'api/v1/security/authenticate/external ';
