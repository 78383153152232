import React, { Component, Fragment } from 'react';
import './RuleView.css';
import Layout from '../../../components/Layout/Layout';
import BaseRule from '../BaseRule/BaseRule';
import Breadcrumb from '../../../components/Breadcrumb';
import { ROUTE_HOME, ROUTE_REL_SYSTEM_CREATE, ROUTE_REL_SYSTEM_EDIT, ROUTE_REL_SYSTEM_VIEW, ROUTE_REL_VERIFICATION_CREATE, ROUTE_SYSTEM_MODULE, ROUTE_VERIFICATION_MODULE } from '../../../constant/AppRoutes';

class RuleView extends Component
{
    render ()
    {
        let breadcrumbData = [
            {
                label: "Reglas",
                to: ROUTE_HOME,
                isActive: false,
            },
            {
                label: "Ver detalle de regla",
                isActive: true,
            }
        ]

        const searchParams = Object.fromEntries([...this.props.searchParams]);
        if (searchParams.from)
        {
            if (searchParams.from === "systemDetails")
            {
                breadcrumbData = [
                    {
                        label: "Sistemas externos",
                        to: ROUTE_SYSTEM_MODULE,
                        isActive: false,
                    },
                    {
                        label: "Ver detalle de sistema externo",
                        to: `${ROUTE_SYSTEM_MODULE}/${searchParams.systemId}${ROUTE_REL_SYSTEM_VIEW}`,
                        isActive: false,
                    },
                    {
                        label: "Ver detalle de regla",
                        isActive: true,
                    }
                ]
            }
            if (searchParams.from === "systemEdit")
            {
                breadcrumbData = [
                    {
                        label: "Sistemas externos",
                        to: ROUTE_SYSTEM_MODULE,
                        isActive: false,
                    },
                    {
                        label: "Editar sistema externo",
                        to: `${ROUTE_SYSTEM_MODULE}/${searchParams.systemId}${ROUTE_REL_SYSTEM_EDIT}`,
                        isActive: false,
                    },
                    {
                        label: "Ver detalle de regla",
                        isActive: true,
                    }
                ]
            }
            if (searchParams.from === "systemCreate")
            {
                breadcrumbData = [
                    {
                        label: "Sistemas externos",
                        to: ROUTE_SYSTEM_MODULE,
                        isActive: false,
                    },
                    {
                        label: "Nuevo sistema externo",
                        to: `${ROUTE_SYSTEM_MODULE}${ROUTE_REL_SYSTEM_CREATE}`,
                        isActive: false,
                    },
                    {
                        label: "Ver detalle de regla",
                        isActive: true,
                    }
                ]
            }
            if (searchParams.from === "newVerification")
            {
                breadcrumbData = [
                    {
                        label: "Histórico de verificaciones",
                        to: ROUTE_VERIFICATION_MODULE,
                        isActive: false,
                    },
                    {
                        label: "Nueva verificación",
                        to: `${ROUTE_VERIFICATION_MODULE}${ROUTE_REL_VERIFICATION_CREATE}`,
                        isActive: false,
                    },
                    {
                        label: "Ver detalle de regla",
                        isActive: true,
                    }
                ]
            }
        }

        return (
            <>
                <Layout active={`${searchParams.from === "systemDetails" ? ROUTE_SYSTEM_MODULE : ROUTE_HOME}`}>
                    <Breadcrumb data={breadcrumbData} />
                    <div className='row my-3'>
                        <div className='col'>
                            <h4 className='mb-0'>Ver detalle de regla</h4>
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col'>
                            <div className="card search-filters-container">
                                <div className="card-body">
                                    <BaseRule navigate={this.props.navigate} ruleId={this.props.params.ruleId ?? 0} mode="view" />
                                </div>
                            </div>
                        </div>
                    </div>
                </Layout>
            </>
        );
    }
}

export default RuleView;