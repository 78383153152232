import React, { Component, Fragment, forwardRef } from 'react';
import './VerificationList.css';
import Layout from '../../../components/Layout';
import Pagination from '../../../components/Pagination';
import http from '../../../api/HTTPService';
import { Link } from 'react-router-dom';
import ReactDatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import format from "date-fns/format";
import copy from 'clipboard-copy';
import DateFromString from '../../../util/DateFromString';
import { MultiSelect } from 'react-multi-select-component';
import MultiselectStrings from '../../../util/MultiselectStrings';
import Loading from '../../../components/Loading';
import { API_EXTERNAL_SYSTEM_LIST, API_RULE_LIST, API_RULE_SYNC_HISTORY, API_RULE_SYNC_VERIFICATION_DB2, API_RULE_SYNC_VERIFICATION_SYNOLOGY, API_VERIFICATION_EXCEL_EXPORT, API_VERIFICATION_SEARCH, API_VERIFICATION_STATES, API_VERIFICATION_STATES_DB2, API_VERIFICATION_STATES_SYNOLOGY } from '../../../constant/APIUrls';
import { ROUTE_REL_RULE_VIEW, ROUTE_REL_VERIFICATION_CREATE, ROUTE_REL_VERIFICATION_RESULT, ROUTE_RULE_MODULE, ROUTE_VERIFICATION_MODULE } from '../../../constant/AppRoutes';

registerLocale("es", es);

const SYNC_HISTORY_ITEMS_PER_PAGE = 4;

class VerificationList extends Component
{
    constructor (props)
    {
        super(props);

        const searchParams = Object.fromEntries([...this.props.searchParams]);
        this.state = {
            totalResults: 0,
            inputName: searchParams.name ?? '',
            inputDNI: searchParams.dni ?? '',
            inputVerificationState: searchParams.verificationState ?? '',
            inputVerificationStateDB2: searchParams.verificationStateDB2 ?? '',
            inputVerificationStateSynology: searchParams.verificationStateSynology ?? '',
            inputUpdateDateFrom: DateFromString(searchParams.updateDateFrom),
            inputUpdateDateTo: DateFromString(searchParams.updateDateTo),
            rulesList: [],

            optionsRules: [],
            optionsSystems: [],
            optionsStates: [],
            optionsStatesDB2: [],
            optionsStatesSynology: [],
            selectedRulesFilter: searchParams.rules ? JSON.parse(searchParams.rules) : [],
            selectedSystemsFilter: searchParams.systems ? JSON.parse(searchParams.systems) : [],
            copied: false,
            isLoadingExternalSystemsFilter: false,
            isLoadingRulesFilter: false,
            isLoadingStatesFilter: false,
            isLoadingStatesDB2Filter: false,
            isLoadingStatesSynologyFilter: false,
            isLoadingVerifications: false,
            isLoadingSyncHistory: false,
            firstResult: parseInt(searchParams.firstResult ?? 1),
            syncHistoryList: [],
            errMessages: [],
            errMessagesSyncHistory: [],
            errMessagesSyncVerification: [],

            isSynchronizingVerification: false,
            syncResult: true,
            syncErrorMessage: "",

            syncHistoryFirstResult: 1,
            syncHistoryTotalResults: 0,

            syncHistoryCurrentVerification: undefined,
            syncHistoryCurrentVerificationType: 0,
        }
    }

    handleChangeSyncHistoryCurrentPage(currentPage)
    {
        this.setState({ syncHistoryFirstResult: currentPage });
        this.getSyncHistory(this.state.syncHistoryCurrentVerification, this.state.syncHistoryCurrentVerificationType, currentPage);
    }

    static defaultProps = {
        maxResults: 5,
    }

    clearFilters()
    {
        this.props.setSearchParams('');
        this.setState({
            inputName: '',
            inputDNI: '',
            inputVerificationState: '',
            inputVerificationStateDB2: '',
            inputVerificationStateSynology: '',
            inputUpdateDateFrom: null,
            inputUpdateDateTo: null,
            selectedRulesFilter: [],
            selectedSystemsFilter: [],
        })
    }

    handleKeyPress = (event) =>
    {
        if (event.key === 'Enter')
        {
            this.handleSearch();
        }
    };

    updateSearchParam(key, value)
    {
        let searchParams = this.props.searchParams;
        searchParams.set(key, value);
        this.props.setSearchParams(searchParams);
    }

    handleInputChange(key, value)
    {
        this.setState({
            [key]: value,
        })
    }

    handleSearch()
    {
        let searchParams = this.props.searchParams;
        this.state.inputName.length ?
            searchParams.set('name', this.state.inputName) :
            searchParams.delete('name');
        this.state.inputDNI.length ?
            searchParams.set('dni', this.state.inputDNI) :
            searchParams.delete('dni');
        this.state.inputVerificationState.length ?
            searchParams.set('verificationState', this.state.inputVerificationState) :
            searchParams.delete('verificationState');
        this.state.inputVerificationStateDB2.length ?
            searchParams.set('verificationStateDB2', this.state.inputVerificationStateDB2) :
            searchParams.delete('verificationState');
        this.state.inputVerificationStateSynology.length ?
            searchParams.set('verificationStateSynology', this.state.inputVerificationStateSynology) :
            searchParams.delete('verificationStateSynology');
        if (this.state.inputUpdateDateFrom)
        {
            const inputUpdateDateFrom = format(this.state.inputUpdateDateFrom, 'dd/MM/yyyy')
            searchParams.set('updateDateFrom', inputUpdateDateFrom);
        }
        else
        {
            searchParams.delete('updateDateFrom');
        }
        if (this.state.inputUpdateDateTo)
        {
            const inputUpdateDateTo = format(this.state.inputUpdateDateTo, 'dd/MM/yyyy')
            searchParams.set('updateDateTo', inputUpdateDateTo);
        }
        else
        {
            searchParams.delete('updateDateTo');
        }
        this.state.selectedRulesFilter.length ?
            searchParams.set('rules', JSON.stringify(this.state.selectedRulesFilter)) :
            searchParams.delete('rules');
        this.state.selectedSystemsFilter.length ?
            searchParams.set('systems', JSON.stringify(this.state.selectedSystemsFilter)) :
            searchParams.delete('systems');
        this.props.setSearchParams(searchParams);
    }

    handleExport(e)
    {
        e.target.disabled = true;
        const loadingSpinner = e.target.querySelector('span');
        loadingSpinner.classList.remove('hidden');
        const rules = this.state.selectedRulesFilter.map((rule) => ({ ruleId: rule.value }));
        const system = this.state.selectedSystemsFilter.map((system) => ({ systemId: system.value }));
        const username = localStorage.getItem('username');
        let inputUpdateDateFrom;
        let inputUpdateDateTo;
        this.state.inputUpdateDateFrom ? inputUpdateDateFrom = format(this.state.inputUpdateDateFrom, 'dd/MM/yyyy') : inputUpdateDateFrom = '';
        this.state.inputUpdateDateTo ? inputUpdateDateTo = format(this.state.inputUpdateDateTo, 'dd/MM/yyyy') : inputUpdateDateTo = '';
        const data = {
            documentNumber: this.state.inputDNI ?? '',
            name: this.state.inputName ?? '',
            updateDateFrom: inputUpdateDateFrom,
            updateDateTo: inputUpdateDateTo,
            verificationState: this.state.inputVerificationState ?? '',
            db2State: this.state.inputVerificationStateDB2 ?? '',
            synologyState: this.state.inputVerificationStateSynology ?? '',
            rules: rules,
            system: system,
            username: username,
        }

        http.post(API_VERIFICATION_EXCEL_EXPORT, data, {
            responseType: 'arraybuffer'
        })
            .then((response) =>
            {
                const imageContentType = response.headers['content-type'];
                const imageFilename = response.headers.ulima_filename;
                const imageBinary = new Blob([response.data], { type: imageContentType });
                const imageUrl = URL.createObjectURL(imageBinary);
                const dlink = document.createElement("a");
                dlink.href = imageUrl;
                dlink.download = imageFilename;
                dlink.target = "_blank";
                dlink.style.display = "none";
                document.body.appendChild(dlink);
                dlink.click();
                URL.revokeObjectURL(imageUrl);
                document.body.removeChild(dlink);
            })
            .catch((error) =>
            {
                console.log(error);
                this.setState({
                    errMessages: error.response ? error.response.data?.messages ?? [{ code: "", message: "Error desconocido" }] : [{ code: "", message: "Error de red" }],
                })
                window.scrollTo({ top: 0, behavior: 'smooth' });
            })
            .finally(() =>
            {
                e.target.disabled = false;
                loadingSpinner.classList.add('hidden');
            })
    }

    handleChangeCurrentPage(currentPage)
    {
        this.updateSearchParam('firstResult', currentPage);
        this.setState({ firstResult: currentPage })
    }

    componentDidMount()
    {
        this.geVerificationHistory();
        this.getRulesList();
        this.getSystemsList();
        this.getStatesList();
        this.getStatesDB2List();
        this.getStatesSynologyList();
    }

    componentDidUpdate(prevProps)
    {
        if (prevProps && (prevProps.searchParams !== this.props.searchParams))
        {
            this.geVerificationHistory();
        }
    }

    handleCopy(text)
    {
        copy(text);
        this.setState({
            copied: true,
        }, () =>
        {
            this.copiedTimeout = setTimeout(() =>
            {
                this.setState({
                    copied: false,
                });
            }, 2000);
        });
    }

    getSyncHistory(verification, type, firstResult)
    {
        if (!verification) return;
        this.setState((prevState) => ({
            isLoadingSyncHistory: true,
            syncHistoryList: [],
            errMessagesSyncHistory: [],
            syncHistoryCurrentVerification: verification,
            syncHistoryCurrentVerificationType: type,
        }))
        http.get(`${API_RULE_SYNC_HISTORY}?firstResult=${firstResult}&maxResults=${SYNC_HISTORY_ITEMS_PER_PAGE}&verificationId=${verification.verificationId}&syncType=${type}`)
            .then((response) =>
            {
                this.setState({
                    syncHistoryList: response.data.data.list ?? [],
                    syncHistoryTotalResults: response.data.data.total ?? 0,
                })
            })
            .catch((error) =>
            {
                console.log(error);
                this.setState({
                    errMessagesSyncHistory: error.response ? error.response.data?.messages ?? [{ code: "", message: "Error desconocido" }] : [{ code: "", message: "Error de red" }],
                })
                window.scrollTo({ top: 0, behavior: 'smooth' });
            })
            .finally(() =>
            {
                this.setState({
                    isLoadingSyncHistory: false,
                })
            });
    }

    syncVerification(verification, type)
    {
        if (!verification) return;
        this.setState({
            isSynchronizingVerification: true,
            syncResult: false,
            syncErrorMessage: "",
            errMessagesSyncVerification: [],
        })
        let API_URL = API_RULE_SYNC_VERIFICATION_DB2;
        if (type === 2)
            API_URL = API_RULE_SYNC_VERIFICATION_SYNOLOGY
        const username = localStorage.getItem('username');
        http.post(API_URL, {
            verificationId: verification.verificationId,
            username: username,
        })
            .then((response) =>
            {
                this.setState({
                    syncResult: response.data.data.success,
                    syncErrorMessage: response.data.data.message,
                })
            })
            .catch((error) =>
            {
                console.log(error);
                this.setState({
                    errMessagesSyncVerification: error.response ? error.response.data?.messages ?? [{ code: "", message: "Error desconocido" }] : [{ code: "", message: "Error de red" }],
                })
                window.scrollTo({ top: 0, behavior: 'smooth' });
            })
            .finally(() =>
            {
                this.setState({
                    isSynchronizingVerification: false,
                })
            });
    }

    geVerificationHistory()
    {
        this.setState({
            isLoadingVerifications: true,
        })
        const searchParams = Object.fromEntries([...this.props.searchParams]);
        const rules = this.state.selectedRulesFilter.map((rule) => ({ ruleId: rule.value }));
        const system = this.state.selectedSystemsFilter.map((system) => ({ systemId: system.value }));
        const username = localStorage.getItem('username');
        http.post(API_VERIFICATION_SEARCH, {
            firstResult: searchParams.firstResult ?? this.state.firstResult,
            maxResults: searchParams.maxResults ?? this.props.maxResults,
            documentNumber: searchParams.documentNumber ?? '',
            name: searchParams.name ?? '',
            updateDateFrom: searchParams.updateDateFrom ?? '',
            updateDateTo: searchParams.updateDateTo ?? '',
            verificationState: searchParams.verificationState ?? '',
            db2State: searchParams.verificationStateDB2 ?? '',
            synologyState: searchParams.verificationStateSynology ?? '',
            rules: rules,
            system: system,
            username: username,
        })
            .then((response) =>
            {
                const newList = response.data.data.list.map(item =>
                {
                    return {
                        ...item,
                        characteristics: "",
                        errorMessage: "",
                        imageFormats: [],
                        parameters: [],
                        fetched: false,
                        editing: false,
                    };
                });
                this.setState({
                    verificationList: newList,
                    totalResults: response.data.data.total,
                })
            })
            .catch((error) =>
            {
                console.log(error);
                this.setState({
                    errMessages: error.response ? error.response.data?.messages ?? [{ code: "", message: "Error desconocido" }] : [{ code: "", message: "Error de red" }],
                })
                window.scrollTo({ top: 0, behavior: 'smooth' });
            })
            .finally(() =>
            {
                this.setState({
                    isLoadingVerifications: false,
                })
            });
    }

    getSystemsList()
    {
        this.setState({
            isLoadingExternalSystemsFilter: true,
        })
        http.get(API_EXTERNAL_SYSTEM_LIST)
            .then((response) =>
            {
                const systems = response.data.data.list.map((system) => ({ label: system.name, value: system.systemId }));
                this.setState({
                    optionsSystems: systems,
                })
            })
            .catch((error) =>
            {
                console.log(error);
                this.setState({
                    errMessages: error.response ? error.response.data?.messages ?? [{ code: "", message: "Error desconocido" }] : [{ code: "", message: "Error de red" }],
                })
                window.scrollTo({ top: 0, behavior: 'smooth' });
            })
            .finally(() =>
            {
                this.setState({
                    isLoadingExternalSystemsFilter: false,
                })
            });
    }

    getRulesList()
    {
        this.setState({
            isLoadingRulesFilter: true,
        })
        const username = localStorage.getItem('username');
        http.post(API_RULE_LIST, {
            username: username,
        })
            .then((response) =>
            {
                const rules = response.data.data.list.map((rule) => ({ label: rule.name, value: rule.ruleId }));
                this.setState({
                    optionsRules: rules,
                })
            })
            .catch((error) =>
            {
                console.log(error);
                this.setState({
                    errMessages: error.response ? error.response.data?.messages ?? [{ code: "", message: "Error desconocido" }] : [{ code: "", message: "Error de red" }],
                })
                window.scrollTo({ top: 0, behavior: 'smooth' });
            })
            .finally(() =>
            {
                this.setState({
                    isLoadingRulesFilter: false,
                })
            });
    }

    getStatesList()
    {
        this.setState({
            isLoadingStatesFilter: true,
        })
        http.get(API_VERIFICATION_STATES)
            .then((response) =>
            {
                this.setState({
                    optionsStates: response.data.data.list,
                })
            })
            .catch((error) =>
            {
                console.log(error);
                this.setState({
                    errMessages: error.response ? error.response.data?.messages ?? [{ code: "", message: "Error desconocido" }] : [{ code: "", message: "Error de red" }],
                })
                window.scrollTo({ top: 0, behavior: 'smooth' });
            })
            .finally(() =>
            {
                this.setState({
                    isLoadingStatesFilter: false,
                })
            });
    }

    getStatesDB2List()
    {
        this.setState({
            isLoadingStatesDB2Filter: true,
        })
        http.get(API_VERIFICATION_STATES_DB2)
            .then((response) =>
            {
                this.setState({
                    optionsStatesDB2: response.data.data.list,
                })
            })
            .catch((error) =>
            {
                console.log(error);
                this.setState({
                    errMessages: error.response ? error.response.data?.messages ?? [{ code: "", message: "Error desconocido" }] : [{ code: "", message: "Error de red" }],
                })
                window.scrollTo({ top: 0, behavior: 'smooth' });
            })
            .finally(() =>
            {
                this.setState({
                    isLoadingStatesDB2Filter: false,
                })
            });
    }

    getStatesSynologyList()
    {
        this.setState({
            isLoadingStatesSynologyFilter: true,
        })
        http.get(API_VERIFICATION_STATES_SYNOLOGY)
            .then((response) =>
            {
                this.setState({
                    optionsStatesSynology: response.data.data.list,
                })
            })
            .catch((error) =>
            {
                console.log(error);
                this.setState({
                    errMessages: error.response ? error.response.data?.messages ?? [{ code: "", message: "Error desconocido" }] : [{ code: "", message: "Error de red" }],
                })
                window.scrollTo({ top: 0, behavior: 'smooth' });
            })
            .finally(() =>
            {
                this.setState({
                    isLoadingStatesSynologyFilter: false,
                })
            });
    }

    refresh()
    {
        window.location.reload();
    }

    render()
    {
        const searchParams = Object.fromEntries([...this.props.searchParams]);
        const updateSearchParam = this.updateSearchParam.bind(this);
        const handleInputChange = this.handleInputChange.bind(this);
        const handleChangeCurrentPage = this.handleChangeCurrentPage.bind(this);
        const handleChangeSyncHistoryCurrentPage = this.handleChangeSyncHistoryCurrentPage.bind(this);
        const RangeCustomInput = forwardRef(({ value, onClick }, ref) => (
            <div ref={ref} className="input-group input-group-with-icon">
                <input className="form-control" type="search" placeholder="Rango de fechas de última modificación" value={value} readOnly onClick={onClick} />
                <i className="bi bi-calendar-range"></i>
            </div>
        ));
        return (
            <>
                <Layout active={ROUTE_VERIFICATION_MODULE}>
                    {
                        (this.state.isLoadingVerifications || this.state.isLoadingExternalSystemsFilter || this.state.isLoadingRulesFilter || this.state.isLoadingStatesFilter) ?
                            <>
                                <Loading />
                            </> :
                            <>
                                <div className='animate__animated animate__fadeInDown animate__faster'>
                                    <div className='row my-3'>
                                        <div className='col'>
                                            <div className='d-flex justify-content-between'>
                                                <h4 className='mb-0'>Histórico de verificaciones</h4>
                                                <Link to={`${ROUTE_VERIFICATION_MODULE}${ROUTE_REL_VERIFICATION_CREATE}`} className='btn btn-primary'>
                                                    <i className="bi bi-plus-lg me-2 align-middle"></i>
                                                    <span className='align-middle'>Realizar verificación</span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mb-3'>
                                        <div className='col'>
                                            <div className="card search-filters-container">
                                                <div className="card-body">
                                                    {
                                                        (this.state.errMessages && this.state.errMessages.length) ?
                                                            <>
                                                                <div className='row mb-3'>
                                                                    <div className='col'>
                                                                        <div className="alert alert-danger mb-0" role="alert">
                                                                            {
                                                                                this.state.errMessages.map((err, index) =>
                                                                                {
                                                                                    return (
                                                                                        <Fragment key={index}>
                                                                                            <p className='mb-0'> {err.code?.length ? `[${err.code}]` : ""} {err.message}</p>
                                                                                        </Fragment>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </> : <></>
                                                    }
                                                    <div className='row g-2 mb-3'>
                                                        <div className='col-4'>
                                                            <div className="input-group input-group-with-icon">
                                                                <input onKeyDown={this.handleKeyPress} className="form-control" type="search" placeholder="DNI" value={this.state.inputDNI} onChange={(e) => handleInputChange('inputDNI', e.target.value)} />
                                                                <i className="bi bi-search"></i>
                                                            </div>
                                                        </div>
                                                        <div className='col-8'>
                                                            <div className="input-group input-group-with-icon">
                                                                <input onKeyDown={this.handleKeyPress} className="form-control" type="search" placeholder="Nombres y apellidos" value={this.state.inputName} onChange={(e) => handleInputChange('inputName', e.target.value)} />
                                                                <i className="bi bi-search"></i>
                                                            </div>
                                                        </div>
                                                        <div className='col-4'>
                                                            <MultiSelect
                                                                options={this.state.optionsRules}
                                                                value={this.state.selectedRulesFilter}
                                                                onChange={(value) =>
                                                                {
                                                                    this.setState({ selectedRulesFilter: value })
                                                                }}
                                                                labelledBy="Selectionar"
                                                                className='ul-multi-select'
                                                                overrideStrings={{
                                                                    allItemsAreSelected: "Todos",
                                                                    clearSearch: "Limpiar",
                                                                    clearSelected: "Limpiar seleccionados",
                                                                    noOptions: "Sin opciones",
                                                                    search: "Buscar",
                                                                    selectAll: "Seleccionar todo",
                                                                    selectAllFiltered: "Seleccionar todo (filtrado)",
                                                                    selectSomeItems: "Reglas relacionadas",
                                                                    create: "Crear",
                                                                }}
                                                            />
                                                        </div>
                                                        <div className='col-4'>
                                                            <MultiSelect
                                                                options={this.state.optionsSystems}
                                                                value={this.state.selectedSystemsFilter}
                                                                onChange={(value) =>
                                                                {
                                                                    this.setState({ selectedSystemsFilter: value })
                                                                }}
                                                                labelledBy="Seleccionar"
                                                                className='ul-multi-select'
                                                                overrideStrings={MultiselectStrings}
                                                            />
                                                        </div>
                                                        <div className='col-4'>
                                                            <ReactDatePicker
                                                                placeholderText="Rango de fechas de verificación"
                                                                selectsRange={true}
                                                                startDate={this.state.inputUpdateDateFrom}
                                                                endDate={this.state.inputUpdateDateTo}
                                                                dateFormat="dd/MM/yyyy"
                                                                locale={es}
                                                                onChange={(update) =>
                                                                {
                                                                    const [startDate, endDate] = update;
                                                                    this.setState({
                                                                        inputUpdateDateFrom: startDate,
                                                                        inputUpdateDateTo: endDate,
                                                                    })
                                                                }}
                                                                customInput={
                                                                    <RangeCustomInput />
                                                                }
                                                                isClearable
                                                            >
                                                            </ReactDatePicker>
                                                            <div id="lastModificationRange" className="form-text">
                                                                <small>DD/MM/AAAA-DD/MM/AAAA</small>
                                                            </div>
                                                        </div>
                                                        <div className="col-4">
                                                            <select className={`form-select ${this.state.inputVerificationState === '' ? "select-placeholder" : ""}`} placeholder='Estado de verificación' value={this.state.inputVerificationState} onChange={(e) => handleInputChange('inputVerificationState', e.target.value)} >
                                                                <option value="" disabled hidden>Estado de verificación</option>
                                                                {
                                                                    this.state.optionsStates.map((state, index) =>
                                                                    {
                                                                        return (
                                                                            <option key={index} value={state.verificationState}>{state.name}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                        <div className="col-4">
                                                            <select className={`form-select ${this.state.inputVerificationStateDB2 === '' ? "select-placeholder" : ""}`} placeholder='Estado DB2' value={this.state.inputVerificationStateDB2} onChange={(e) => handleInputChange('inputVerificationStateDB2', e.target.value)} >
                                                                <option value="" disabled hidden>Estado DB2</option>
                                                                {
                                                                    this.state.optionsStatesDB2.map((state, index) =>
                                                                    {
                                                                        return (
                                                                            <option key={index} value={state.db2State}>{state.name}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                        <div className="col-4">
                                                            <select className={`form-select ${this.state.inputVerificationStateSynology === '' ? "select-placeholder" : ""}`} placeholder='Estado Synology' value={this.state.inputVerificationStateSynology} onChange={(e) => handleInputChange('inputVerificationStateSynology', e.target.value)} >
                                                                <option value="" disabled hidden>Estado Synology</option>
                                                                {
                                                                    this.state.optionsStatesSynology.map((state, index) =>
                                                                    {
                                                                        return (
                                                                            <option key={index} value={state.synologyState}>{state.name}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col'>
                                                            <div className='d-sm-block d-md-flex justify-content-end'>
                                                                <div>
                                                                    <button className='btn btn-outline-primary me-2' onClick={this.clearFilters.bind(this)}>Limpiar filtros</button>
                                                                    <button className='btn btn-primary me-2' onClick={this.handleSearch.bind(this)}>Buscar</button>
                                                                    <button className='btn btn-primary' onClick={this.handleExport.bind(this)}>
                                                                        <span className="spinner-border spinner-border-sm me-2 hidden" role="status" aria-hidden="true"></span>
                                                                        Exportar a Excel</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        this.state.totalResults === 0 ?
                                            <>
                                                <p className='text-center py-5'>No hay resultados disponibles</p>
                                            </> :
                                            <>
                                                <div className='row mb-3'>
                                                    <div className='col'>
                                                        <div className='d-flex justify-content-between'>
                                                            <h5>Resultados ({this.state.totalResults}):</h5>
                                                            <div>
                                                                <div className="row g-3 align-items-center">
                                                                    <div className="col-auto">
                                                                        <label htmlFor="itemsPerPageTop">Resultados por página:</label>
                                                                    </div>
                                                                    <div className="col-auto">
                                                                        <select
                                                                            id="itemsPerPageTop"
                                                                            className="form-select form-select-sm"
                                                                            defaultValue={searchParams.maxResults ?? this.props.maxResults}
                                                                            onChange={(e) => updateSearchParam('maxResults', e.target.value)}
                                                                        >
                                                                            <option value={5}>5</option>
                                                                            <option value={10}>10</option>
                                                                            <option value={20}>20</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row row-cols-1 g-2'>
                                                    <div className='col-12'>
                                                        <div className='card border-0'>
                                                            <div className='card-body py-0'>
                                                                <div className='row'>
                                                                    <div className='col-3 align-self-center'>
                                                                        <p className='subtitle-1'>Usuario</p>
                                                                    </div>
                                                                    <div className='col-2 align-self-center'>
                                                                        <p className='subtitle-1'>Regla</p>
                                                                    </div>
                                                                    <div className='col-2 align-self-center'>
                                                                        <p className='subtitle-1'>Sistema externo</p>
                                                                    </div>
                                                                    <div className='col-2 align-self-center'>
                                                                        <p className='subtitle-1'>Fecha de registro</p>
                                                                    </div>
                                                                    <div className='col-2 align-self-center'>
                                                                        <p className='subtitle-1'>Estados</p>
                                                                    </div>
                                                                    <div className='col-1 align-self-center'>
                                                                        <p className='subtitle-1 text-center'>Acciones</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        this.state.verificationList && this.state.verificationList.map((verification, index) =>
                                                        {
                                                            return (
                                                                <Fragment key={index}>
                                                                    <div className='col'>
                                                                        <div className='card rule-container'>
                                                                            <div className='card-body'>
                                                                                <div className='row'>
                                                                                    <div className='col-3 align-self-center'>
                                                                                        <p className="mb-0">
                                                                                            <i className="bi bi-person-vcard me-2" />
                                                                                            {verification.documentNumber}</p>
                                                                                        <p className="mb-0">
                                                                                            <i className="bi bi-person me-2" />
                                                                                            {verification.fullName}</p>
                                                                                        {
                                                                                            (verification.phoneNumber && verification.phoneNumber.length) ?
                                                                                                <>
                                                                                                    <p className="mb-0">
                                                                                                        <i className="bi bi-telephone me-2" />
                                                                                                        <span className="align-middle">{verification.phoneNumber}</span>
                                                                                                    </p>
                                                                                                </> : <></>
                                                                                        }
                                                                                        {
                                                                                            (verification.email && verification.email.length) ?
                                                                                                <>
                                                                                                    <p className="mb-0">
                                                                                                        <i className="bi bi-envelope me-2" />
                                                                                                        <span className="align-middle">{verification.email}</span>
                                                                                                    </p>
                                                                                                </> : <></>
                                                                                        }
                                                                                    </div>
                                                                                    <div className='col-2 align-self-center'>
                                                                                        <p className='mb-0'>{verification.ruleName}</p>
                                                                                    </div>
                                                                                    <div className='col-2 align-self-center'>
                                                                                        <p className='mb-0'>{verification.systemName}</p>
                                                                                    </div>
                                                                                    <div className='col-2 align-self-center'>
                                                                                        <p className="mb-0">
                                                                                            <i className="bi bi-calendar-event me-2" />
                                                                                            <span className="align-middle">{verification.updateDate}</span>
                                                                                        </p>
                                                                                        <p className="mb-0">
                                                                                            <i className="bi bi-clock me-2" />
                                                                                            <span className="align-middle">{verification.updateHour}</span>
                                                                                        </p>

                                                                                    </div>
                                                                                    <div className='col-2 align-self-center'>
                                                                                        <small className='fw-bold'>Verificación</small>
                                                                                        <p className='small mb-0'>{verification.verificationState}</p>
                                                                                        <small className='fw-bold'>DB2</small>
                                                                                        <p className='small mb-0'>{verification.db2StateName}</p>
                                                                                        <small className='fw-bold'>Synology</small>
                                                                                        <p className='small mb-0'>{verification.synologyStateName}</p>
                                                                                    </div>
                                                                                    <div className='col-1 align-self-center d-flex justify-content-center'>
                                                                                        <div className="dropdown">
                                                                                            <button className="btn btn-link dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                                                Opciones
                                                                                            </button>
                                                                                            <ul className="dropdown-menu">
                                                                                                <li><Link className="dropdown-item" to={`${ROUTE_VERIFICATION_MODULE}/${verification.verificationId}${ROUTE_REL_VERIFICATION_RESULT}`}><i className="bi bi-eye me-2"></i>Ver detalle</Link></li>
                                                                                                <li><Link className="dropdown-item" to={`${ROUTE_RULE_MODULE}/${verification.ruleId}${ROUTE_REL_RULE_VIEW}`}><i className="bi bi-eye me-2"></i>Ver regla</Link></li>
                                                                                                <li><button className="dropdown-item" data-bs-toggle="modal" data-bs-target="#syncHistoryModal" onClick={() => this.getSyncHistory(verification, 1, 1)}><i className="bi bi-clock-history me-2"></i>Historial DB2</button></li>
                                                                                                <li><button className="dropdown-item" data-bs-toggle="modal" data-bs-target="#syncVerificationModal" onClick={() => this.syncVerification(verification, 1)}><i className="bi bi-arrow-repeat me-2"></i>Sincronizar DB2</button></li>
                                                                                                <li><button className="dropdown-item" data-bs-toggle="modal" data-bs-target="#syncHistoryModal" onClick={() => this.getSyncHistory(verification, 2, 1)}><i className="bi bi-clock-history me-2"></i>Historial Synology</button></li>
                                                                                                <li><button className="dropdown-item" data-bs-toggle="modal" data-bs-target="#syncVerificationModal" onClick={() => this.syncVerification(verification, 2)}><i className="bi bi-arrow-repeat me-2"></i>Sincronizar Synology</button></li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Fragment>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                <div className='row mt-3'>
                                                    <div className='col'>
                                                        <div className='d-flex justify-content-between'>
                                                            <Pagination handleChangeCurrentPage={(page) => handleChangeCurrentPage(page)} activePage={this.state.firstResult} itemsPerPage={searchParams.maxResults ?? this.props.maxResults} totalItems={this.state.totalResults} />
                                                            <div>
                                                                <div className="row g-3 align-items-center">
                                                                    <div className="col-auto">
                                                                        <label htmlFor="itemsPerPageTop">Resultados por página:</label>
                                                                    </div>
                                                                    <div className="col-auto">
                                                                        <select
                                                                            id="itemsPerPageTop"
                                                                            className="form-select form-select-sm"
                                                                            defaultValue={searchParams.maxResults ?? this.props.maxResults}
                                                                            onChange={(e) => updateSearchParam('maxResults', e.target.value)}
                                                                        >
                                                                            <option value={5}>5</option>
                                                                            <option value={10}>10</option>
                                                                            <option value={20}>20</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                    }
                                </div>
                            </>
                    }

                    <div className="modal fade" id="syncHistoryModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-hidden="true">
                        <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title fs-5">Historial de sincronizaciones</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className='row'>
                                        {
                                            this.state.errMessagesSyncHistory?.length ?
                                                <>
                                                    <div className='col'>
                                                        <div className="alert alert-danger mb-0" role="alert">
                                                            {
                                                                this.state.errMessagesSyncHistory.map((err, index) =>
                                                                {
                                                                    return (
                                                                        <Fragment key={index}>
                                                                            <p className='mb-0'> {err.code?.length ? `[${err.code}]` : ""} {err.message}</p>
                                                                        </Fragment>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </> : <></>
                                        }
                                        <div className='col'>
                                            {
                                                !this.state.isLoadingSyncHistory ?
                                                    <>
                                                        {
                                                            !this.state.syncHistoryList?.length ?
                                                                <>
                                                                    <p className='mb-0'>No se encontraron sincronizaciones relacionadas.</p>
                                                                </> : <>
                                                                    <table className="table">
                                                                        <thead>
                                                                            <tr>
                                                                                <th scope="col">Fecha</th>
                                                                                <th scope="col">Estado</th>
                                                                                <th scope="col">Descripción</th>
                                                                                <th scope="col">Usuario</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {
                                                                                this.state.syncHistoryList.map((syncItem, index) => (
                                                                                    <Fragment key={index}>
                                                                                        <tr>
                                                                                            <td>{syncItem.syncDateTime}</td>
                                                                                            <td>{syncItem.stateName}</td>
                                                                                            <td style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>{syncItem.message}</td>
                                                                                            <td>{syncItem.username}</td>
                                                                                        </tr>
                                                                                    </Fragment>
                                                                                ))
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                    <Pagination handleChangeCurrentPage={(page) => handleChangeSyncHistoryCurrentPage(page)} activePage={this.state.syncHistoryFirstResult} itemsPerPage={SYNC_HISTORY_ITEMS_PER_PAGE} totalItems={this.state.syncHistoryTotalResults} />
                                                                </>
                                                        }
                                                    </> :
                                                    <>
                                                        <div className="spinner-border mx-auto d-block" role="status">
                                                            <span className="visually-hidden">Obteniendo historial de sincronizaciones...</span>
                                                        </div>
                                                    </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="syncVerificationModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-hidden="true">
                        <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title fs-5">Sincronizando</h5>
                                    {
                                        !this.state.isSynchronizingVerification ?
                                            <>
                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </> : undefined
                                    }
                                </div>
                                <div className="modal-body text-center">
                                    {
                                        this.state.errMessagesSyncVerification?.length ?
                                            <>
                                                <div className="alert alert-danger mb-3" role="alert">
                                                    {
                                                        this.state.errMessagesSyncVerification.map((err, index) =>
                                                        {
                                                            return (
                                                                <Fragment key={index}>
                                                                    <p className='mb-0'> {err.code?.length ? `[${err.code}]` : ""} {err.message}</p>
                                                                </Fragment>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </> : <>
                                                {
                                                    !this.state.isSynchronizingVerification ?
                                                        <>
                                                            {
                                                                this.state.syncResult ?
                                                                    <>
                                                                        <i className="fs-1 d-block bi bi-check-circle text-center mb-3" />
                                                                        <p className='text-center mb-0'>Sincronización exitosa</p>
                                                                    </> :
                                                                    <>
                                                                        <i className="fs-1 d-block bi bi-x-circle text-center mb-3" />
                                                                        <p className='text-center mb-0'>{this.state.syncErrorMessage}</p>
                                                                    </>
                                                            }
                                                        </> :
                                                        <>
                                                            <div className="spinner-border mx-auto d-block" role="status">
                                                                <span className="visually-hidden">Sincronizando...</span>
                                                            </div>
                                                        </>
                                                }
                                            </>
                                    }
                                </div>
                                {
                                    !this.state.isSynchronizingVerification ?
                                        <>
                                            <div className="modal-footer">
                                                {
                                                    this.state.syncResult ?
                                                        <>
                                                            <button type="button" className="btn btn-primary" onClick={this.refresh}>Refrescar</button>
                                                        </> :
                                                        <>
                                                            <button ref={this.refESUnderstoodBtn} type="button" className="btn btn-primary" data-bs-dismiss="modal">Cerrar</button>
                                                        </>
                                                }
                                            </div>
                                        </> : undefined
                                }
                            </div>
                        </div>
                    </div>

                </Layout >
            </>
        );
    }
}

export default VerificationList;