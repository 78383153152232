import React from "react";
import './loading.css';
export default class Loading extends React.Component
{
    static defaultProps = {
        height: 'calc(100vh - 10rem)',
    }
    render ()
    {
        return (
            <>
                <div className="row" style={{height: this.props.height}}>
                    <div className="col align-self-center text-center">
                        <div className="ul-loading-spinner">
                            <div className="ul-loading-animation">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}