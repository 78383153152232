import React, { Component, Fragment } from 'react';
import './ExternalSystemView.css';
import Layout from '../../../components/Layout/Layout';
import BaseExternalSystem from '../BaseExternalSystem';
import Breadcrumb from '../../../components/Breadcrumb';
import { ROUTE_REL_USER_EDIT, ROUTE_REL_USER_VIEW, ROUTE_SYSTEM_MODULE, ROUTE_USER_MODULE } from '../../../constant/AppRoutes';

class ExternalSystemView extends Component
{
    render ()
    {
        let breadcrumbData = [
            {
                label: "Sistemas externos",
                to: ROUTE_SYSTEM_MODULE,
                isActive: false,
            },
            {
                label: "Ver detalle de sistema externo",
                isActive: true,
            }
        ]

        const searchParams = Object.fromEntries([...this.props.searchParams]);
        if (searchParams.from)
        {
            if (searchParams.from === "userCreation")
            {
                breadcrumbData = [
                    {
                        label: "Gestión de usuarios",
                        to: ROUTE_USER_MODULE,
                        isActive: false,
                    },
                    {
                        label: "Ver detalle de sistema externo",
                        isActive: true,
                    },
                ]
            }
            if (searchParams.from === "userEdition")
            {
                breadcrumbData = [
                    {
                        label: "Gestión de usuarios",
                        to: ROUTE_USER_MODULE,
                        isActive: false,
                    },
                    {
                        label: "Editar usuario",
                        to: `${ROUTE_USER_MODULE}/${searchParams.userId}${ROUTE_REL_USER_EDIT}`
                    },
                    {
                        label: "Ver detalle de sistema externo",
                        isActive: true,
                    },
                ]
            }
            if (searchParams.from === "userDetails")
            {
                breadcrumbData = [
                    {
                        label: "Gestión de usuarios",
                        to: ROUTE_USER_MODULE,
                        isActive: false,
                    },
                    {
                        label: "Detalle de usuario",
                        to: `${ROUTE_USER_MODULE}/${searchParams.userId}${ROUTE_REL_USER_VIEW}`
                    },
                    {
                        label: "Ver detalle de sistema externo",
                        isActive: true,
                    },
                ]
            }
        }

        return (
            <>

                <Layout active={`${searchParams.from === "userCreation" ? ROUTE_USER_MODULE: searchParams.from === "userEdition" ? ROUTE_USER_MODULE: searchParams.from === "userDetails" ? ROUTE_USER_MODULE : ROUTE_SYSTEM_MODULE}`}>
                    <Breadcrumb data={breadcrumbData} />
                    <div className='row my-3'>
                        <div className='col'>
                            <h4 className='mb-0'>Ver detalle de sistema externo</h4>
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col'>
                            <div className="card search-filters-container">
                                <div className="card-body">
                                    <BaseExternalSystem systemId={this.props.params.systemId ?? 0} mode="view" navigate={this.props.navigate} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Layout>
            </>
        );
    }
}

export default ExternalSystemView;