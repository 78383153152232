import {Roles} from '../../constant/Enums';

const items = [
    {
        "label": "Reglas",
        "to": "/",
        "icon": "bi-card-list",
        "allowed": [Roles.ALL]
    },
    {
        "label": "Sistemas externos",
        "to": "/system",
        "icon": "bi-box-arrow-up-left",
        "allowed": [Roles.ALL]
    },
    {
        "label": "Verificaciones",
        "to": "/verification",
        "icon": "bi-file-earmark-text",
        "allowed": [Roles.ALL]
    },
    {
        "label": "Usuarios",
        "to": "/user",
        "icon": "bi-person",
        "allowed": [Roles.ROLE_ADMIN]
    }
]

export default items;