import { BiometryKeys } from "../../../../constant/Enums"

const BiometryData = [
    {
        name: "Ojo izquierdo",
        parameterKey: BiometryKeys.EYES_POSITION_LEFT,
        fromX: '',
        toX: '',
        fromY: '',
        toY: '',
    },
    {
        name: "Ojo derecho",
        parameterKey: BiometryKeys.EYES_POSITION_RIGHT,
        fromX: '',
        toX: '',
        fromY: '',
        toY: '',
    },
    {
        name: "Boca",
        parameterKey: BiometryKeys.MOUTH_POSITION,
        fromX: '',
        toX: '',
        fromY: '',
        toY: '',
    }
]

export default BiometryData;