import React from "react";
import './InputPassword.css';

export default class InputPassword extends React.Component
{
    constructor (props)
    {
        super(props);
        this.state = {
            passwordType: "password"
        }
    }

    handlePasswordChange (event)
    {
        this.props.onChange(event);
    }

    togglePassword ()
    {
        if (this.state.passwordType === "password")
        {
            this.setState({
                passwordType: "text"
            })
            return;
        }
        this.setState({
            passwordType: "password"
        })
    }

    render ()
    {
        const handlePasswordChange = this.handlePasswordChange.bind(this);
        const togglePassword = this.togglePassword.bind(this);

        return (
            <>
                <input className='form-control' autoComplete="new-password" id={this.props.id} type={this.state.passwordType} onChange={handlePasswordChange} name={this.props.name} placeholder={this.props.placeholder} value={this.props.value} />
                <button type="button" className="btn-password-toggle" onClick={togglePassword}>
                    {this.state.passwordType === "password" ? <i className="bi bi-eye-slash"></i> : <i className="bi bi-eye"></i>}
                </button>
            </>
        )
    }
}