import '@popperjs/core';
import './content/theme/fonts.css';
import './content/theme/theme.css';
import './content/multi-select.css';
import './content/datepicker.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import "bootstrap-icons/font/bootstrap-icons.css";
import 'animate.css';
import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import PrivateRoute from "./util/PrivateRoute";
import Login from "./views/Login";
import RuleList from './views/Rule/RuleList';
import RuleEdit from './views/Rule/RuleEdit';
import RuleCreate from './views/Rule/RuleCreate';
import RuleCopy from './views/Rule/RuleCopy';
import RuleView from './views/Rule/RuleView';
import ExternalSystemList from './views/ExternalSystem/ExternalSystemList';
import ExternalSystemCreate from './views/ExternalSystem/ExternalSystemCreate';
import ExternalSystemEdit from './views/ExternalSystem/ExternalSystemEdit';
import ExternalSystemView from './views/ExternalSystem/ExternalSystemView';
import VerificationList from './views/Verification/VerificationList';
import VerificationVerifyB64 from './views/Verification/VerificationVerifyB64';
// import VerificationVerify from './views/Verification/VerificationVerify';
import VerificationResult from './views/Verification/VerificationResult';
import UserList from './views/User/UserList';
import UserCreate from './views/User/UserCreate';
import UserEdit from './views/User/UserEdit';
import UserView from './views/User/UserView';
import Profile from './views/User/Profile';
import PasswordUpdate from './views/User/PasswordUpdate/PasswordUpdate';
import { ROUTE_HOME, ROUTE_LOGIN, ROUTE_PASSWORD_UPDATE, ROUTE_PROFILE, ROUTE_REL_RULE_COPY, ROUTE_REL_RULE_CREATE, ROUTE_REL_RULE_EDIT, ROUTE_REL_RULE_VIEW, ROUTE_REL_SYSTEM_CREATE, ROUTE_REL_SYSTEM_EDIT, ROUTE_REL_SYSTEM_VIEW, ROUTE_REL_USER_CREATE, ROUTE_REL_USER_EDIT, ROUTE_REL_USER_VIEW, ROUTE_REL_VERIFICATION_CREATE, ROUTE_REL_VERIFICATION_EXTERNAL_CREATE, ROUTE_REL_VERIFICATION_RESULT, ROUTE_REL_VERIFICATION_RESULT_SUMMARY, ROUTE_RULE_MODULE, ROUTE_SYSTEM_MODULE, ROUTE_USER_MODULE, ROUTE_VERIFICATION_MODULE } from './constant/AppRoutes';
import VerificationResultSummary from './views/Verification/VerificationResultSummary';

const App = () =>
{
    const username = localStorage.getItem('username');
    const [isAuthenticated,] = useState(!!username);

    return (
        <div>
            <Routes>
                <Route path={ROUTE_LOGIN} element={<Login />} />

                <Route path={ROUTE_HOME} element={
                    <PrivateRoute isAuthenticated={isAuthenticated}>
                        <RuleList />
                    </PrivateRoute>
                } />

                <Route path={`${ROUTE_RULE_MODULE}${ROUTE_REL_RULE_CREATE}`} element={
                    <PrivateRoute isAuthenticated={isAuthenticated}>
                        <RuleCreate />
                    </PrivateRoute>
                } />

                <Route path={`${ROUTE_RULE_MODULE}/:ruleId${ROUTE_REL_RULE_EDIT}`} element={
                    <PrivateRoute isAuthenticated={isAuthenticated}>
                        <RuleEdit />
                    </PrivateRoute>
                } />

                <Route path={`${ROUTE_RULE_MODULE}/:ruleId${ROUTE_REL_RULE_VIEW}`} element={
                    <PrivateRoute isAuthenticated={isAuthenticated}>
                        <RuleView />
                    </PrivateRoute>
                } />

                <Route path={`${ROUTE_RULE_MODULE}/:ruleId${ROUTE_REL_RULE_COPY}`} element={
                    <PrivateRoute isAuthenticated={isAuthenticated}>
                        <RuleCopy />
                    </PrivateRoute>
                } />

                <Route path={ROUTE_SYSTEM_MODULE} element={
                    <PrivateRoute isAuthenticated={isAuthenticated}>
                        <ExternalSystemList />
                    </PrivateRoute>
                } />

                <Route path={`${ROUTE_SYSTEM_MODULE}${ROUTE_REL_SYSTEM_CREATE}`} element={
                    <PrivateRoute isAuthenticated={isAuthenticated}>
                        <ExternalSystemCreate />
                    </PrivateRoute>
                } />

                <Route path={`${ROUTE_SYSTEM_MODULE}/:systemId${ROUTE_REL_SYSTEM_EDIT}`} element={
                    <PrivateRoute isAuthenticated={isAuthenticated}>
                        <ExternalSystemEdit />
                    </PrivateRoute>
                } />

                <Route path={`${ROUTE_SYSTEM_MODULE}/:systemId${ROUTE_REL_SYSTEM_VIEW}`} element={
                    <PrivateRoute isAuthenticated={isAuthenticated}>
                        <ExternalSystemView />
                    </PrivateRoute>
                } />

                <Route path={ROUTE_VERIFICATION_MODULE} element={
                    <PrivateRoute isAuthenticated={isAuthenticated}>
                        <VerificationList />
                    </PrivateRoute>
                } />

                <Route path={`${ROUTE_VERIFICATION_MODULE}${ROUTE_REL_VERIFICATION_CREATE}`} element={
                    <PrivateRoute isAuthenticated={isAuthenticated}>
                        <VerificationVerifyB64 external={false} />
                    </PrivateRoute>
                } />
                <Route path={`${ROUTE_VERIFICATION_MODULE}${ROUTE_REL_VERIFICATION_EXTERNAL_CREATE}`} element={
                    <PrivateRoute isAuthenticated={isAuthenticated} skip={true}>
                        <VerificationVerifyB64 external={true} />
                    </PrivateRoute>
                } />

                <Route path={`${ROUTE_VERIFICATION_MODULE}/:resultId${ROUTE_REL_VERIFICATION_RESULT}`} element={
                    <PrivateRoute isAuthenticated={isAuthenticated}>
                        <VerificationResult />
                    </PrivateRoute>
                } />
                <Route path={`${ROUTE_VERIFICATION_MODULE}/:resultId${ROUTE_REL_VERIFICATION_RESULT_SUMMARY}`} element={
                    <PrivateRoute isAuthenticated={isAuthenticated} skip={true}>
                        <VerificationResultSummary />
                    </PrivateRoute>
                } />


                <Route path={ROUTE_USER_MODULE} element={
                    <PrivateRoute isAuthenticated={isAuthenticated}>
                        <UserList />
                    </PrivateRoute>
                } />

                <Route path={`${ROUTE_USER_MODULE}${ROUTE_REL_USER_CREATE}`} element={
                    <PrivateRoute isAuthenticated={isAuthenticated}>
                        <UserCreate />
                    </PrivateRoute>
                } />

                <Route path={`${ROUTE_USER_MODULE}/:userId${ROUTE_REL_USER_EDIT}`} element={
                    <PrivateRoute isAuthenticated={isAuthenticated}>
                        <UserEdit />
                    </PrivateRoute>
                } />

                <Route path={`${ROUTE_USER_MODULE}/:userId${ROUTE_REL_USER_VIEW}`} element={
                    <PrivateRoute isAuthenticated={isAuthenticated}>
                        <UserView />
                    </PrivateRoute>
                } />

                <Route path={ROUTE_PROFILE} element={
                    <PrivateRoute isAuthenticated={isAuthenticated}>
                        <Profile />
                    </PrivateRoute>
                } />

                <Route path={ROUTE_PASSWORD_UPDATE} element={
                    <PrivateRoute isAuthenticated={isAuthenticated}>
                        <PasswordUpdate />
                    </PrivateRoute>
                } />
            </Routes>
        </div>
    );
};

export default App;
