import React, { Component, Fragment, createRef } from 'react';
import './UserCreate.css';
import Layout from '../../../components/Layout/Layout';
import BaseUser from '../BaseUser';
import { ROUTE_USER_MODULE } from '../../../constant/AppRoutes';

class UserCreate extends Component
{
    constructor (props)
    {
        super(props);
        this.refBaseUser = createRef();
    }
    preventNavigation ()
    {
        this.refBaseUser.current?.handleDiscardChanges();
    }

    render ()
    {
        const preventNavigation = this.preventNavigation.bind(this);
        return (
            <>
                <Layout active={ROUTE_USER_MODULE}>
                    <div className="row mt-3">
                        <div className="col">
                            <nav aria-label="breadcrumb">
                                <ol className="caption breadcrumb mb-0">
                                    <li onClick={preventNavigation} className="breadcrumb-item"><span style={{ color: 'rgba(var(--ul-link-color-rgb), var(--ul-link-opacity, 1))', cursor: 'pointer' }}>Gestión de usuarios</span></li>
                                    <li className="breadcrumb-item active" aria-current={"page"}>Creación de usuario</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className='row my-3'>
                        <div className='col'>
                            <h4 className='mb-0'>Creación de usuario</h4>
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col'>
                            <div className="card search-filters-container">
                                <div className="card-body">
                                    <BaseUser ref={this.refBaseUser} navigate={this.props.navigate} userId={this.props.params.userId ?? 0} mode="create" />
                                </div>
                            </div>
                        </div>
                    </div>
                </Layout>
            </>
        );
    }
}

export default UserCreate;