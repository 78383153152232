import React, { createRef } from 'react'
import AvatarEditor from 'react-avatar-editor';
import { v4 as uuidv4 } from 'uuid';
import Dropzone from 'react-dropzone';
import './UserAvatarEditor.css';
import isImageFormatSupported from '../../util/IsImageFormatSupported';

class UserAvatarEditor extends React.Component
{
    constructor (props)
    {
        super(props);
        this.uuid = uuidv4();
        this.editor = createRef();
        this.state = {
            image: '',
            position: { x: 0.5, y: 0.5 },
            scale: 1,
            preview: undefined,
            unsupported: false,
            showInfo: true,
        }
    }

    static defaultProps = {
        height: 288,
        width: 240,
    }

    handleNewImage = (e) =>
    {
        const file = e.target.files?.[0];
        if (file)
        {
            if (isImageFormatSupported(file.type))
            {
                let fileName = file.name;
                const lastDotIndex = fileName.lastIndexOf(".");
                const name = fileName.slice(0, lastDotIndex);
                const lowercaseExtension = fileName.slice(lastDotIndex + 1).toLowerCase();
                fileName = `${name}.${lowercaseExtension}`;
                if (fileName.endsWith('.jpeg'))
                {
                    fileName = fileName.replace(/\.jpeg$/, '.jpg');
                }
                this.setState({
                    image: new File([file], fileName, { type: file.type }),
                    unsupported: false,
                })
            }
            else
            {
                this.setState({
                    unsupported: true,
                })
            }
        }
    }

    readAsBase64 = (file) =>
    {
        return new Promise((resolve, reject) =>
        {
            const fileReader = new FileReader();

            fileReader.onload = (event) =>
            {
                resolve(event.target.result);
            };

            fileReader.onerror = (error) =>
            {
                reject(error);
            };

            fileReader.readAsDataURL(file);
        });
    }

    getPicture = async () =>
    {
        if (this.state.image === '') return null;
        let base64 = null;
        let base64O = null;
        let scaled_image = this.editor.current?.getImageScaledToCanvas().toDataURL('image/jpeg');
        const base64OImage = await this.readAsBase64(this.state.image);
        if (scaled_image && base64OImage)
        {
            base64 = scaled_image.split(",")[1];
            base64O = base64OImage.split(",")[1];
        }
        return ({
            base64: base64,
            base64Original: base64O,
            name: this.state.image.name
        });
    }

    handleScale = (e) =>
    {
        const scale = parseFloat(e.target.value);
        this.setState({ scale });
    }

    handlePositionChange = (position) =>
    {
        this.setState({ position })
    }

    handleZoomIn = () =>
    {
        this.setState((prevState) => ({ scale: (prevState.scale + 0.1 < 2 ? prevState.scale + 0.1 : 2) }));
    }

    handleZoomOut = () =>
    {
        this.setState((prevState) => ({ scale: (prevState.scale - 0.1 > 1 ? prevState.scale - 0.1 : 1) }));
    }

    render()
    {
        return (
            <>
                <div className='row row-cols-1 g-3'>
                    {
                        this.state.unsupported ?
                            <>
                                <div className='col'>
                                    <div className="alert alert-danger mb-0" role="alert">
                                        Formato no soportado
                                    </div>
                                </div>
                            </> : <></>
                    }
                    <div className='col'>
                        <Dropzone
                            onDrop={([image]) => this.setState({ image })}
                            multiple={false}
                            noClick={true}
                            accept={{ 'image/*': [] }}
                        >
                            {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps()} style={{ width: this.props.width + 20 }} className={`user-avatar-preview mx-auto position-relative ${this.state.image === '' ? 'position-relative' : 'user-avatar-preview-z-n1'}`} data-content={this.state.image === '' ? 'Arrastra una foto aquí' : ''}>
                                    <AvatarEditor
                                        ref={this.editor}
                                        scale={this.state.scale}
                                        width={this.props.width}
                                        height={this.props.height}
                                        position={this.state.position}
                                        onPositionChange={this.handlePositionChange}
                                        backgroundColor='#ffffff'
                                        image={this.state.image}
                                        border={10}
                                        className='border'
                                        disableCanvasRotation={true}
                                        style={{ background: 'transparent', marginLeft: 'auto', marginRight: 'auto', borderRadius: '.5rem', display: 'block' }}
                                    />
                                    {
                                        this.state.image !== '' ?
                                            <>
                                                <div className='picture-placeholder' data-content={`(${this.props.width}, ${this.props.height})`}></div>
                                            </> : <></>
                                    }
                                    {/* {
                                        this.state.image !== '' && this.state.showInfo ?
                                            <>
                                                <div className='crop-info'>
                                                    <i className="bi bi-x-circle position-absolute top-0 end-0 p-2" onClick={() => { this.setState({ showInfo: false }) }}></i>
                                                    Centre su foto en recuadro de fondo blanco
                                                </div>
                                            </> : <></>
                                    } */}
                                    <input
                                        name="newImage"
                                        type="file"
                                        onChange={this.handleNewImage}
                                        {...getInputProps()}
                                    />
                                </div>
                            )}
                        </Dropzone>
                    </div>
                    <div className='col text-center'>
                        {
                            this.state.image !== '' ?
                                <>
                                    <div className='row row-cols-1 g-2 mb-3 user-avatar-controls-container mx-auto'>
                                        <div className='col'>
                                            <i className="bi bi-zoom-out align-middle me-2" style={{ cursor: 'pointer' }} onClick={this.handleZoomOut}></i>
                                            <input
                                                className="form-range user-avatar-custom-range range-auto align-middle"
                                                name="scale"
                                                type="range"
                                                onChange={this.handleScale}
                                                min="1"
                                                max="2"
                                                step="0.01"
                                                value={this.state.scale}
                                            />
                                            <i className="bi bi-zoom-in align-middle ms-2" style={{ cursor: 'pointer' }} onClick={this.handleZoomIn}></i>
                                        </div>
                                    </div>
                                </> : <></>
                        }
                        <input
                            id={`pictureUploadInput${this.uuid}`}
                            name="newImage"
                            type="file"
                            accept="image/*"
                            hidden
                            onChange={this.handleNewImage}
                        />
                        <label className='btn btn-primary mx-auto' htmlFor={`pictureUploadInput${this.uuid}`}>
                            <i className={`bi ${this.state.image !== '' ? 'bi-images' : 'bi-plus-lg'} me-2`}></i>
                            {this.state.image !== '' ? 'Seleccionar otra foto' : 'Seleccionar una foto'}
                        </label>
                    </div>
                </div>
            </>
        )
    }
}

export default UserAvatarEditor;