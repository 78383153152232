import React, { Component } from 'react';
import './pagination.css';

class Pagination extends Component
{
    constructor (props)
    {
        super(props);
        this.state = {
            activePage: this.props.activePage,
            itemsPerPage:this.props.itemsPerPage,
            totalItems: this.props.totalItems,
        };
    }

    handlePageChange = (pageNumber) =>
    {
        this.setState({ activePage: pageNumber });
        this.props.handleChangeCurrentPage(pageNumber);
    };

    handleFirstPage = () =>
    {
        this.setState({ activePage: 1 });
        this.props.handleChangeCurrentPage(1);
    };

    handleLastPage = () =>
    {
        const { totalItems, itemsPerPage } = this.state;
        const totalPages = Math.ceil(totalItems / itemsPerPage);
        this.setState({ activePage: totalPages });
        this.props.handleChangeCurrentPage(totalPages);
    };

    renderPaginationItems = () =>
    {
        const { totalItems, itemsPerPage, activePage } = this.state;
        const totalPages = Math.ceil(totalItems / itemsPerPage);
        const maxPagesToShow = 5;

        let startPage = 1;
        let endPage = totalPages;

        if (totalPages > maxPagesToShow)
        {
            const middlePage = Math.floor(maxPagesToShow / 2);
            startPage = activePage - middlePage;
            endPage = activePage + middlePage;

            if (startPage < 1)
            {
                endPage += Math.abs(startPage) + 1;
                startPage = 1;
            }

            if (endPage > totalPages)
            {
                startPage -= endPage - totalPages;
                endPage = totalPages;
            }
        }

        const paginationItems = [];
        for (let pageNumber = startPage; pageNumber <= endPage; pageNumber++)
        {
            const isActive = activePage === pageNumber;
            const pageClass = isActive ? 'page-item active' : 'page-item';
            paginationItems.push(
                <li key={pageNumber} className={pageClass} onClick={() => this.handlePageChange(pageNumber)}>
                    <button className="page-link">{pageNumber}</button>
                </li>
            );
        }

        return paginationItems;
    };

    componentDidUpdate(prevProps, prevState)
    {
        if(prevProps.totalItems !== this.props.totalItems)
        {
            this.setState({
                totalItems: this.props.totalItems,
            })
        }
        if(prevProps.activePage !== this.props.activePage)
        {
            this.setState({
                activePage: this.props.activePage,
            })
        }
    }

    render ()
    {
        return (
            <div>
                <ul className="pagination app-pagination">
                    <li className={`page-item ${this.state.activePage === 1 ? 'disabled' : ''}`} onClick={this.handleFirstPage}>
                        <button className="page-link" disabled={this.state.activePage === 1}>
                            <i className="bi bi-chevron-left"></i>
                        </button>
                    </li>
                    {this.renderPaginationItems()}
                    <li className={`page-item ${this.state.activePage === Math.ceil(this.state.totalItems / this.state.itemsPerPage) ? 'disabled' : ''}`} onClick={this.handleLastPage}>
                        <button className="page-link" disabled={this.state.activePage === Math.ceil(this.state.totalItems / this.state.itemsPerPage)}>
                            <i className="bi bi-chevron-right"></i>
                        </button>
                    </li>
                </ul>
            </div>
        );
    }
}

export default Pagination;
