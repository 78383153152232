import React from "react";
import './loading.css';
export default class Snackbar extends React.Component
{
    render ()
    {
        return (
            <>
                {
                    this.props.showSnackbar ?
                        <>
                            <div className="alert alert-success d-flex align-items-center position-absolute top-5 start-50 translate-middle" style={{ zIndex: '10000' }} role="alert">
                                <i style={{color: 'var(--ul-success-500)'}} className="bi bi-check-circle-fill me-2"></i>
                                <div>
                                    {this.props.snackbarMsg}
                                </div>
                            </div>
                        </> :
                        <>
                        </>
                }
            </>
        )
    }
}