import React, { Fragment } from "react";
import './VerificationResultSummary.css';
import Layout from "../../../components/Layout/Layout";
import http from "../../../api/HTTPService";
import Loading from "../../../components/Loading";
import { API_IMAGE_GET, API_VERIFICATION_DETAILS_EXTERNAL } from "../../../constant/APIUrls";
import { ROUTE_VERIFICATION_MODULE } from "../../../constant/AppRoutes";
import { Link } from "react-router-dom";

export default class VerificationResultSummary extends React.Component
{
    constructor (props)
    {
        super(props);
        this.state = {
            data: [],
            isLoadingResult: false,
            photo: "",
            invalidParams: false,
        }
    }
    getResult ()
    {
        this.setState({
            isLoadingResult: true,
        })
        const resultId = this.props.params.resultId;
        const search = this.props.location.search;
        const params = new URLSearchParams(search);
        const username = params.get('username');
        if (username)
        {
            http.get(`${API_VERIFICATION_DETAILS_EXTERNAL}?verificationId=${resultId}&username=${username}`)
                .then((response) =>
                {
                    let data = response.data.data;
                    const compareByOrderId = (a, b) =>
                    {
                        return a.order - b.order;
                    }

                    // Ordenar el arreglo de objetos utilizando la función de comparación
                    data.parameters.sort(compareByOrderId);
                    this.setState({
                        data: data,
                        filename: '',
                    })
                    http.get(`${API_IMAGE_GET}?uuid=${data.uuid}`, {
                        responseType: 'arraybuffer'
                    })
                        .then((response) =>
                        {
                            const imageContentType = 'image/jpeg';
                            const imageBinary = new Blob([response.data], { type: imageContentType });
                            const imageUrl = URL.createObjectURL(imageBinary);
                            this.setState({ photo: imageUrl, filename: response.headers.ulima_filename });
                        })
                        .catch(error => console.error('Error al obtener la imagen:', error));
                })
                .catch((err) =>
                {
                    console.log(err);
                })
                .finally(() =>
                {
                    this.setState({
                        isLoadingResult: false,
                    })
                })
        }
        else
        {
            this.setState({
                isLoadingResult: false,
                invalidParams: true,
            })
        }
    }

    componentDidMount ()
    {
        this.getResult();
    }

    render ()
    {
        return (
            <>
                <Layout active={ROUTE_VERIFICATION_MODULE} external={true}>
                    {
                        this.state.invalidParams ?
                            <>
                                <div className="alert alert-danger text-center mt-3" role="alert">
                                    Parámetros de invocación no válidos. No se recibió un nombre de usuario.
                                </div>
                            </> : <>
                                {
                                    (this.state.isLoadingResult) ?
                                        <>
                                            <Loading />
                                        </> :
                                        <>
                                            <div className='row mb-3 mt-3'>
                                                <div className='col'>
                                                    <div className="card search-filters-container">
                                                        <div className="card-body">
                                                            <div className="row mb-3">
                                                                <div className="col-12 col-md-4">
                                                                    <h6 className="text-center">Imagen:</h6>
                                                                    {
                                                                        this.state.photo.length ?
                                                                            <>
                                                                                <img className="img-fluid border mx-auto d-flex rounded mb-3" alt="Fotografía cargada" src={this.state.photo} style={{ maxHeight: '18rem' }} />
                                                                                <a download={this.state.filename} target="_blank" rel="noreferrer" href={this.state.photo} className="caption d-block text-center my-3">{this.state.filename}</a>
                                                                            </> : <>
                                                                                <Loading height='auto' />
                                                                            </>
                                                                    }
                                                                </div>
                                                                <div className="col-12 col-md-8">
                                                                    <div className={`alert alert-${this.state.data.valid ? 'success' : 'danger'} text-center`} role="alert">
                                                                        {this.state.data.result}
                                                                    </div>
                                                                    {
                                                                        !this.state.data.valid ?
                                                                            <>
                                                                                <h6 className="text-center">Errores encontrados:</h6>
                                                                            </> : <></>
                                                                    }
                                                                    {
                                                                        this.state.data.parameters?.map((parameter, index) =>
                                                                        (
                                                                            <Fragment key={index}>
                                                                                {
                                                                                    !parameter.valid ?
                                                                                        <div className={`rounded-pill alert-danger mt-3`}>
                                                                                            <div className="p-3 d-flex">
                                                                                                <i className="bi bi-x-circle me-3"></i>
                                                                                                <p className="mb-0">{parameter.errorMessage}</p>
                                                                                            </div>
                                                                                        </div> : <></>
                                                                                }
                                                                            </Fragment>
                                                                        ))
                                                                    }
                                                                    {
                                                                        this.state.data.biometrics?.map((parameter, index) =>
                                                                        (
                                                                            <Fragment key={index}>
                                                                                {
                                                                                    !parameter.valid ?
                                                                                        <div className={`rounded-pill alert-danger mt-3`}>
                                                                                            <div className="p-3 d-flex">
                                                                                                <i className="bi bi-x-circle me-3"></i>
                                                                                                <p className="mb-0">{parameter.errorMessage}</p>
                                                                                            </div>
                                                                                        </div> : <></>
                                                                                }
                                                                            </Fragment>
                                                                        ))
                                                                    }
                                                                    <div className="text-center mt-3">
                                                                        {
                                                                            this.state.data.valid ?
                                                                                <>
                                                                                    <button className='btn btn-primary' onClick={() => { window.close() }}>
                                                                                        Cerrar esta ventana
                                                                                    </button>
                                                                                </> : <>
                                                                                    <Link to={-1} className='btn btn-primary'>Volver a intentar</Link>
                                                                                </>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                }
                            </>
                    }
                </Layout>
            </>
        );
    }
}