import React from 'react';
import { Navigate, useParams, useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import { Roles, UserModulePermissions } from '../constant/Enums';
import { ROUTE_LOGIN } from '../constant/AppRoutes';

const PrivateRoute = ({ isAuthenticated, skip=false, children }) =>
{
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();

    const userModuleAllowedRoles = [Roles.ROLE_ADMIN, Roles.ROLE_MASTER];

    let roles = []
    try
    {
        roles = JSON.parse(localStorage.getItem('roles'));
    }
    catch {
        console.log("No se han definido roles para este usuario")
    }

    if (!isAuthenticated && !skip)
    {
        const redirectUrl = encodeURIComponent(location.pathname);
        return <Navigate to={`${ROUTE_LOGIN}?redirect=${redirectUrl}`} />;
    }
    if (location.pathname.startsWith(UserModulePermissions.STARTS_WITH))
    {
        if (!(userModuleAllowedRoles.includes(Roles.ALL) || roles.some(userRole => userModuleAllowedRoles.includes(userRole.roleKey))))
        return <Navigate to={`/`} />;
    }        

    return React.cloneElement(children, { params, location, navigate, searchParams, setSearchParams });
};

export default PrivateRoute;