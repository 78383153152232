import React, { Component, createRef } from 'react';
import Person from './Media/Person.svg';

export const CANVAS_SIZE = {
    minX: 250,
    maxX: 300,
    minY: 250,
    maxY: 300,
}

class BiometricsPreview extends Component
{
    constructor (props)
    {
        super(props);
        this.refCanvas = createRef();
    }

    draw ()
    {
        const canvas = this.refCanvas.current;
        if (!canvas) return;

        let rightEye = {
            x: parseInt(this.props.rightEye.fromX),
            y: parseInt(this.props.rightEye.fromY),
            mid_x: parseInt(this.props.rightEye.fromX) + (parseInt((this.props.rightEye.toX)) - parseInt(this.props.rightEye.fromX)) / 2,
            mid_y: parseInt(this.props.rightEye.fromY) + (parseInt((this.props.rightEye.toY)) - parseInt(this.props.rightEye.fromY)) / 2,
            width: parseInt((this.props.rightEye.toX)) - parseInt(this.props.rightEye.fromX),
            height: parseInt((this.props.rightEye.toY)) - parseInt(this.props.rightEye.fromY),
        };

        let leftEye = {
            x: parseInt(this.props.leftEye.fromX),
            y: parseInt(this.props.leftEye.fromY),
            mid_x: parseInt(this.props.leftEye.fromX) + (parseInt((this.props.leftEye.toX)) - parseInt(this.props.leftEye.fromX)) / 2,
            mid_y: parseInt(this.props.leftEye.fromY) + (parseInt((this.props.leftEye.toY)) - parseInt(this.props.leftEye.fromY)) / 2,
            width: parseInt((this.props.leftEye.toX)) - parseInt(this.props.leftEye.fromX),
            height: parseInt((this.props.leftEye.toY)) - parseInt(this.props.leftEye.fromY),
        };

        let mouth = {
            x: parseInt(this.props.mouth.fromX),
            y: parseInt(this.props.mouth.fromY),
            mid_x: parseInt(this.props.mouth.fromX) + (parseInt((this.props.mouth.toX)) - parseInt(this.props.mouth.fromX)) / 2,
            mid_y: parseInt(this.props.mouth.fromY) + (parseInt((this.props.mouth.toY)) - parseInt(this.props.mouth.fromY)) / 2,
            width: parseInt((this.props.mouth.toX)) - parseInt(this.props.mouth.fromX),
            height: parseInt((this.props.mouth.toY)) - parseInt(this.props.mouth.fromY),
        };

        // Obtener el contexto 2D del lienzo
        var ctx = canvas.getContext("2d");
        ctx.clearRect(1, 1, canvas.width, canvas.height);

        // Establecer la fuente y el tamaño de la fuente
        ctx.font = "10px Arial";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";

        // Dibujar el rectángulo del tamaño máximo
        ctx.strokeStyle = "blue";
        ctx.strokeRect(1, 1, this.props.sizeParameter.maxX, this.props.sizeParameter.maxY);
        ctx.fillStyle = "black";
        const max_rect_label_min = `(0, 0)`;
        ctx.fillText(max_rect_label_min,
            ctx.measureText(max_rect_label_min).width / 2 + 5,
            10);
        const max_rect_label_max = `(${this.props.sizeParameter.maxX}, ${this.props.sizeParameter.maxY})`;
        ctx.fillText(max_rect_label_max,
            this.props.sizeParameter.maxX - ctx.measureText(max_rect_label_max).width / 2 - 5,
            this.props.sizeParameter.maxY - 10);

        // Dibujar el rectángulo del tamaño mínimo
        ctx.strokeStyle = "blue";
        const x_off = (this.props.sizeParameter.maxX - this.props.sizeParameter.minX) / 2;
        const y_off = (this.props.sizeParameter.maxY - this.props.sizeParameter.minY) / 2;
        ctx.strokeRect(x_off, y_off, this.props.sizeParameter.minX, this.props.sizeParameter.minY);
        ctx.fillStyle = "black";
        const min_rect_label_min = `(0, 0)`;
        ctx.fillText(`(0, 0)`,
            x_off + ctx.measureText(min_rect_label_min).width / 2 + 5,
            y_off + 10);
        const min_rect_label_max = `(${this.props.sizeParameter.minX}, ${this.props.sizeParameter.minX})`;
        ctx.fillText(min_rect_label_max,
            this.props.sizeParameter.minX + x_off - ctx.measureText(min_rect_label_max).width / 2 - 5,
            this.props.sizeParameter.maxX - y_off - 10);

        // Dibujar el rectángulo del ojo derecho
        if (rightEye.width > 0 && rightEye.height > 0)
        {
            ctx.fillStyle = "#FF835975";
            ctx.fillRect(rightEye.x, rightEye.y, rightEye.width, rightEye.height);
            ctx.fillStyle = "black";
            ctx.fillText(`ojo der`, rightEye.x + 20, rightEye.y + 10);
        }

        // Dibujar el rectángulo del ojo izquierdo
        if (leftEye.width > 0 && leftEye.height > 0)
        {
            ctx.fillStyle = "#FF835975";
            ctx.fillRect(leftEye.x, leftEye.y, leftEye.width, leftEye.height);
            ctx.fillStyle = "black";
            ctx.fillText(`ojo izq`, leftEye.x + 20, leftEye.y + 10);
        }

        // Dibujar el rectángulo de la boca
        if (mouth.width > 0 && mouth.height > 0)
        {
            ctx.fillStyle = "#FF835975";
            ctx.fillRect(mouth.x, mouth.y, mouth.width, mouth.height);
            ctx.fillStyle = "black";
            ctx.fillText(`boca`, mouth.x + 16, mouth.y + 10);
        }
    }

    componentDidMount ()
    {
        this.draw();
    }

    componentDidUpdate ()
    {
        this.draw();
    }

    render ()
    {
        let canvasSize = {
            width: parseInt(this.props.sizeParameter.maxX) + 2,
            height: parseInt(this.props.sizeParameter.maxY) + 2,
        };

        return (
            <canvas ref={this.refCanvas} width={canvasSize.width} height={canvasSize.height}
                style={{
                    backgroundColor: '#D9D9D9',
                    backgroundImage: `url(${Person})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'top center',
                    backgroundSize: 'contain',
                    border: 'none'
                }} />
        );
    }
}

export default BiometricsPreview;
