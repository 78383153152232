import React, { Component, Fragment, createRef } from 'react';
import './BaseRule.css';
import Parameter from '../../../components/Parameter';
import parameterDefaultsData from '../../../components/Parameter/ParameterDefaultsData.json';
import ruleDefaultsData from './RuleDefaultsData.json';
import http from '../../../api/HTTPService';
import Loading from '../../../components/Loading';
import { MultiSelect } from 'react-multi-select-component';
import { API_IMAGE_FORMAT_LIST, API_PARAMETER_LIST, API_RULE_BASE, API_RULE_DETAILS_REL, API_RULE_FETCH_REL, API_RULE_SAVE } from '../../../constant/APIUrls';
import BiometryData from './Data/BiometryData';
import { BiometryKeys, ParameterKeys } from '../../../constant/Enums';
import BiometricsPreview from '../../../components/BiometricsPreview';
import { CANVAS_SIZE } from '../../../components/BiometricsPreview/BiometricsPreview';

class BaseRule extends Component
{
    constructor (props)
    {
        super(props);

        this.state = {
            ruleList: [],
            parameters: [],
            biometrics: BiometryData,
            imageFormats: [],
            pagesNumber: 0,
            isLoadingParameters: false,
            isLoadingImageFormats: false,
            isLoadingRule: false,
            errMessages: [],
            sizeParameterId: 0,
        };
        this.refBtnCancel = createRef();
        this.refBtnSave = createRef();
        this.refBtnCloseCancel = createRef();
        this.refBtnCloseSave = createRef();
        this.refBtnBack = createRef();
    }

    static defaultProps = {
        maxResults: 20,
        mode: "create",
    }

    handleKeyPress = (event) =>
    {
        if (event.key === 'Enter')
        {
            if (this.props.mode === "view")
                this.refBtnBack.current.click();
        }
    }

    handleRuleChange = (ruleIndex, field, value) =>
    {
        this.setState(prevState =>
        {
            const ruleList = [...prevState.ruleList];
            ruleList[ruleIndex][field] = value;
            return { ruleList };
        });
    };

    handleAcceptedFormatsChange = (ruleIndex, acceptedFormatsState, update) =>
    {
        this.setState(prevState =>
        {
            const ruleList = [...prevState.ruleList];
            ruleList[ruleIndex].imageFormats = acceptedFormatsState;
            return { ruleList };
        }, () => update && update());
    };

    handleParamChange = (ruleIndex, paramIndex, paramState) =>
    {
        this.setState(prevState =>
        {
            const ruleList = [...prevState.ruleList];
            ruleList[ruleIndex].ruleParameters[paramIndex] = paramState;
            return { ruleList };
        });
    };

    addParam = (ruleIndex) =>
    {
        let ruleParameters = this.state.ruleList[ruleIndex].ruleParameters;
        const parameterDefaultsDataCopy = JSON.parse(JSON.stringify(parameterDefaultsData));
        parameterDefaultsDataCopy.parameterId = this.state.parameters.length ? this.state.parameters[0].parameterId : 0;
        parameterDefaultsDataCopy.errorMessage = this.state.parameters.length ? this.state.parameters[0].errorMessage : "";
        ruleParameters.push(parameterDefaultsDataCopy);
        this.setState(prevState =>
        {
            const ruleList = [...prevState.ruleList];
            ruleList[ruleIndex].ruleParameters = ruleParameters;
            return { ruleList };
        });
    };

    removeParam = (ruleIndex, paramIndex) =>
    {
        if (this.state.ruleList[ruleIndex].ruleParameters.length)
        {
            let ruleParameters = this.state.ruleList[ruleIndex].ruleParameters;
            ruleParameters.splice(paramIndex, 1);
            this.setState(prevState =>
            {
                const ruleList = [...prevState.ruleList];
                ruleList[ruleIndex].ruleParameters = ruleParameters;
                return { ruleList };
            });
        }
    };

    initNewRule = () =>
    {
        const ruleDefaultsDataCopy = JSON.parse(JSON.stringify(ruleDefaultsData));
        const ruleList = [];
        ruleList.unshift(ruleDefaultsDataCopy);
        this.setState({ ruleList: ruleList });
    }

    getAllowedImageFormats ()
    {
        this.setState({
            isLoadingImageFormats: true,
        })
        http.get(API_IMAGE_FORMAT_LIST)
            .then((response) =>
            {
                const imageFormats = response.data.data.list.map((imageFormat) => ({ label: imageFormat.name, value: imageFormat.imageFormatId }));
                this.setState({
                    imageFormats: imageFormats,
                });
            })
            .catch((error) =>
            {
                console.log(error)
            })
            .finally(() =>
            {
                this.setState({
                    isLoadingImageFormats: false,
                });
            })
    }

    getParametersList ()
    {
        this.setState({
            isLoadingParameters: true,
        })
        http.get(API_PARAMETER_LIST)
            .then((response) =>
            {
                this.setState({
                    parameters: response.data.data.list,
                    sizeParameterId: response.data.data.list.find(parameter => parameter.parameterKey === ParameterKeys.SIZE).parameterId ?? 0,
                });
            })
            .catch((error) =>
            {
                console.log(error)
            })
            .finally(() =>
            {
                this.setState({
                    isLoadingParameters: false,
                });
            })
    }

    handleFetchRule = () =>
    {
        if (!this.props.ruleId) return;
        this.setState({
            isLoadingRule: true,
        });
        const username = localStorage.getItem('username');
        http.post(`${API_RULE_BASE}/${this.props.mode === "view" ? API_RULE_DETAILS_REL : API_RULE_FETCH_REL}`, {
            ruleId: this.props.ruleId,
            username: username,
        })
            .then((response) =>
            {
                const ruleList = [];
                let data = response.data.data;
                if (this.props.mode === "copy")
                    data.name += ' (copia)';
                if (this.props.mode === "view")
                    data.ruleParameters = data.parameters;
                if (this.props.mode !== "view")
                    data.imageFormats = data.imageFormats.map((imageFormat) => ({ label: imageFormat.name, value: imageFormat.imageFormatId }));
                const biometrics = [...data.biometrics].map((parameter) => ({
                    ...parameter,
                    name: BiometryData.find(bparameter => bparameter.parameterKey === parameter.parameterKey).name ?? '',
                    validationErrorEmptyFromX: false,
                    validationErrorEmptyToX: false,
                    validationErrorX: false,
                    validationErrorEmptyFromY: false,
                    validationErrorEmptyToY: false,
                    validationErrorY: false,
                }));
                ruleList.unshift(data);
                this.setState({
                    ruleList: ruleList,
                    biometrics: biometrics,
                });
            })
            .catch((error) =>
            {
                console.log(error)
            })
            .finally(() =>
            {
                this.setState({
                    isLoadingRule: false,
                });
            })
    };

    handleSaveRule = (e, ruleIndex) =>
    {
        const rule = { ...this.state.ruleList[ruleIndex] };
        rule.imageFormats = rule.imageFormats.map((imageFormat) => ({ imageFormatId: imageFormat.value }));
        rule.biometrics = this.state.biometrics;
        if (this.props.mode === "copy")
            delete rule.ruleId;

        const username = localStorage.getItem('username');
        rule.username = username;
        e.target.disabled = true;
        const loadingSpinner = e.target.querySelector('span');
        loadingSpinner.classList.remove('hidden');
        this.setState({
            errMessages: [],
        })
        http.post(API_RULE_SAVE, rule)
            .then((response) =>
            {
                localStorage.setItem("snackbar", true);
                localStorage.setItem("msg", `La regla ${rule.name} ha sido ${this.props.mode === "create" ? "creada" : "actualizada"}.`)
                this.props.navigate('/');
            })
            .catch((error) =>
            {
                this.setState({
                    errMessages: error.response ? error.response.data?.messages ?? [{ code: "", message: "Error desconocido" }] : [{ code: "", message: "Error de red" }],
                })
                window.scrollTo({ top: 0, behavior: 'smooth' });
            })
            .finally(() =>
            {
                loadingSpinner.classList.add('hidden');
                e.target.disabled = false;
            })
    }

    handleDiscardChanges ()
    {
        this.refBtnCancel.current.click();
    }

    isValidForm (ruleIndex)
    {
        const rules = this.state.ruleList;
        let valid = true;
        let addedParameters = [];
        rules[ruleIndex].ruleParameters = rules[ruleIndex].ruleParameters.map((ruleParameter) =>
        {
            let validationErrorX = false;
            let validationErrorY = false;
            let validationErrorEmptyMinX = false;
            let validationErrorEmptyMaxX = false;
            let validationErrorEmptyMinY = false;
            let validationErrorEmptyMaxY = false;
            let validationErrorEmptyMinValue = false;
            let validationErrorEmptyMaxValue = false;
            let validationErrorInvalidHexMinValue = false;
            let validationErrorInvalidHexMaxValue = false;
            let validationErrorMsgError = false;
            if (!ruleParameter.errorMessage.length)
            {
                validationErrorMsgError = true;
                valid = false;
            }
            const parameter = this.state.parameters.find((parameter) => parameter.parameterId === ruleParameter.parameterId);
            if (parameter.groupType === 1)
            {
                if (!ruleParameter.minX.length)
                {
                    validationErrorEmptyMinX = true;
                    valid = false;
                }
                if (!ruleParameter.maxX.length)
                {
                    validationErrorEmptyMaxX = true;
                    valid = false;
                }
                if (!ruleParameter.minY.length)
                {
                    validationErrorEmptyMinY = true;
                    valid = false;
                }
                if (!ruleParameter.maxY.length)
                {
                    validationErrorEmptyMaxY = true;
                    valid = false;
                }
                if (!validationErrorEmptyMinX && !validationErrorEmptyMaxX && parseInt(ruleParameter.minX) > parseInt(ruleParameter.maxX))
                {
                    validationErrorX = true;
                    valid = false;
                }
                if (!validationErrorEmptyMinY && !validationErrorEmptyMaxY && parseInt(ruleParameter.minY) > parseInt(ruleParameter.maxY))
                {
                    validationErrorY = true;
                    valid = false;
                }
            }
            if (parameter.groupType === 2)
            {
                if (!ruleParameter.minValue.length)
                {
                    validationErrorEmptyMinValue = true;
                    valid = false;
                }
                else
                {
                    if (parameter.measurementUnit === "hex" && !/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(ruleParameter.minValue))
                    {
                        validationErrorInvalidHexMinValue = true;
                        valid = false;
                    }
                }
                if (!ruleParameter.maxValue.length)
                {
                    validationErrorEmptyMaxValue = true;
                    valid = false;
                }
                else
                {
                    if (parameter.measurementUnit === "hex" && !/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(ruleParameter.minValue))
                    {
                        validationErrorInvalidHexMaxValue = true;
                        valid = false;
                    }
                }
            }
            const added = addedParameters.some((added) => added === parameter.parameterId);
            addedParameters.push(parameter.parameterId);
            added && (valid = false);
            return ({
                ...ruleParameter,
                validationErrorX: validationErrorX,
                validationErrorY: validationErrorY,
                validationErrorEmptyMinX: validationErrorEmptyMinX,
                validationErrorEmptyMaxX: validationErrorEmptyMaxX,
                validationErrorEmptyMinY: validationErrorEmptyMinY,
                validationErrorEmptyMaxY: validationErrorEmptyMaxY,
                validationErrorEmptyMinValue: validationErrorEmptyMinValue,
                validationErrorEmptyMaxValue: validationErrorEmptyMaxValue,
                validationErrorInvalidHexMinValue: validationErrorInvalidHexMinValue,
                validationErrorInvalidHexMaxValue: validationErrorInvalidHexMaxValue,
                validationErrorMsgError: validationErrorMsgError,
                validationErrorDuplicated: added,
            })
        })
        let validationErrorMsgError = false;
        let validationErrorCharacteristics = false;
        let validationErrorName = false;
        let validationErrorImageFormats = false;
        if (!rules[ruleIndex].errorMessage.length)
        {
            validationErrorMsgError = true;
            valid = false;
        }
        if (!rules[ruleIndex].characteristics.length)
        {
            validationErrorCharacteristics = true;
            valid = false;
        }
        if (!rules[ruleIndex].name.length)
        {
            validationErrorName = true;
            valid = false;
        }
        if (!rules[ruleIndex].imageFormats.length)
        {
            validationErrorImageFormats = true;
            valid = false;
        }
        rules[ruleIndex].validationErrorMsgError = validationErrorMsgError;
        rules[ruleIndex].validationErrorCharacteristics = validationErrorCharacteristics;
        rules[ruleIndex].validationErrorName = validationErrorName;
        rules[ruleIndex].validationErrorImageFormats = validationErrorImageFormats;

        const biometrics = this.state.biometrics.map((parameter) =>
        {
            let validationErrorEmptyFromX = false;
            let validationErrorEmptyFromY = false;
            let validationErrorEmptyToX = false;
            let validationErrorEmptyToY = false;
            let validationErrorX = false;
            let validationErrorY = false;
            if (parseInt(parameter.fromX) === 0)
            {
                validationErrorEmptyFromX = true;
                valid = false;
            }
            if (parseInt(parameter.fromY) === 0)
            {
                validationErrorEmptyFromY = true;
                valid = false;
            }
            if (parseInt(parameter.toX) === 0)
            {
                validationErrorEmptyToX = true;
                valid = false;
            }
            if (parseInt(parameter.toY) === 0)
            {
                validationErrorEmptyToY = true;
                valid = false;
            }
            if (parseInt(parameter.fromX) > parseInt(parameter.toX))
            {
                validationErrorX = true;
                valid = false;
            }
            if (parseInt(parameter.fromY) > parseInt(parameter.toY))
            {
                validationErrorY = true;
                valid = false;
            }
            return ({
                ...parameter,
                validationErrorEmptyFromX: validationErrorEmptyFromX,
                validationErrorEmptyToX: validationErrorEmptyToX,
                validationErrorEmptyFromY: validationErrorEmptyFromY,
                validationErrorEmptyToY: validationErrorEmptyToY,
                validationErrorX: validationErrorX,
                validationErrorY: validationErrorY,
            })
        })

        this.setState({
            ruleList: rules,
            biometrics: biometrics,
        })
        return valid;
    }

    handleSaveChanges (e, ruleIndex)
    {
        if (!this.isValidForm(ruleIndex)) return;
        this.refBtnSave.current.click();
    }

    handleBiometricsChange (index, key, value)
    {
        const biometrics = [...this.state.biometrics];
        biometrics[index] = { ...biometrics[index], [key]: value }
        this.setState({
            biometrics: biometrics,
        });
    }

    handleCopyRule (ruleIndex)
    {
        const rule = this.state.ruleList[ruleIndex];
        this.setState(prevState =>
        {
            const ruleList = [...prevState.ruleList];
            ruleList.unshift(rule);
            return { ruleList };
        });
    }

    componentDidMount ()
    {
        this.getAllowedImageFormats();
        this.getParametersList();
        this.props.mode === "create" ? this.initNewRule() : this.handleFetchRule();
        document.addEventListener('keydown', this.handleKeyPress, false);
    }

    preventChars = (e) =>
    {
        const key = e.key;
        if (!(key === 'Backspace' || key === 'ArrowLeft' || key === 'ArrowRight' || key === 'Tab'))
        {
            if (!/[0-9\b]/.test(key))
            {
                e.preventDefault();
            }
        }
    }

    componentWillUnmount ()
    {
        document.removeEventListener('keydown', this.handleKeyPress, false);
    }

    render ()
    {
        const { ruleList } = this.state;
        const handleBiometricsChange = this.handleBiometricsChange.bind(this);
        const preventChars = this.preventChars.bind(this);
        const canvas_size = this.state.ruleList.length ? (this.state.ruleList[0].ruleParameters.find(parameter => parameter.parameterId === this.state.sizeParameterId) ?? CANVAS_SIZE) : CANVAS_SIZE;
        return (
            <>
                <div className='verification-rules'>
                    {
                        (this.state.isLoadingImageFormats ||
                            this.state.isLoadingParameters ||
                            this.state.isLoadingRule) ?
                            <>
                                <Loading />
                            </> :
                            <>
                                <div className='row'>
                                    <div className='col'>
                                        {
                                            !ruleList.length ?
                                                <>
                                                    <p className='mb-0'>Ocurrió un error al obtener la regla.</p>
                                                </> : <>
                                                    {
                                                        ruleList.map((rule, ruleIndex) => (
                                                            <Fragment key={ruleIndex}>
                                                                {
                                                                    this.props.mode === "view" ?
                                                                        <>
                                                                            <h6 className='subtitle-1 mb-3'>Datos de la regla:</h6>
                                                                        </> :
                                                                        <>
                                                                            <h6 className='subtitle-1 mb-3'>Complete los siguientes datos:</h6>
                                                                        </>
                                                                }
                                                                {
                                                                    (this.state.errMessages && this.state.errMessages.length) ? <div className="alert alert-danger" role="alert">
                                                                        {
                                                                            this.state.errMessages.map((err, index) =>
                                                                            {
                                                                                return (
                                                                                    <Fragment key={index}>
                                                                                        <p className='mb-0'> {err.code?.length ? `[${err.code}]` : ""} {err.message}</p>
                                                                                    </Fragment>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div> : <></>
                                                                }
                                                                {
                                                                    this.props.mode === "view" ?
                                                                        <>
                                                                            <p><span className='subtitle-2'>Nombre de la regla: </span><span className='body-2'>{rule.name}</span></p>
                                                                        </> :
                                                                        <>
                                                                            <div className="form-floating mb-3">
                                                                                <input type="text" className="form-control"
                                                                                    id="nameInput" placeholder="Nombre" value={rule.name} required
                                                                                    onChange={e => this.handleRuleChange(ruleIndex, 'name', e.target.value)}
                                                                                />
                                                                                <label htmlFor="nameInput">Nombre</label>
                                                                                {
                                                                                    rule?.validationErrorName ?
                                                                                        <>
                                                                                            <div className="d-block invalid-feedback">
                                                                                                Este campo es obligatorio
                                                                                            </div>
                                                                                        </> : <></>
                                                                                }
                                                                            </div>
                                                                        </>
                                                                }
                                                                {
                                                                    this.props.mode === "view" ?
                                                                        <>
                                                                            <p><span className='subtitle-2'>Características: </span><span className='body-2'>{rule.characteristics}</span></p>
                                                                        </> :
                                                                        <>
                                                                            <div className="form-floating mb-3">
                                                                                <textarea type="text" className="form-control" required
                                                                                    id="featuresInput" placeholder="Características" value={rule.characteristics}
                                                                                    onChange={e => this.handleRuleChange(ruleIndex, 'characteristics', e.target.value)}
                                                                                    rows={3} style={{ height: '6rem' }}
                                                                                />
                                                                                <label htmlFor="featuresInput">Características</label>
                                                                                {
                                                                                    rule?.validationErrorCharacteristics ?
                                                                                        <>
                                                                                            <div className="d-block invalid-feedback">
                                                                                                Este campo es obligatorio
                                                                                            </div>
                                                                                        </> : <></>
                                                                                }
                                                                            </div>
                                                                        </>
                                                                }
                                                                {
                                                                    this.props.mode === "view" ?
                                                                        <>
                                                                            <p><span className='subtitle-2'>Mensaje de error de la regla: </span><span className='body-2'>{rule.errorMessage}</span></p>
                                                                        </> :
                                                                        <>
                                                                            <div className="form-floating mb-3">
                                                                                <input type="text" className="form-control"
                                                                                    required
                                                                                    id="errorMessageInput" placeholder="Mensaje de error de la regla" value={rule.errorMessage}
                                                                                    onChange={e => this.handleRuleChange(ruleIndex, 'errorMessage', e.target.value)}
                                                                                />
                                                                                <label htmlFor="errorMessageInput">Mensaje de error de la regla</label>
                                                                                {
                                                                                    rule?.validationErrorMsgError ?
                                                                                        <>
                                                                                            <div className="d-block invalid-feedback">
                                                                                                Este campo es obligatorio
                                                                                            </div>
                                                                                        </> : <></>
                                                                                }
                                                                            </div>
                                                                        </>
                                                                }
                                                                {
                                                                    this.props.mode === "view" ?
                                                                        <>
                                                                            <p><span className='subtitle-2'>Formatos permitidos: </span><span className='body-2'>{rule.imageFormats}</span></p>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <div className="form-floating mb-3">
                                                                                <MultiSelect
                                                                                    required
                                                                                    options={this.state.imageFormats}
                                                                                    value={rule.imageFormats}
                                                                                    onChange={(acceptedFormatsState) => this.handleAcceptedFormatsChange(ruleIndex, acceptedFormatsState)}
                                                                                    labelledBy="Selectionar"
                                                                                    className='form-control ul-multi-select'
                                                                                    overrideStrings={{
                                                                                        allItemsAreSelected: "Todos",
                                                                                        clearSearch: "Limpiar",
                                                                                        clearSelected: "Limpiar seleccionados",
                                                                                        noOptions: "Sin opciones",
                                                                                        search: "Buscar",
                                                                                        selectAll: "Seleccionar todo",
                                                                                        selectAllFiltered: "Seleccionar todo (filtrado)",
                                                                                        selectSomeItems: "Formatos aceptados",
                                                                                        create: "Crear",
                                                                                    }}
                                                                                    id="formatsInput"
                                                                                />
                                                                                <label htmlFor="formatsInput">Formatos aceptados</label>
                                                                                {
                                                                                    rule?.validationErrorImageFormats ?
                                                                                        <>
                                                                                            <div className="d-block invalid-feedback">
                                                                                                Este campo es obligatorio
                                                                                            </div>
                                                                                        </> : <></>
                                                                                }
                                                                            </div>
                                                                        </>
                                                                }
                                                                {
                                                                    this.props.mode === "view" ?
                                                                        <>
                                                                            <p><span className='subtitle-2'>Estado: </span><span className='body-2'>{rule.active ? "Activa" : "Inactiva"}</span></p>
                                                                        </> : <></>
                                                                }
                                                                {
                                                                    this.props.mode === "edit" ?
                                                                        <>
                                                                            <div className="form-check">
                                                                                <input className="form-check-input" type="checkbox" value={rule.active} id="isActiveCheck" defaultChecked={rule.active}
                                                                                    onChange={e => this.handleRuleChange(ruleIndex, 'active', e.target.checked)} />
                                                                                <label className="form-check-label" htmlFor="isActiveCheck">
                                                                                    Mostrar como activa
                                                                                </label>
                                                                            </div>
                                                                        </> : <></>
                                                                }
                                                                <h6 className='subtitle-1 mb-3'>Lista de parámetros:</h6>
                                                                {
                                                                    rule.ruleParameters.map((param, paramIndex) => (
                                                                        <Fragment key={paramIndex}>
                                                                            <div className='parameter-container mb-3'>
                                                                                <div className='mb-3'>
                                                                                    <p className='mb-0'>Parámetro {paramIndex + 1}:</p>
                                                                                </div>
                                                                                <Parameter
                                                                                    groupTypes={this.state.parameters}
                                                                                    mode={this.props.mode}
                                                                                    ruleId={ruleIndex}
                                                                                    parameter={param}
                                                                                    onChange={parameterState => this.handleParamChange(ruleIndex, paramIndex, parameterState)} />
                                                                                {
                                                                                    this.props.mode === "view" ?
                                                                                        <>
                                                                                        </> :
                                                                                        <>
                                                                                            <div className='d-flex justify-content-end mt-4'>
                                                                                                <button style={{ color: 'var(--ul-error-500)' }} className='btn btn-link text-decoration-none p-0 m-0'
                                                                                                    type='button'
                                                                                                    onClick={() => this.removeParam(ruleIndex, paramIndex)}>
                                                                                                    <i className="bi bi-trash me-2"></i>
                                                                                                    <span>Eliminar parámetro</span>
                                                                                                </button>
                                                                                            </div>
                                                                                        </>
                                                                                }
                                                                                {
                                                                                    param?.validationErrorDuplicated ?
                                                                                        <>
                                                                                            <div className="d-block invalid-feedback">
                                                                                                Lo sentimos, no se puede crear una regla con parámetros repetidos
                                                                                            </div>
                                                                                        </> : <></>
                                                                                }
                                                                            </div>
                                                                        </Fragment>
                                                                    ))
                                                                }
                                                                {
                                                                    rule.ruleParameters && !rule.ruleParameters.length ?
                                                                        <>
                                                                            <small className='d-block opacity-75 mb-3'>No se han definido parámetros de verificación</small>
                                                                        </> : <></>
                                                                }
                                                                {
                                                                    this.props.mode === "view" ?
                                                                        <>

                                                                            <h6 className='subtitle-1 mb-3'>Parámetros de biometría:</h6>
                                                                            <div className='row'>
                                                                                <div className='col'>
                                                                                    {
                                                                                        this.state.biometrics.map((parameter, index) => (
                                                                                            <Fragment key={index}>
                                                                                                <div className="parameter-container mb-3">
                                                                                                    <div className="mb-3">
                                                                                                        <p className="mb-0">{parameter.name}:</p>
                                                                                                    </div>
                                                                                                    <div className="row mb-3">
                                                                                                        <div className="col">
                                                                                                            <div className="mb-0">
                                                                                                                <p><span className='subtitle-2'>Eje X Desde - Hasta: </span><span className='body-2'>{parameter.fromX} - {parameter.toX}</span></p>
                                                                                                                <p className='mb-0'><span className='subtitle-2'>Eje Y Desde - Hasta: </span><span className='body-2'>{parameter.fromY} - {parameter.toY}</span></p>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </Fragment>
                                                                                        ))
                                                                                    }
                                                                                </div>
                                                                            </div>

                                                                            <div className='d-flex justify-content-end'>
                                                                                <button ref={this.refBtnBack} className='btn btn-primary' type='button' onClick={() => { this.props.navigate(-1) }}>Volver</button>
                                                                            </div>
                                                                        </> :
                                                                        <>
                                                                            <div className='mb-3'>
                                                                                <button className='btn btn-link text-decoration-none p-0 m-0'
                                                                                    type='button'
                                                                                    onClick={() => this.addParam(ruleIndex)}>
                                                                                    <i className="bi bi-plus-lg me-2"></i>
                                                                                    <span>Agregar parámetro</span>
                                                                                </button>
                                                                            </div>
                                                                            <div className='mb-4'>
                                                                                <h6 className='subtitle-1 mb-3'>Parámetros de biometría:</h6>
                                                                                <div className='row'>
                                                                                    <div className='col'>
                                                                                        {
                                                                                            this.state.biometrics.map((parameter, index) => (
                                                                                                <Fragment key={index}>
                                                                                                    <div className='parameter-container mb-3'>
                                                                                                        <div className='mb-3'>
                                                                                                            <p className='mb-0'>{parameter.name}</p>
                                                                                                            <div className='row'>
                                                                                                                <div className='col-2 col-md-1 align-self-center'>
                                                                                                                    Eje X:
                                                                                                                </div>
                                                                                                                <div className='col-10 col-md-5'>
                                                                                                                    <div className='row g-2'>
                                                                                                                        <div className='col'>
                                                                                                                            <div className="form-floating">
                                                                                                                                <input
                                                                                                                                    type="text" className="form-control"
                                                                                                                                    required
                                                                                                                                    id={`fromX${index}`}
                                                                                                                                    placeholder="Desde"
                                                                                                                                    value={parameter.fromX ?? ''}
                                                                                                                                    onKeyDown={preventChars}
                                                                                                                                    onChange={(e) => handleBiometricsChange(index, 'fromX', e.target.value)} />
                                                                                                                                <label htmlFor={`fromX${index}`}>Desde</label>
                                                                                                                                {
                                                                                                                                    parameter.validationErrorEmptyFromX ?
                                                                                                                                        <>
                                                                                                                                            <div className="d-block invalid-feedback">
                                                                                                                                                Este campo es obligatorio
                                                                                                                                            </div>
                                                                                                                                        </> : <></>
                                                                                                                                }
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className='col'>
                                                                                                                            <div className="form-floating">
                                                                                                                                <input type="text" className="form-control"
                                                                                                                                    id={`toX${index}`}
                                                                                                                                    required
                                                                                                                                    placeholder="Hasta" value={parameter.toX ?? ''}
                                                                                                                                    onKeyDown={preventChars}
                                                                                                                                    onChange={(e) => handleBiometricsChange(index, 'toX', e.target.value)} />
                                                                                                                                <label htmlFor={`toX${index}`}>Hasta</label>
                                                                                                                                {
                                                                                                                                    parameter.validationErrorEmptyToX ?
                                                                                                                                        <>
                                                                                                                                            <div className="d-block invalid-feedback">
                                                                                                                                                Este campo es obligatorio
                                                                                                                                            </div>
                                                                                                                                        </> : <></>
                                                                                                                                }
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    {
                                                                                                                        parameter.validationErrorX ?
                                                                                                                            <>
                                                                                                                                <div className="d-block invalid-feedback">
                                                                                                                                    El valor mínimo debe ser menor que el valor máximo
                                                                                                                                </div>
                                                                                                                            </> : <></>
                                                                                                                    }
                                                                                                                </div>
                                                                                                                <div className='col-2 col-md-1 align-self-center'>
                                                                                                                    Eje Y:
                                                                                                                </div>
                                                                                                                <div className='col-10 col-md-5'>
                                                                                                                    <div className='row g-2'>
                                                                                                                        <div className='col'>
                                                                                                                            <div className="form-floating">
                                                                                                                                <input type="text" className="form-control"
                                                                                                                                    id={`fromY${index}`}
                                                                                                                                    required
                                                                                                                                    placeholder="Desde" value={parameter.fromY ?? ''}
                                                                                                                                    onKeyDown={preventChars}
                                                                                                                                    onChange={(e) => handleBiometricsChange(index, 'fromY', e.target.value)} />
                                                                                                                                <label htmlFor={`fromY${index}`}>Desde</label>
                                                                                                                                {
                                                                                                                                    parameter.validationErrorEmptyFromY ?
                                                                                                                                        <>
                                                                                                                                            <div className="d-block invalid-feedback">
                                                                                                                                                Este campo es obligatorio
                                                                                                                                            </div>
                                                                                                                                        </> : <></>
                                                                                                                                }
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className='col'>
                                                                                                                            <div className="form-floating">
                                                                                                                                <input type="text" className="form-control"
                                                                                                                                    id={`toY${index}`}
                                                                                                                                    required
                                                                                                                                    placeholder="Hasta" value={parameter.toY ?? ''}
                                                                                                                                    onKeyDown={this.preventChars}
                                                                                                                                    onChange={(e) => handleBiometricsChange(index, 'toY', e.target.value)} />
                                                                                                                                <label htmlFor={`toY${index}`}>Hasta</label>
                                                                                                                                {
                                                                                                                                    parameter.validationErrorEmptyToY ?
                                                                                                                                        <>
                                                                                                                                            <div className="d-block invalid-feedback">
                                                                                                                                                Este campo es obligatorio
                                                                                                                                            </div>
                                                                                                                                        </> : <></>
                                                                                                                                }
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    {
                                                                                                                        parameter.validationErrorY ?
                                                                                                                            <>
                                                                                                                                <div className="d-block invalid-feedback">
                                                                                                                                    El valor mínimo debe ser menor que el valor máximo
                                                                                                                                </div>
                                                                                                                            </> : <></>
                                                                                                                    }
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </Fragment>
                                                                                            ))
                                                                                        }
                                                                                        {
                                                                                            this.state.biometrics && !this.state.biometrics.length ?
                                                                                                <>
                                                                                                    <small className='d-block opacity-75 mb-3'>No se han definido parámetros de biometría</small>
                                                                                                </> : <></>
                                                                                        }
                                                                                    </div>
                                                                                    <div className='col' style={{ flex: `0 0 ${parseInt(canvas_size.maxX) + 16}px` }}>
                                                                                        <BiometricsPreview
                                                                                            sizeParameter={canvas_size}
                                                                                            rightEye={
                                                                                                this.state.biometrics.find(parameter => parameter.parameterKey === BiometryKeys.EYES_POSITION_RIGHT) ?? {
                                                                                                    fromX: 100,
                                                                                                    toX: 150,
                                                                                                    fromY: 50,
                                                                                                    toY: 100,
                                                                                                }
                                                                                            }
                                                                                            leftEye={
                                                                                                this.state.biometrics.find(parameter => parameter.parameterKey === BiometryKeys.EYES_POSITION_LEFT) ?? {
                                                                                                    fromX: 25,
                                                                                                    toX: 75,
                                                                                                    fromY: 50,
                                                                                                    toY: 100,
                                                                                                }
                                                                                            }
                                                                                            mouth={
                                                                                                this.state.biometrics.find(parameter => parameter.parameterKey === BiometryKeys.MOUTH_POSITION) ?? {
                                                                                                    fromX: 50,
                                                                                                    toX: 150,
                                                                                                    fromY: 100,
                                                                                                    toY: 150,
                                                                                                }
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='d-flex justify-content-end'>
                                                                                <button className='me-3 btn btn-outline-primary' type='button' onClick={() => this.handleDiscardChanges()}>Cancelar</button>
                                                                                <button className='btn btn-primary' onClick={e => this.handleSaveChanges(e, ruleIndex)}>
                                                                                    <span className="spinner-border spinner-border-sm me-2 hidden" role="status" aria-hidden="true"></span>
                                                                                    {(this.props.mode === "create" || this.props.mode === "copy") ? "Crear regla" : "Guardar cambios"}
                                                                                </button>
                                                                            </div>
                                                                        </>
                                                                }

                                                                <button ref={this.refBtnCancel} type="button" hidden className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#cancelCreationEditionModal">
                                                                    Cancel
                                                                </button>
                                                                <div className="modal fade" id="cancelCreationEditionModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="cancelCreationEditionModalLabel" aria-hidden="true">
                                                                    <div className="modal-dialog modal-dialog-centered">
                                                                        <div className="modal-content">
                                                                            <div className="modal-header">
                                                                                <h5 className='mb-0' id="cancelCreationEditionModalLabel">Cancelar {this.props.mode === "create" ? "creación" : "edición"} de la regla</h5>
                                                                            </div>
                                                                            <div className="modal-body">
                                                                                <p className='mb-0'>
                                                                                    ¿Estás seguro(a) que deseas cancelar la {this.props.mode === "create" ? "creación" : "edición"} de la regla y volver a la pantalla de reglas?
                                                                                </p>
                                                                            </div>
                                                                            <div className="modal-footer">
                                                                                <button ref={this.refBtnCloseCancel} type="button" className="btn btn-outline-primary" data-bs-dismiss="modal">Cerrar</button>
                                                                                <button className="btn btn-primary" type='button' data-bs-dismiss="modal" onClick={() =>
                                                                                {
                                                                                    this.refBtnCloseCancel.current.click();
                                                                                    this.props.navigate('/');
                                                                                }}>Confirmar cancelación</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <button ref={this.refBtnSave} type="button" hidden className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#saveCreationEditionModal">
                                                                    Save
                                                                </button>
                                                                <div className="modal fade" id="saveCreationEditionModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="saveCreationEditionModalLabel" aria-hidden="true">
                                                                    <div className="modal-dialog modal-dialog-centered">
                                                                        <div className="modal-content">
                                                                            <div className="modal-header">
                                                                                <h5 className='mb-0' id="saveCreationEditionModalLabel">
                                                                                    {
                                                                                        (this.props.mode === "create" || this.props.mode === "copy") ?
                                                                                            <>
                                                                                                Crear regla
                                                                                            </> :
                                                                                            <>
                                                                                                Guardar cambios
                                                                                            </>
                                                                                    }
                                                                                </h5>
                                                                            </div>
                                                                            <div className="modal-body">
                                                                                <p className='mb-0'>
                                                                                    {
                                                                                        this.props.mode === "create" ?
                                                                                            <>
                                                                                                ¿Estás seguro(a) de que deseas crear nueva la regla "{rule.name}"?
                                                                                            </> :
                                                                                            <>
                                                                                                ¿Estás seguro(a) de que deseas guardar los cambios realizados en la regla "{rule.name}"?
                                                                                            </>
                                                                                    }
                                                                                </p>
                                                                            </div>
                                                                            <div className="modal-footer">
                                                                                <button ref={this.refBtnCloseSave} type="button" className="btn btn-outline-primary" data-bs-dismiss="modal">Cerrar</button>
                                                                                <button className="btn btn-primary" type='button' data-bs-dismiss="modal" onClick={(e) =>
                                                                                {
                                                                                    this.refBtnCloseSave.current.click();
                                                                                    this.handleSaveRule(e, 0);
                                                                                }}>
                                                                                    <span className="spinner-border spinner-border-sm me-2 hidden" role="status" aria-hidden="true"></span>
                                                                                    {
                                                                                        this.props.mode === "create" ?
                                                                                            <>
                                                                                                Confirmar creación
                                                                                            </> :
                                                                                            <>
                                                                                                Confirmar
                                                                                            </>
                                                                                    }
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Fragment>))
                                                    }
                                                </>
                                        }
                                    </div>
                                </div>
                            </>
                    }
                </div>
            </>
        );
    }
}

export default BaseRule;