import React, { Component, Fragment } from 'react';
import './PasswordUpdate.css';
import Layout from '../../../components/Layout/Layout';
import { Link } from 'react-router-dom';
import http from '../../../api/HTTPService';
import InputPassword from '../../../components/InputPassword';
import { API_USER_CHANGE_PASSWORD } from '../../../constant/APIUrls';

class PasswordUpdate extends Component
{
    constructor (props)
    {
        super(props);
        this.state = {
            oldPassword: '',
            newPassword: '',
            newPasswordConfirmation: '',

            errOldPassword: '',
            errNewPassword: '',
            errNewPasswordConfirmation: '',
            errMessages: [],
        }
    }

    isValid ()
    {
        let valid = true;
        if (!this.state.oldPassword.length)
        {
            this.setState({
                errOldPassword: "Este campo es obligatorio",
            });
            valid = false;
        }
        if (!this.state.newPassword.length)
        {
            this.setState({
                errNewPassword: "Este campo es obligatorio",
            });
            valid = false;
        }
        if (!this.state.newPasswordConfirmation.length)
        {
            this.setState({
                errNewPasswordConfirmation: "Este campo es obligatorio",
            });
            valid = false;
        }
        if (this.state.newPassword.length && this.state.newPasswordConfirmation.length && this.state.newPassword !== this.state.newPasswordConfirmation)
        {
            this.setState({
                errNewPasswordConfirmation: "Las contraseñas no coinciden",
            });
            valid = false;
        }
        return valid;
    }

    updatePassword (e)
    {
        if (!this.isValid()) return;
        e.target.disabled = true;
        const loadingSpinner = e.target.querySelector('span');
        loadingSpinner.classList.remove('hidden');
        this.setState({
            errMessages: [],
        })
        const userId = localStorage.getItem('userId');
        http.post(API_USER_CHANGE_PASSWORD, {
            userId: userId,
            oldPassword: this.state.oldPassword,
            newPassword: this.state.newPassword,
        })
            .then((response) =>
            {
                localStorage.setItem("snackbar", true);
                localStorage.setItem("msg", `Contraseña actualizada correctamente.`)
                this.props.navigate(-1);
            })
            .catch((error) =>
            {
                this.setState({
                    errMessages: error.response ? error.response.data?.messages ?? [{ code: "", message: "Error desconocido" }] : [{ code: "", message: "Error de red" }],
                })
                window.scrollTo({ top: 0, behavior: 'smooth' });
            })
            .finally(() =>
            {
                loadingSpinner.classList.add('hidden');
                e.target.disabled = false;
            })
    }

    handleOldPasswordChange (e)
    {
        this.setState({
            errOldPassword: '',
            oldPassword: e.target.value,
        })
    }

    handleNewPasswordChange (e)
    {
        this.setState({
            errNewPassword: '',
            newPassword: e.target.value,
        })
    }

    handleNewPasswordConfirmationChange (e)
    {
        this.setState({
            errNewPasswordConfirmation: '',
            newPasswordConfirmation: e.target.value,
        })
    }

    render ()
    {
        const updatePassword = this.updatePassword.bind(this);
        const handleOldPasswordChange = this.handleOldPasswordChange.bind(this);
        const handleNewPasswordChange = this.handleNewPasswordChange.bind(this);
        const handleNewPasswordConfirmationChange = this.handleNewPasswordConfirmationChange.bind(this);
        const username = localStorage.getItem('username');

        return (
            <>
                <Layout noSidebar={true}>
                    <div className='row my-3'>
                        <div className='col'>
                            <h4 className='mb-0'>Actualizar contraseña</h4>
                        </div>
                    </div>
                    <form>
                        <div className='row mb-3'>
                            <div className='col'>
                                <div className="card search-filters-container">
                                    <div className="card-body">
                                        {
                                            (this.state.errMessages && this.state.errMessages.length) ? <div className="alert alert-danger" role="alert">
                                                {
                                                    this.state.errMessages.map((err, index) =>
                                                    {
                                                        return (
                                                            <Fragment key={index}>
                                                                <p className='mb-0'> {err.code?.length ? `[${err.code}]` : ""} {err.message}</p>
                                                            </Fragment>
                                                        )
                                                    })
                                                }
                                            </div> : <></>
                                        }
                                        <h6 className='subtitle-1 mb-3'>Complete los campos:</h6>
                                        <div className='row row-cols-1 row-cols-md-3 g-3 mb-3'>
                                            <div className='col' hidden>
                                                <div className="form-floating">
                                                    <input className='form-control' autoComplete="off" type="text" name="username" defaultValue={username} />
                                                </div>
                                            </div>
                                            <div className='col'>
                                                <div className="form-floating">
                                                    <InputPassword id="floatInputOldPasword" name="oldPassword" placeholder='Ingrese la antigüa contraseña' value={this.state.oldPassword} onChange={handleOldPasswordChange} />
                                                    <label htmlFor='floatInputOldPasword'>Ingrese la antigüa contraseña</label>
                                                    {
                                                        this.state.errOldPassword.length ?
                                                            <>
                                                                <div className="d-block invalid-feedback">
                                                                    {this.state.errOldPassword}
                                                                </div>
                                                            </> : <></>
                                                    }
                                                </div>
                                            </div>
                                            <div className='col'>
                                                <div className="form-floating">
                                                    <InputPassword id="floatInputNewPasword" name="newPassword" placeholder='Ingrese la nueva contraseña' value={this.state.newPassword} onChange={handleNewPasswordChange} />
                                                    <label htmlFor='floatInputNewPasword'>Ingrese la nueva contraseña</label>
                                                    {
                                                        this.state.errNewPassword.length ?
                                                            <>
                                                                <div className="d-block invalid-feedback">
                                                                    {this.state.errNewPassword}
                                                                </div>
                                                            </> : <></>
                                                    }
                                                </div>
                                            </div>
                                            <div className='col'>
                                                <div className="form-floating">
                                                    <InputPassword id="floatInputNewPaswordConfirmation" name="newPasswordConfirmation" placeholder='Confirmar nueva contraseña' value={this.state.newPasswordConfirmation} onChange={handleNewPasswordConfirmationChange} />
                                                    <label htmlFor='floatInputNewPaswordConfirmation'>Confirmar nueva contraseña</label>
                                                    {
                                                        this.state.errNewPasswordConfirmation.length ?
                                                            <>
                                                                <div className="d-block invalid-feedback">
                                                                    {this.state.errNewPasswordConfirmation}
                                                                </div>
                                                            </> : <></>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col d-flex justify-content-end'>
                                                <Link to={-1} className='btn btn-outline-primary me-3' type='button'>Volver</Link>
                                                <button className='btn btn-primary' type='button' onClick={updatePassword}>
                                                    <span className="spinner-border spinner-border-sm me-2 hidden" role="status" aria-hidden="true"></span>
                                                    Actualizar contraseña</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </Layout>
            </>
        );
    }
}

export default PasswordUpdate;
