import React, { Component, Fragment, createRef } from 'react';
import './ExternalSystemCreate.css';
import Layout from '../../../components/Layout/Layout';
import BaseExternalSystem from '../BaseExternalSystem';
import { ROUTE_SYSTEM_MODULE } from '../../../constant/AppRoutes';

class ExternalSystemCreate extends Component
{
    constructor (props)
    {
        super(props);
        this.refBaseSE = createRef();
    }
    preventNavigation ()
    {
        this.refBaseSE.current?.handleDiscardChanges();
    }

    render ()
    {
        const preventNavigation = this.preventNavigation.bind(this);
        return (
            <>
                <Layout active={ROUTE_SYSTEM_MODULE}>
                    <div className="row mt-3">
                        <div className="col">
                            <nav aria-label="breadcrumb">
                                <ol className="caption breadcrumb mb-0">
                                    <li onClick={preventNavigation} className="breadcrumb-item"><span style={{ color: 'rgba(var(--ul-link-color-rgb), var(--ul-link-opacity, 1))', cursor: 'pointer' }}>Sistemas externos</span></li>
                                    <li className="breadcrumb-item active" aria-current={"page"}>Nuevo sistema externo</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className='row my-3'>
                        <div className='col'>
                            <h4 className='mb-0'>Nuevo sistema externo</h4>
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col'>
                            <div className="card search-filters-container">
                                <div className="card-body">
                                    <BaseExternalSystem ref={this.refBaseSE} navigate={this.props.navigate} systemId={this.props.params.systemId ?? 0} mode="create" />
                                </div>
                            </div>
                        </div>
                    </div>
                </Layout>
            </>
        );
    }
}

export default ExternalSystemCreate;