const Roles = {
    ROLE_MASTER: 'ROLE_MASTER',
    ROLE_ADMIN: 'ROLE_ADMIN',
    ROLE_MANAGER: 'ROLE_MANAGER',
    ALL: 'ALL',
};

const UserModulePermissions = {
    STARTS_WITH: '/user',
    ALLOWED_ROLES: {
        ROLE_MASTER: 'ROLE_MASTER',
        ROLE_ADMIN: 'ROLE_ADMIN',
    }
}

const ParameterKeys = {
    FILE_SIZE: 'FileSize',
    SIZE: 'Size',
    DPI: 'Dpi',
    PERSON: 'Person',
    VALID_EYES: 'ValidEyes',
    COLOR: 'Color',
    BACKGROUND_COLOR: 'BackgroundColor',
    TIE: 'Tie',
    GLASESS: 'Glasses',
    SHIRT: 'Shirt',
    COAT: 'Coat'
}

const BiometryKeys = {
    EYES_POSITION_LEFT: 'EyesPositionLeft',
    EYES_POSITION_RIGHT: 'EyesPositionRight',
    MOUTH_POSITION: 'MouthPosition'
}

export { Roles, UserModulePermissions, ParameterKeys, BiometryKeys };