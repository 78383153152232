import React, { Component, Fragment, createRef, forwardRef } from 'react';
import './ExternalSystemList.css';
import Layout from '../../../components/Layout';
import Pagination from '../../../components/Pagination';
import http from '../../../api/HTTPService';
import { Link } from 'react-router-dom';
import ReactDatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import format from "date-fns/format";
import copy from 'clipboard-copy';
import DateFromString from '../../../util/DateFromString';
import { MultiSelect } from 'react-multi-select-component';
import Loading from '../../../components/Loading';
import Snackbar from '../../../components/Snackbar';
import { Roles } from '../../../constant/Enums';
import { API_EXTERNAL_SYSTEM_RULES, API_EXTERNAL_SYSTEM_SEARCH, API_RULE_LIST } from '../../../constant/APIUrls';
import { ROUTE_REL_SYSTEM_CREATE, ROUTE_REL_SYSTEM_EDIT, ROUTE_REL_SYSTEM_VIEW, ROUTE_SYSTEM_MODULE } from '../../../constant/AppRoutes';

registerLocale("es", es);

class ExternalSystemList extends Component
{
    constructor (props)
    {
        super(props);

        const searchParams = Object.fromEntries([...this.props.searchParams]);
        this.state = {
            totalResults: 0,
            inputName: searchParams.name ?? '',
            inputUpdatedBy: searchParams.updatedBy ?? '',
            inputUpdateDateFrom: DateFromString(searchParams.updateDateFrom),
            inputUpdateDateTo: DateFromString(searchParams.updateDateTo),
            inputOnlyInactive: searchParams.onlyInactive === "true" ?? false,
            rulesList: [],
            rulesSelectedExternalSystemsName: "",

            isLoadingExternalSystemRules: false,

            options: [],
            selectedRulesFilter: searchParams.rules ? JSON.parse(searchParams.rules) : [],

            revealAPIKey: [],
            showTooltip: [],

            isLoadingExternalSystem: false,
            isLoadingRelatedRulesFilter: false,

            showSnackbar: false,
            snackbarMsg: "",

            firstResult: parseInt(searchParams.firstResult ?? 1),

            errMessages: [],
        }
        this.refRulesUnderstoodBtn = createRef();
    }

    static defaultProps = {
        maxResults: 5,
    }

    clearFilters ()
    {
        this.props.setSearchParams('');
        this.setState({
            selectedRulesFilter: [],
            inputName: '',
            inputUpdatedBy: '',
            inputUpdateDateFrom: null,
            inputUpdateDateTo: null,
            inputOnlyInactive: false,
        })
    }

    handleKeyPress = (event) =>
    {
        if (event.key === 'Enter')
        {
            this.handleSearch();
        }
    }

    handleKeyPressRuleList = (event) =>
    {
        if (event.key === 'Enter')
        {
            this.refRulesUnderstoodBtn.current.click();
        }
    }

    updateSearchParam (key, value)
    {
        let searchParams = this.props.searchParams;
        searchParams.set(key, value);
        this.props.setSearchParams(searchParams);
    }

    handleInputChange (key, value)
    {
        this.setState({
            [key]: value,
        })
    }

    handleSearch ()
    {
        let searchParams = this.props.searchParams;
        this.state.inputName.length ?
            searchParams.set('name', this.state.inputName) :
            searchParams.delete('name');
        this.state.inputUpdatedBy.length ?
            searchParams.set('updatedBy', this.state.inputUpdatedBy) :
            searchParams.delete('updatedBy');
        if (this.state.inputUpdateDateFrom)
        {
            const inputUpdateDateFrom = format(this.state.inputUpdateDateFrom, 'dd/MM/yyyy')
            searchParams.set('updateDateFrom', inputUpdateDateFrom);
        }
        else
        {
            searchParams.delete('updateDateFrom');
        }
        if (this.state.inputUpdateDateTo)
        {
            const inputUpdateDateTo = format(this.state.inputUpdateDateTo, 'dd/MM/yyyy')
            searchParams.set('updateDateTo', inputUpdateDateTo);
        }
        else
        {
            searchParams.delete('updateDateTo');
        }
        this.state.selectedRulesFilter.length ?
            searchParams.set('rules', JSON.stringify(this.state.selectedRulesFilter)) :
            searchParams.delete('rules');
        this.state.inputOnlyInactive === true ?
            searchParams.set('onlyInactive', "true") :
            searchParams.delete('onlyInactive');
        this.props.setSearchParams(searchParams);
    }

    handleChangeCurrentPage (currentPage)
    {
        this.updateSearchParam('firstResult', currentPage);
        this.setState({ firstResult: currentPage })
    }

    componentDidMount ()
    {
        this.getExternalSystems();
        this.getRulesList();
        this.loadSnackbar();
    }

    componentDidUpdate (prevProps)
    {
        if (prevProps && (prevProps.searchParams !== this.props.searchParams))
        {
            this.getExternalSystems();
        }
    }

    handleCopy (e, text)
    {
        copy(text);
    }


    getExternalSystems ()
    {
        this.setState({
            isLoadingExternalSystem: true,
        })
        const searchParams = Object.fromEntries([...this.props.searchParams]);
        const rules = this.state.selectedRulesFilter.map((rule) => ({ ruleId: rule.value }));
        const username = localStorage.getItem('username');
        http.post(API_EXTERNAL_SYSTEM_SEARCH, {
            firstResult: searchParams.firstResult ?? this.state.firstResult,
            maxResults: searchParams.maxResults ?? this.props.maxResults,
            name: searchParams.name ?? '',
            updatedBy: searchParams.updatedBy ?? '',
            updateDateFrom: searchParams.updateDateFrom ?? '',
            updateDateTo: searchParams.updateDateTo ?? '',
            onlyInactive: searchParams.onlyInactive,
            rules: rules,
            username: username,
        })
            .then((response) =>
            {
                this.setState({
                    systemList: response.data.data.list,
                    totalResults: response.data.data.total,
                    revealAPIKey: new Array(response.data.data?.list.length ?? 0).fill(false),
                    showTooltip: new Array(response.data.data?.list.length ?? 0).fill(false),
                })
            })
            .catch((error) =>
            {
                console.log(error);
                this.setState({
                    errMessages: error.response ? error.response.data?.messages ?? [{ code: "", message: "Error desconocido" }] : [{ code: "", message: "Error de red" }],
                })
                window.scrollTo({ top: 0, behavior: 'smooth' });
            })
            .finally(() =>
            {
                this.setState({
                    isLoadingExternalSystem: false,
                })
            });
    }

    getRules (externalSystem)
    {
        if (!externalSystem) return;
        this.setState({
            isLoadingExternalSystemRules: true,
            rulesSelectedExternalSystemsName: externalSystem.name,
            rulesList: [],
        })
        const username = localStorage.getItem('username');
        http.post(API_EXTERNAL_SYSTEM_RULES, {
            systemId: externalSystem.systemId,
            username: username,
        })
            .then((response) =>
            {
                this.setState({
                    rulesList: response.data.data.rules,
                })
            })
            .catch((error) =>
            {
                console.log(error);
                this.setState({
                    errMessages: error.response ? error.response.data?.messages ?? [{ code: "", message: "Error desconocido" }] : [{ code: "", message: "Error de red" }],
                })
                window.scrollTo({ top: 0, behavior: 'smooth' });
            })
            .finally(() =>
            {
                this.setState({
                    isLoadingExternalSystemRules: false,
                })
            });
    }

    getRulesList ()
    {
        this.setState({
            isLoadingRelatedRulesFilter: true,
        })
        const username = localStorage.getItem('username');
        http.post(API_RULE_LIST, {
            username: username,
        })
            .then((response) =>
            {
                const rules = response.data.data.list.map((rule) => ({ label: rule.name, value: rule.ruleId }));
                this.setState({
                    options: rules,
                })
            })
            .catch((error) =>
            {
                console.log(error);
                this.setState({
                    errMessages: error.response ? error.response.data?.messages ?? [{ code: "", message: "Error desconocido" }] : [{ code: "", message: "Error de red" }],
                })
                window.scrollTo({ top: 0, behavior: 'smooth' });
            })
            .finally(() =>
            {
                this.setState({
                    isLoadingRelatedRulesFilter: false,
                })
            });
    }

    loadSnackbar ()
    {
        const snackbar = localStorage.getItem("snackbar");
        if (snackbar)
        {
            const msg = localStorage.getItem("msg");
            this.setState({
                showSnackbar: true,
                snackbarMsg: msg,
            })
            window.setTimeout(() =>
            {
                this.setState({
                    showSnackbar: false,
                })
                localStorage.removeItem("snackbar");
                localStorage.removeItem("msg")
            }, 5000)
        }
    }

    handleCreateExternalSystem ()
    {
        sessionStorage.removeItem("newEditRuleFields");
        this.props.navigate(`${ROUTE_SYSTEM_MODULE}${ROUTE_REL_SYSTEM_CREATE}`);
    }

    render ()
    {
        const searchParams = Object.fromEntries([...this.props.searchParams]);
        const updateSearchParam = this.updateSearchParam.bind(this);
        const handleInputChange = this.handleInputChange.bind(this);
        const handleChangeCurrentPage = this.handleChangeCurrentPage.bind(this);
        const handleCreateExternalSystem = this.handleCreateExternalSystem.bind(this);
        const RangeCustomInput = forwardRef(({ value, onClick }, ref) => (
            <div ref={ref} className="input-group input-group-with-icon">
                <input className="form-control" type="search" placeholder="Rango de fechas de última modificación" value={value} readOnly onClick={onClick} />
                <i className="bi bi-calendar-range"></i>
            </div>
        ));

        const allowedRoles = [Roles.ROLE_ADMIN, Roles.ROLE_MASTER];

        let roles = []
        try
        {
            roles = JSON.parse(localStorage.getItem('roles'));
        }
        catch {
            console.log("No se han definido roles para este usuario")
        }

        return (
            <>
                <Layout active={ROUTE_SYSTEM_MODULE}>
                    {
                        (this.state.isLoadingExternalSystem || this.state.isLoadingRelatedRulesFilter) ?
                            <>
                                <Loading />
                            </> :
                            <>
                                <Snackbar showSnackbar={this.state.showSnackbar} snackbarMsg={this.state.snackbarMsg} />
                                <div className='animate__animated animate__fadeInDown animate__faster'>
                                    <div className='row my-3'>
                                        <div className='col'>
                                            <div className='d-flex justify-content-between'>
                                                <h4 className='mb-0'>Sistemas externos</h4>
                                                {
                                                    allowedRoles.includes(Roles.ALL) || roles.some(userRole => allowedRoles.includes(userRole.roleKey)) ?
                                                        <>
                                                            <button type='button' onClick={handleCreateExternalSystem} className='btn btn-primary'>
                                                                <i className="bi bi-plus-lg me-2 align-middle"></i>
                                                                <span className='align-middle'>Crear sistema externo</span>
                                                            </button>
                                                        </> : <></>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mb-3'>
                                        <div className='col'>
                                            <div className="card search-filters-container">
                                                <div className="card-body">
                                                    {
                                                        (this.state.errMessages && this.state.errMessages.length) ?
                                                            <>
                                                                <div className='row mb-3'>
                                                                    <div className='col'>
                                                                        <div className="alert alert-danger mb-0" role="alert">
                                                                            {
                                                                                this.state.errMessages.map((err, index) =>
                                                                                {
                                                                                    return (
                                                                                        <Fragment key={index}>
                                                                                            <p className='mb-0'> {err.code?.length ? `[${err.code}]` : ""} {err.message}</p>
                                                                                        </Fragment>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </> : <></>
                                                    }
                                                    <div className='row'>
                                                        <div className='col'>
                                                            <h6 className='subtitle-1'>Filtros de búsqueda:</h6>
                                                        </div>
                                                    </div>
                                                    <div className='row row-cols-1 row-cols-sm-2 row-cols-md-4 g-2 mb-3'>
                                                        <div className='col'>
                                                            <div className="input-group input-group-with-icon">
                                                                <input onKeyDown={this.handleKeyPress} className="form-control" type="search" placeholder="Nombre de sistema" value={this.state.inputName} onChange={(e) => handleInputChange('inputName', e.target.value)} />
                                                                <i className="bi bi-search"></i>
                                                            </div>
                                                        </div>
                                                        <div className='col'>
                                                            <div className="input-group input-group-with-icon">
                                                                <input onKeyDown={this.handleKeyPress} className="form-control" type="search" placeholder="Usuario que realizó la última modificación" value={this.state.inputUpdatedBy} onChange={(e) => handleInputChange('inputUpdatedBy', e.target.value)} />
                                                                <i className="bi bi-search"></i>
                                                            </div>
                                                        </div>
                                                        <div className='col'>
                                                            <ReactDatePicker
                                                                placeholderText="Rango de fechas de última modificación"
                                                                selectsRange={true}
                                                                startDate={this.state.inputUpdateDateFrom}
                                                                endDate={this.state.inputUpdateDateTo}
                                                                dateFormat="dd/MM/yyyy"
                                                                locale={es}
                                                                onChange={(update) =>
                                                                {
                                                                    const [startDate, endDate] = update;
                                                                    this.setState({
                                                                        inputUpdateDateFrom: startDate,
                                                                        inputUpdateDateTo: endDate,
                                                                    })
                                                                }}
                                                                customInput={
                                                                    <RangeCustomInput />
                                                                }
                                                                isClearable
                                                            >
                                                            </ReactDatePicker>
                                                            <div id="lastModificationRange" className="form-text">
                                                                <small>DD/MM/AAAA-DD/MM/AAAA</small>
                                                            </div>
                                                        </div>
                                                        <div className='col'>
                                                            <MultiSelect
                                                                options={this.state.options}
                                                                value={this.state.selectedRulesFilter}
                                                                onChange={(value) =>
                                                                {
                                                                    this.setState({ selectedRulesFilter: value })
                                                                }}
                                                                labelledBy="Select"
                                                                className='ul-multi-select'
                                                                overrideStrings={{
                                                                    allItemsAreSelected: "Todos",
                                                                    clearSearch: "Limpiar",
                                                                    clearSelected: "Limpiar seleccionados",
                                                                    noOptions: "Sin opciones",
                                                                    search: "Buscar",
                                                                    selectAll: "Seleccionar todo",
                                                                    selectAllFiltered: "Seleccionar todo (filtrado)",
                                                                    selectSomeItems: "Reglas relacionadas",
                                                                    create: "Crear",
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='row row-cols-1 row-cols-md-2'>
                                                        <div className='col'>
                                                            <div className="form-check align-self-center">
                                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckShowOnlyInactive" checked={this.state.inputOnlyInactive} onChange={(e) => handleInputChange('inputOnlyInactive', e.target.checked)} />
                                                                <label className="form-check-label" htmlFor="flexCheckShowOnlyInactive">
                                                                    Mostrar solo inactivos
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className='col'>
                                                            <div className='d-sm-block d-md-flex justify-content-end'>
                                                                <div>
                                                                    <button className='btn btn-outline-primary me-2' onClick={this.clearFilters.bind(this)}>Limpiar filtros</button>
                                                                    <button className='btn btn-primary' onClick={this.handleSearch.bind(this)}>Buscar</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        this.state.totalResults === 0 ?
                                            <>
                                                <p className='text-center py-5'>No hay resultados disponibles</p>
                                            </> :
                                            <>
                                                <div className='row mb-3'>
                                                    <div className='col'>
                                                        <div className='d-flex justify-content-between'>
                                                            <h5>Resultados ({this.state.totalResults}):</h5>
                                                            <div>
                                                                <div className="row g-3 align-items-center">
                                                                    <div className="col-auto">
                                                                        <label htmlFor="itemsPerPageTop">Resultados por página:</label>
                                                                    </div>
                                                                    <div className="col-auto">
                                                                        <select
                                                                            id="itemsPerPageTop"
                                                                            className="form-select form-select-sm"
                                                                            defaultValue={searchParams.maxResults ?? this.props.maxResults}
                                                                            onChange={(e) => updateSearchParam('maxResults', e.target.value)}
                                                                        >
                                                                            <option value={5}>5</option>
                                                                            <option value={10}>10</option>
                                                                            <option value={20}>20</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row px-3'>
                                                    <div className='col'>
                                                        <p className='subtitle-1'>Nombre de sistema</p>
                                                    </div>
                                                    <div className='col'>
                                                        <p className='subtitle-1'>Última modificación</p>
                                                    </div>
                                                    <div className='col'>
                                                        <p className='subtitle-1'>Access Key</p>
                                                    </div>
                                                    <div className='col'>
                                                        <p className='subtitle-1'>Secret Key</p>
                                                    </div>
                                                    <div className='col'>
                                                        <p className='subtitle-1 text-center'>Sistemas externos</p>
                                                    </div>
                                                    <div className='col'>
                                                        <p className='subtitle-1 text-center'>Acciones</p>
                                                    </div>
                                                </div>
                                                <div className='row g-2'>
                                                    {
                                                        this.state.systemList && this.state.systemList.map((system, index) =>
                                                        {
                                                            return (
                                                                <Fragment key={index}>
                                                                    <div className='col-12'>
                                                                        <div className='card system-container'>
                                                                            <div className='card-body'>
                                                                                <div className='row'>
                                                                                    <div className='col align-self-center'>
                                                                                        <p className='mb-0'>{system.name}</p>
                                                                                    </div>
                                                                                    <div className='col align-self-center'>
                                                                                        <p className='mb-0'><i className="bi bi-calendar-event me-2"></i>{system.updateDate}</p>
                                                                                        <p className='mb-0'><i className="bi bi-person me-2"></i>{system.updatedBy}</p>
                                                                                    </div>
                                                                                    <div className='col align-self-center text-truncate'>
                                                                                        {system.username}
                                                                                    </div>
                                                                                    <div className='col align-self-center text-truncate'>
                                                                                        <div className='d-flex justify-content-between'>
                                                                                            <p className='mb-0 align-self-center text-truncate'>{this.state.revealAPIKey[index] ? system.apiKey : '********************************************************'}</p>
                                                                                            <div>
                                                                                                <button className='btn btn-link p-0 me-2' onClick={() =>
                                                                                                {
                                                                                                    const revealAPIKey = [...this.state.revealAPIKey];
                                                                                                    revealAPIKey[index] = !revealAPIKey[index];
                                                                                                    this.setState({
                                                                                                        revealAPIKey: revealAPIKey,
                                                                                                    })
                                                                                                }}>
                                                                                                    <i className={`bi bi-eye${this.state.revealAPIKey[index] ? "-fill" : ""}`}></i>
                                                                                                </button>
                                                                                                <button className='btn btn-link p-0 me-2' onClick={(e) =>
                                                                                                {
                                                                                                    this.handleCopy(e, system.apiKey);
                                                                                                    const showTooltip = [...this.state.showTooltip];
                                                                                                    showTooltip[index] = true;
                                                                                                    this.setState({
                                                                                                        showTooltip: showTooltip,
                                                                                                    })
                                                                                                    window.setTimeout(() =>
                                                                                                    {
                                                                                                        const showTooltip = [...this.state.showTooltip];
                                                                                                        showTooltip[index] = false;
                                                                                                        this.setState({
                                                                                                            showTooltip: showTooltip,
                                                                                                        })
                                                                                                    }, 2000)
                                                                                                }}>
                                                                                                    <i className="bi bi-clipboard"></i>
                                                                                                </button>
                                                                                                {this.state.showTooltip[index] && <span className="badge text-bg-success">Copiado</span>}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col align-self-center'>
                                                                                        <div className='d-flex justify-content-center align-items-center'>
                                                                                            <button className='btn btn-link text-center' href='#' data-bs-toggle="modal" data-bs-target="#rulesModal" onClick={() => this.getRules(system)}>Ver lista</button>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col align-self-center d-flex justify-content-center'>
                                                                                        <div className="dropdown">
                                                                                            <button className="btn btn-link dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                                                Opciones
                                                                                            </button>
                                                                                            <ul className="dropdown-menu">
                                                                                                <li><Link to={`${ROUTE_SYSTEM_MODULE}/${system.systemId}${ROUTE_REL_SYSTEM_VIEW}`} className="dropdown-item" href="#"><i className="bi bi-eye me-2"></i>Ver detalle</Link></li>
                                                                                                <li><Link to={`${ROUTE_SYSTEM_MODULE}/${system.systemId}${ROUTE_REL_SYSTEM_EDIT}`} className="dropdown-item" href="#"><i className="bi bi-pencil me-2"></i>Editar</Link></li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Fragment>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                <div className='row mt-3'>
                                                    <div className='col'>
                                                        <div className='d-flex justify-content-between'>
                                                            <Pagination handleChangeCurrentPage={(page) => handleChangeCurrentPage(page)} activePage={this.state.firstResult} itemsPerPage={searchParams.maxResults ?? this.props.maxResults} totalItems={this.state.totalResults} />
                                                            <div>
                                                                <div className="row g-3 align-items-center">
                                                                    <div className="col-auto">
                                                                        <label htmlFor="itemsPerPageTop">Resultados por página:</label>
                                                                    </div>
                                                                    <div className="col-auto">
                                                                        <select
                                                                            id="itemsPerPageTop"
                                                                            className="form-select form-select-sm"
                                                                            defaultValue={searchParams.maxResults ?? this.props.maxResults}
                                                                            onChange={(e) => updateSearchParam('maxResults', e.target.value)}
                                                                        >
                                                                            <option value={5}>5</option>
                                                                            <option value={10}>10</option>
                                                                            <option value={20}>20</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                    }
                                </div>
                            </>
                    }

                    <div onKeyDown={this.handleKeyPressRuleList} className="modal fade" id="rulesModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    {
                                        !this.state.isLoadingExternalSystemRules ?
                                            <>
                                                <h5 className="modal-title fs-5">Reglas relacionadas a "{this.state.rulesSelectedExternalSystemsName}"</h5>
                                            </> : <></>
                                    }
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    {
                                        !this.state.isLoadingExternalSystemRules ?
                                            <>
                                                <p>Estas son las reglas relacionadas al sistema externo "{this.state.rulesSelectedExternalSystemsName}":</p>
                                                {
                                                    !this.state.rulesList?.length ?
                                                        <>
                                                            <p className='mb-0'>No se encontraron reglas relacionadas.</p>
                                                        </> : <>
                                                            <ul className='mb-0'>
                                                                {
                                                                    this.state.rulesList.map((rule, index) =>
                                                                    {
                                                                        return (
                                                                            <Fragment key={index}>
                                                                                <li>{rule.name}</li>
                                                                            </Fragment>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                        </>
                                                }
                                            </> :
                                            <></>
                                    }
                                </div>
                                <div className="modal-footer">
                                    <button ref={this.refRulesUnderstoodBtn} type="button" className="btn btn-primary" data-bs-dismiss="modal">Entendido</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Layout >
            </>
        );
    }
}

export default ExternalSystemList;