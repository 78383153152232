import React, { Fragment } from "react";
import { Link } from "react-router-dom";
export default class Breadcrumb extends React.Component
{
    static defaultProps = {
        data: [],
    }
    render ()
    {
        return (
            <>
                <div className="row mt-3">
                    <div className="col">
                        <nav aria-label="breadcrumb">
                            <ol className="caption breadcrumb mb-0">
                                {
                                    this.props.data.map((item, index) =>
                                    {
                                        return (
                                            <Fragment key={index}>
                                                {
                                                    item.isActive ?
                                                        <>
                                                            <li className="breadcrumb-item active" aria-current={"page"}>{item.label}</li>
                                                        </> :
                                                        <>
                                                            <li className="breadcrumb-item"><Link to={item.to}>{item.label}</Link></li>
                                                        </>
                                                }
                                            </Fragment>
                                        )
                                    })
                                }
                            </ol>
                        </nav>
                    </div>
                </div>
            </>
        )
    }
}