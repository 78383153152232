const overrideStrings = {
    allItemsAreSelected: "Todos",
    clearSearch: "Limpiar",
    clearSelected: "Limpiar seleccionados",
    noOptions: "Sin opciones",
    search: "Buscar",
    selectAll: "Seleccionar todo",
    selectAllFiltered: "Seleccionar todo (filtrado)",
    selectSomeItems: "Sistemas externos",
    create: "Crear",
}
export default overrideStrings