import React, { Component, Fragment } from 'react';
import './UserList.css';
import Layout from '../../../components/Layout';
import Pagination from '../../../components/Pagination';
import http from '../../../api/HTTPService';
import { Link } from 'react-router-dom';
import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import copy from 'clipboard-copy';
import { MultiSelect } from 'react-multi-select-component';
import Loading from '../../../components/Loading';
import Snackbar from '../../../components/Snackbar';
import { API_EXTERNAL_SYSTEM_LIST, API_USER_ROLES, API_USER_SEARCH, API_USER_SYSTEMS } from '../../../constant/APIUrls';
import { ROUTE_REL_USER_CREATE, ROUTE_REL_USER_EDIT, ROUTE_REL_USER_VIEW, ROUTE_USER_MODULE } from '../../../constant/AppRoutes';

registerLocale("es", es);

class UserList extends Component
{
    constructor (props)
    {
        super(props);

        const searchParams = Object.fromEntries([...this.props.searchParams]);
        this.state = {
            totalResults: 0,
            inputFullName: searchParams.fullname ?? '',
            assignedESList: [],
            assignedESSelectedUserName: "",

            isLoadingAssignedES: false,

            ESOptions: [],
            roleOptions: [],
            selectedAssignedESFilter: searchParams.assignedes ? JSON.parse(searchParams.assignedes) : [],
            selectedRolesFilter: searchParams.roles ? JSON.parse(searchParams.roles) : [],

            showTooltip: [],

            isLoadingUsers: false,
            isLoadingESFilter: false,

            showSnackbar: false,
            snackbarMsg: "",

            firstResult: parseInt(searchParams.firstResult ?? 1),

            errMessages: []
        }
    }

    static defaultProps = {
        maxResults: 5,
    }

    clearFilters ()
    {
        this.props.setSearchParams('');
        this.setState({
            selectedAssignedESFilter: [],
            selectedRolesFilter: [],
            inputFullName: '',
        })
    }

    handleKeyPress = (event) =>
    {
        if (event.key === 'Enter')
        {
            this.handleSearch();
        }
    };

    updateSearchParam (key, value)
    {
        let searchParams = this.props.searchParams;
        searchParams.set(key, value);
        this.props.setSearchParams(searchParams);
    }

    handleInputChange (key, value)
    {
        this.setState({
            [key]: value,
        })
    }

    handleSearch ()
    {
        let searchParams = this.props.searchParams;
        this.state.inputFullName.length ?
            searchParams.set('fullname', this.state.inputFullName) :
            searchParams.delete('fullname');
        this.state.selectedAssignedESFilter.length ?
            searchParams.set('assignedes', JSON.stringify(this.state.selectedAssignedESFilter)) :
            searchParams.delete('assignedes');
        this.state.selectedRolesFilter.length ?
            searchParams.set('roles', JSON.stringify(this.state.selectedRolesFilter)) :
            searchParams.delete('roles');
        this.props.setSearchParams(searchParams);
    }

    handleChangeCurrentPage (currentPage)
    {
        this.updateSearchParam('firstResult', currentPage);
        this.setState({ firstResult: currentPage })
    }

    componentDidMount ()
    {
        this.getUsers();
        this.getRoleList();
        this.getESList();
        this.loadSnackbar();
    }

    componentDidUpdate (prevProps)
    {
        if (prevProps && (prevProps.searchParams !== this.props.searchParams))
        {
            this.getUsers();
        }
    }

    handleCopy (e, text)
    {
        copy(text);
    }


    getUsers ()
    {
        this.setState({
            isLoadingUsers: true,
        })
        const searchParams = Object.fromEntries([...this.props.searchParams]);
        const systems = this.state.selectedAssignedESFilter.map((extsys) => ({ systemId: extsys.value }));
        const roles = this.state.selectedRolesFilter.map((role) => ({ roleId: role.value }));
        http.post(API_USER_SEARCH, {
            firstResult: searchParams.firstResult ?? this.state.firstResult,
            maxResults: searchParams.maxResults ?? this.props.maxResults,
            name: searchParams.fullname ?? '',
            systems: systems,
            roles: roles,
        })
            .then((response) =>
            {
                this.setState({
                    userList: response.data.data.list,
                    totalResults: response.data.data.total,
                    showTooltip: new Array(response.data.data?.list.length ?? 0).fill(false),
                })
            })
            .catch((error) =>
            {
                console.log(error);
                this.setState({
                    errMessages: error.response ? error.response.data?.messages ?? [{ code: "", message: "Error desconocido" }] : [{ code: "", message: "Error de red" }],
                })
                window.scrollTo({ top: 0, behavior: 'smooth' });
            })
            .finally(() =>
            {
                this.setState({
                    isLoadingUsers: false,
                })
            });
    }

    getAssignedSystem (user)
    {
        if (!user) return;
        this.setState({
            isLoadingAssignedES: true,
            assignedESSelectedUserName: user.fullName,
            assignedESList: [],
        })
        http.get(`${API_USER_SYSTEMS}?userId=${user.userId}`)
            .then((response) =>
            {
                this.setState({
                    assignedESList: response.data.data.list,
                })
            })
            .catch((error) =>
            {
                console.log(error);
                this.setState({
                    errMessages: error.response ? error.response.data?.messages ?? [{ code: "", message: "Error desconocido" }] : [{ code: "", message: "Error de red" }],
                })
                window.scrollTo({ top: 0, behavior: 'smooth' });
            })
            .finally(() =>
            {
                this.setState({
                    isLoadingAssignedES: false,
                })
            });
    }

    getRoleList ()
    {
        this.setState({
            isLoadingRolesFilter: true,
        })
        http.get(API_USER_ROLES)
            .then((response) =>
            {
                const roles = response.data.data.list.map((role) => ({ label: role.name, value: role.roleId }));
                this.setState({
                    roleOptions: roles,
                })
            })
            .catch((error) =>
            {
                console.log(error);
                this.setState({
                    errMessages: error.response ? error.response.data?.messages ?? [{ code: "", message: "Error desconocido" }] : [{ code: "", message: "Error de red" }],
                })
                window.scrollTo({ top: 0, behavior: 'smooth' });
            })
            .finally(() =>
            {
                this.setState({
                    isLoadingRolesFilter: false,
                })
            });
    }
    getESList ()
    {
        this.setState({
            isLoadingESFilter: true,
        })
        http.get(API_EXTERNAL_SYSTEM_LIST)
            .then((response) =>
            {
                const esystems = response.data.data.list.map((extsys) => ({ label: extsys.name, value: extsys.systemId }));
                this.setState({
                    ESOptions: esystems,
                })
            })
            .catch((error) =>
            {
                console.log(error);
                this.setState({
                    errMessages: error.response ? error.response.data?.messages ?? [{ code: "", message: "Error desconocido" }] : [{ code: "", message: "Error de red" }],
                })
                window.scrollTo({ top: 0, behavior: 'smooth' });
            })
            .finally(() =>
            {
                this.setState({
                    isLoadingESFilter: false,
                })
            });
    }

    loadSnackbar ()
    {
        const snackbar = localStorage.getItem("snackbar");
        if (snackbar)
        {
            const msg = localStorage.getItem("msg");
            this.setState({
                showSnackbar: true,
                snackbarMsg: msg,
            })
            window.setTimeout(() =>
            {
                this.setState({
                    showSnackbar: false,
                })
                localStorage.removeItem("snackbar");
                localStorage.removeItem("msg")
            }, 5000)
        }
    }

    render ()
    {
        const searchParams = Object.fromEntries([...this.props.searchParams]);
        const updateSearchParam = this.updateSearchParam.bind(this);
        const handleInputChange = this.handleInputChange.bind(this);
        const handleChangeCurrentPage = this.handleChangeCurrentPage.bind(this);

        return (
            <>
                <Layout active={ROUTE_USER_MODULE}>
                    {
                        (this.state.isLoadingUsers || this.state.isLoadingESFilter) ?
                            <>
                                <Loading />
                            </> :
                            <>
                                <Snackbar showSnackbar={this.state.showSnackbar} snackbarMsg={this.state.snackbarMsg} />
                                <div className='animate__animated animate__fadeInDown animate__faster'>
                                    <div className='row my-3'>
                                        <div className='col'>
                                            <div className='d-flex justify-content-between'>
                                                <h4 className='mb-0'>Gestión de usuarios</h4>
                                                <Link to={`${ROUTE_USER_MODULE}${ROUTE_REL_USER_CREATE}`} className='btn btn-primary'>
                                                    <i className="bi bi-plus-lg me-2 align-middle"></i>
                                                    <span className='align-middle'>Crear usuario</span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mb-3'>
                                        <div className='col'>
                                            <div className="card search-filters-container">
                                                <div className="card-body">
                                                    {
                                                        (this.state.errMessages && this.state.errMessages.length) ?
                                                            <>
                                                                <div className='row mb-3'>
                                                                    <div className='col'>
                                                                        <div className="alert alert-danger mb-0" role="alert">
                                                                            {
                                                                                this.state.errMessages.map((err, index) =>
                                                                                {
                                                                                    return (
                                                                                        <Fragment key={index}>
                                                                                            <p className='mb-0'> {err.code?.length ? `[${err.code}]` : ""} {err.message}</p>
                                                                                        </Fragment>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </> : <></>
                                                    }
                                                    <div className='row'>
                                                        <div className='col'>
                                                            <h6 className='subtitle-1'>Filtros de búsqueda:</h6>
                                                        </div>
                                                    </div>
                                                    <div className='row row-cols-1 row-cols-sm-2 row-cols-md-3 g-2 mb-3'>
                                                        <div className='col'>
                                                            <div className="input-group input-group-with-icon">
                                                                <input onKeyDown={this.handleKeyPress} className="form-control" type="search" placeholder="Nombres completos o username" value={this.state.inputFullName} onChange={(e) => handleInputChange('inputFullName', e.target.value)} />
                                                                <i className="bi bi-search"></i>
                                                            </div>
                                                        </div>
                                                        <div className='col'>
                                                            <MultiSelect
                                                                options={this.state.roleOptions}
                                                                value={this.state.selectedRolesFilter}
                                                                onChange={(value) =>
                                                                {
                                                                    this.setState({ selectedRolesFilter: value })
                                                                }}
                                                                labelledBy="Select"
                                                                className='ul-multi-select'
                                                                overrideStrings={{
                                                                    allItemsAreSelected: "Todos",
                                                                    clearSearch: "Limpiar",
                                                                    clearSelected: "Limpiar seleccionados",
                                                                    noOptions: "Sin opciones",
                                                                    search: "Buscar",
                                                                    selectAll: "Seleccionar todo",
                                                                    selectAllFiltered: "Seleccionar todo (filtrado)",
                                                                    selectSomeItems: "Roles",
                                                                    create: "Crear",
                                                                }}
                                                            />
                                                        </div>
                                                        <div className='col'>
                                                            <MultiSelect
                                                                options={this.state.ESOptions}
                                                                value={this.state.selectedAssignedESFilter}
                                                                onChange={(value) =>
                                                                {
                                                                    this.setState({ selectedAssignedESFilter: value })
                                                                }}
                                                                labelledBy="Select"
                                                                className='ul-multi-select'
                                                                overrideStrings={{
                                                                    allItemsAreSelected: "Todos",
                                                                    clearSearch: "Limpiar",
                                                                    clearSelected: "Limpiar seleccionados",
                                                                    noOptions: "Sin opciones",
                                                                    search: "Buscar",
                                                                    selectAll: "Seleccionar todo",
                                                                    selectAllFiltered: "Seleccionar todo (filtrado)",
                                                                    selectSomeItems: "Sistemas externos asignados",
                                                                    create: "Crear",
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col'>
                                                            <div className='d-sm-block d-md-flex justify-content-end'>
                                                                <div>
                                                                    <button className='btn btn-outline-primary me-2' onClick={this.clearFilters.bind(this)}>Limpiar filtros</button>
                                                                    <button className='btn btn-primary' onClick={this.handleSearch.bind(this)}>Buscar</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mb-3'>
                                        <div className='col'>
                                            <div className='d-flex justify-content-between'>
                                                <h5>Resultados ({this.state.totalResults}):</h5>
                                                <div>
                                                    <div className="row g-3 align-items-center">
                                                        <div className="col-auto">
                                                            <label htmlFor="itemsPerPageTop">Resultados por página:</label>
                                                        </div>
                                                        <div className="col-auto">
                                                            <select
                                                                id="itemsPerPageTop"
                                                                className="form-select form-select-sm"
                                                                defaultValue={searchParams.maxResults ?? this.props.maxResults}
                                                                onChange={(e) => updateSearchParam('maxResults', e.target.value)}
                                                            >
                                                                <option value={5}>5</option>
                                                                <option value={10}>10</option>
                                                                <option value={20}>20</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row px-3'>
                                        <div className='col'>
                                            <p className='subtitle-1'>Nombres completos</p>
                                        </div>
                                        <div className='col'>
                                            <p className='subtitle-1'>Username</p>
                                        </div>
                                        <div className='col'>
                                            <p className='subtitle-1'>Rol</p>
                                        </div>
                                        <div className='col'>
                                            <p className='subtitle-1 text-center'>Sistemas asignados</p>
                                        </div>
                                        <div className='col'>
                                            <p className='subtitle-1 text-center'>Acciones</p>
                                        </div>
                                    </div>
                                    <div className='row g-2'>
                                        {
                                            this.state.userList && this.state.userList.map((user, index) =>
                                            {
                                                return (
                                                    <Fragment key={index}>
                                                        <div className='col-12'>
                                                            <div className='card user-container'>
                                                                <div className='card-body'>
                                                                    <div className='row'>
                                                                        <div className='col align-self-center'>
                                                                            <p className='mb-0'>{user.fullName}</p>
                                                                        </div>
                                                                        <div className='col align-self-center'>
                                                                            <p className='mb-0'>{user.username}</p>
                                                                        </div>
                                                                        <div className='col align-self-center'>
                                                                            <p className='mb-0'>{user.roles}</p>
                                                                        </div>
                                                                        <div className='col align-self-center'>
                                                                            <div className='d-flex justify-content-center align-items-center'>
                                                                                <button className='btn btn-link text-center' href='#' data-bs-toggle="modal" data-bs-target="#assignedESModal" onClick={() => this.getAssignedSystem(user)}>Ver sistemas</button>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col align-self-center d-flex justify-content-center'>
                                                                            <div className="dropdown">
                                                                                <button className="btn btn-link dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                                    Opciones
                                                                                </button>
                                                                                <ul className="dropdown-menu">
                                                                                    <li><Link to={`${ROUTE_USER_MODULE}/${user.userId}${ROUTE_REL_USER_VIEW}`} className="dropdown-item" href="#"><i className="bi bi-eye me-2"></i>Ver detalle</Link></li>
                                                                                    <li><Link to={`${ROUTE_USER_MODULE}/${user.userId}${ROUTE_REL_USER_EDIT}`} className="dropdown-item" href="#"><i className="bi bi-pencil me-2"></i>Editar</Link></li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Fragment>
                                                )
                                            })
                                        }
                                    </div>
                                    <div className='row mt-3'>
                                        <div className='col'>
                                            <div className='d-flex justify-content-between'>
                                                <Pagination handleChangeCurrentPage={(page) => handleChangeCurrentPage(page)} activePage={this.state.firstResult} itemsPerPage={searchParams.maxResults ?? this.props.maxResults} totalItems={this.state.totalResults} />
                                                <div>
                                                    <div className="row g-3 align-items-center">
                                                        <div className="col-auto">
                                                            <label htmlFor="itemsPerPageTop">Resultados por página:</label>
                                                        </div>
                                                        <div className="col-auto">
                                                            <select
                                                                id="itemsPerPageTop"
                                                                className="form-select form-select-sm"
                                                                defaultValue={searchParams.maxResults ?? this.props.maxResults}
                                                                onChange={(e) => updateSearchParam('maxResults', e.target.value)}
                                                            >
                                                                <option value={5}>5</option>
                                                                <option value={10}>10</option>
                                                                <option value={20}>20</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                    }

                    <div className="modal fade" id="assignedESModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    {
                                        !this.state.isLoadingAssignedES ?
                                            <>
                                                <h5 className="modal-title fs-5">Sistemas relacionados a "{this.state.assignedESSelectedUserName}"</h5>
                                            </> : <></>
                                    }
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    {
                                        !this.state.isLoadingAssignedES ?
                                            <>
                                                {
                                                    !this.state.assignedESList?.length ?
                                                        <>
                                                            <p className='mb-0'>No se encontraron sistemas externos relacionados.</p>
                                                        </> : <>
                                                            <ul className='mb-0'>
                                                                {
                                                                    this.state.assignedESList.map((es, index) =>
                                                                    {
                                                                        return (
                                                                            <Fragment key={index}>
                                                                                <li>{es.name}</li>
                                                                            </Fragment>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                        </>
                                                }
                                            </> :
                                            <></>
                                    }
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-primary" data-bs-dismiss="modal">Entendido</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Layout >
            </>
        );
    }
}

export default UserList;