import React, { Fragment } from "react";
import './VerificationResult.css';
import Layout from "../../../components/Layout/Layout";
import http from "../../../api/HTTPService";
import Breadcrumb from "../../../components/Breadcrumb";
import Loading from "../../../components/Loading";
import { API_IMAGE_GET, API_VERIFICATION_DETAILS } from "../../../constant/APIUrls";
import { ROUTE_REL_VERIFICATION_CREATE, ROUTE_VERIFICATION_MODULE } from "../../../constant/AppRoutes";

export default class VerificationResult extends React.Component
{
    constructor (props)
    {
        super(props);
        this.state = {
            data: [],
            isLoadingResult: false,
            photo: "",
        }
    }
    getResult ()
    {
        this.setState({
            isLoadingResult: true,
        })
        const resultId = this.props.params.resultId;
        const username = localStorage.getItem('username');
        http.get(`${API_VERIFICATION_DETAILS}?verificationId=${resultId}&username=${username}`)
            .then((response) =>
            {
                let data = response.data.data;
                const compareByOrderId = (a, b) =>
                {
                    return a.order - b.order;
                }

                // Ordenar el arreglo de objetos utilizando la función de comparación
                data.parameters.sort(compareByOrderId);
                this.setState({
                    data: data,
                    filename: '',
                })
                http.get(`${API_IMAGE_GET}?uuid=${data.uuid}`, {
                    responseType: 'arraybuffer'
                })
                    .then((response) =>
                    {
                        const imageContentType = 'image/jpeg';
                        const imageBinary = new Blob([response.data], { type: imageContentType });
                        const imageUrl = URL.createObjectURL(imageBinary);
                        this.setState({ photo: imageUrl, filename: response.headers.ulima_filename });
                    })
                    .catch(error => console.error('Error al obtener la imagen:', error));
            })
            .catch((err) =>
            {
                console.log(err);
            })
            .finally(() =>
            {
                this.setState({
                    isLoadingResult: false,
                })
            })
    }

    componentDidMount ()
    {
        this.getResult();
    }

    render ()
    {
        let breadcrumbData = [
            {
                label: "Histórico de verificaciones",
                to: ROUTE_VERIFICATION_MODULE,
                isActive: false,
            },
            {
                label: "Resultados de verificación",
                isActive: true,
            }
        ]
        const searchParams = Object.fromEntries([...this.props.searchParams]);
        if (searchParams.from)
        {
            if (searchParams.from === "creation")
            {
                breadcrumbData = [
                    {
                        label: "Histórico de verificaciones",
                        to: ROUTE_VERIFICATION_MODULE,
                        isActive: false,
                    },
                    {
                        label: "Nueva verificación",
                        to: `${ROUTE_VERIFICATION_MODULE}${ROUTE_REL_VERIFICATION_CREATE}`,
                        isActive: false,
                    },
                    {
                        label: "Resultados de verificación",
                        isActive: true,
                    }
                ]
            }
        }

        return (
            <>
                <Layout active={ROUTE_VERIFICATION_MODULE}>
                    {
                        (this.state.isLoadingResult) ?
                            <>
                                <Loading />
                            </> :
                            <>
                                <Breadcrumb data={breadcrumbData} />
                                <div className='row my-3'>
                                    <div className='col'>
                                        <h4 className='mb-0'>Resultados de verificación</h4>
                                    </div>
                                </div>
                                <div className='row mb-3'>
                                    <div className='col'>
                                        <div className="card search-filters-container">
                                            <div className="card-body">
                                                <div className="row mb-3">
                                                    <div className="col-12 col-md-4">
                                                        <h6 className="text-center">Imagen:</h6>
                                                        {
                                                            this.state.photo.length ?
                                                                <>
                                                                    <img className="img-fluid mx-auto d-flex rounded mb-3" alt="Fotografía cargada" src={this.state.photo} style={{ maxHeight: '18rem' }} />
                                                                    <a download={this.state.filename} target="_blank" rel="noreferrer" href={this.state.photo} className="caption d-block text-center my-3">{this.state.filename}</a>
                                                                </> : <>
                                                                    <Loading height='auto' />
                                                                </>
                                                        }
                                                    </div>
                                                    <div className="col-12 col-md-8">
                                                        <h6>Lista de parámetros:</h6>
                                                        {
                                                            this.state.data.parameters?.length ?
                                                                <>
                                                                    <div className="row mb-3">
                                                                        <div className="col">
                                                                            <h6 className="subtitle-2">Parámetro</h6>
                                                                        </div>
                                                                        <div className="col">
                                                                            <h6 className="subtitle-2">Validación</h6>
                                                                        </div>
                                                                        <div className="col">
                                                                            <h6 className="subtitle-2">Imagen</h6>
                                                                        </div>
                                                                        <div className="col">
                                                                            <h6 className="subtitle-2">Resultado</h6>
                                                                        </div>
                                                                    </div>
                                                                </> : <></>
                                                        }
                                                        {this.state.data && this.state.data.parameters && this.state.data.parameters.map((parameter, index) =>
                                                        {
                                                            return (
                                                                <Fragment key={index}>
                                                                    <div className="card search-filters-container mb-3">
                                                                        <div className="card-body">
                                                                            <div className="row">
                                                                                <div className="col align-self-center">
                                                                                    <p className="mb-0">
                                                                                        {parameter.parameterName}
                                                                                    </p>
                                                                                </div>
                                                                                <div className="col align-self-center">
                                                                                    <pre className="mb-0" style={{ fontFamily: 'inherit', fontSize: 'inherit' }}>
                                                                                        <p className="mb-0">
                                                                                            {parameter.imageValues}
                                                                                        </p>
                                                                                    </pre>
                                                                                </div>
                                                                                <div className="col align-self-center">
                                                                                    <pre className="mb-0" style={{ fontFamily: 'inherit', fontSize: 'inherit' }}>
                                                                                        <p className="mb-0">
                                                                                            {parameter.validationValues}
                                                                                        </p>
                                                                                    </pre>
                                                                                </div>
                                                                                <div className="col align-self-center">
                                                                                    <button type="button" className={`w-100 verification-${parameter.valid ? 'success' : 'error'}-pill`}>
                                                                                        {parameter.valid ? "Válido" : "Inválido"}
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                !parameter.valid ?
                                                                                    <>
                                                                                        <div className="row">
                                                                                            <div className="col">
                                                                                                <div className="alert alert-danger mb-0 mt-3 p-2">
                                                                                                    {parameter.errorMessage}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </> : <></>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </Fragment>
                                                            )
                                                        })}
                                                        {
                                                            !this.state.data.parameters?.length ?
                                                                <>
                                                                    <p>No se definieron parámetros para la regla.</p>
                                                                </> : <>
                                                                </>
                                                        }
                                                        {
                                                            this.state.data.biometrics?.length ?
                                                                <>
                                                                    <h6 className="mt-4">Parámetros de biometría:</h6>
                                                                    <div className="row mb-3">
                                                                        <div className="col">
                                                                            <h6 className="subtitle-2">Parámetro</h6>
                                                                        </div>
                                                                        <div className="col">
                                                                            <h6 className="subtitle-2">Validación</h6>
                                                                        </div>
                                                                        <div className="col">
                                                                            <h6 className="subtitle-2">Imagen</h6>
                                                                        </div>
                                                                        <div className="col">
                                                                            <h6 className="subtitle-2">Resultado</h6>
                                                                        </div>
                                                                    </div>
                                                                </> : <></>
                                                        }
                                                        {this.state.data && this.state.data.biometrics && this.state.data.biometrics.map((parameter, index) =>
                                                        {
                                                            return (
                                                                <Fragment key={index}>
                                                                    <div className="card search-filters-container mb-3">
                                                                        <div className="card-body">
                                                                            <div className="row">
                                                                                <div className="col align-self-center">
                                                                                    <p className="mb-0">
                                                                                        {parameter.parameterName}
                                                                                    </p>
                                                                                </div>
                                                                                <div className="col align-self-center">
                                                                                    <pre className="mb-0" style={{ fontFamily: 'inherit', fontSize: 'inherit' }}>
                                                                                        <p className="mb-0">
                                                                                            {parameter.imageValues}
                                                                                        </p>
                                                                                    </pre>
                                                                                </div>
                                                                                <div className="col align-self-center">
                                                                                    <pre className="mb-0" style={{ fontFamily: 'inherit', fontSize: 'inherit' }}>
                                                                                        <p className="mb-0">
                                                                                            {parameter.validationValues}
                                                                                        </p>
                                                                                    </pre>
                                                                                </div>
                                                                                <div className="col align-self-center">
                                                                                    <button type="button" className={`w-100 verification-${parameter.valid ? 'success' : 'error'}-pill`}>
                                                                                        {parameter.valid ? "Válido" : "Inválido"}
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                !parameter.valid ?
                                                                                    <>
                                                                                        <div className="row">
                                                                                            <div className="col">
                                                                                                <div className="alert alert-danger mb-0 mt-3 p-2">
                                                                                                    {parameter.errorMessage}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </> : <></>
                                                                            }

                                                                        </div>
                                                                    </div>
                                                                </Fragment>
                                                            )
                                                        })}
                                                        {
                                                            this.state.data.parameters?.length ?
                                                                <>
                                                                    <div className="row">
                                                                        <div className="col">
                                                                            <div className="d-flex justify-content-between">
                                                                                <h6 className="align-self-center">Estado:</h6>
                                                                                <button type="button" className={`verification-${this.state.data.valid ? 'success' : 'error'}-pill`}>
                                                                                    {this.state.data.result}
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </> : <>
                                                                </>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                    }
                </Layout>
            </>
        );
    }
}