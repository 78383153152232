import React, { Component, Fragment, createRef } from 'react';
import './RuleEdit.css';
import Layout from '../../../components/Layout';
import BaseRule from '../BaseRule';
import { ROUTE_HOME } from '../../../constant/AppRoutes';

class RuleEdit extends Component
{
    constructor (props)
    {
        super(props);
        this.refBaseRule = createRef();
    }
    preventNavigation ()
    {
        this.refBaseRule.current?.handleDiscardChanges();
    }

    render ()
    {
        const preventNavigation = this.preventNavigation.bind(this);
        return (
            <>
                <Layout active={ROUTE_HOME}>
                    <div className="row mt-3">
                        <div className="col">
                            <nav aria-label="breadcrumb">
                                <ol className="caption breadcrumb mb-0">
                                    <li onClick={preventNavigation} className="breadcrumb-item"><span style={{color: 'rgba(var(--ul-link-color-rgb), var(--ul-link-opacity, 1))', cursor: 'pointer'}}>Reglas</span></li>
                                    <li className="breadcrumb-item active" aria-current={"page"}>Editar regla</li>
                                    </ol>
                            </nav>
                        </div>
                    </div>
                    <div className='row my-3'>
                        <div className='col'>
                            <h4 className='mb-0'>Editar regla</h4>
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col'>
                            <div className="card search-filters-container">
                                <div className="card-body">
                                    <BaseRule ref={this.refBaseRule} navigate={this.props.navigate} ruleId={this.props.params.ruleId ?? 0} mode="edit" />
                                </div>
                            </div>
                        </div>
                    </div>
                </Layout>
            </>
        );
    }
}

export default RuleEdit;