import React, { Fragment, createRef } from "react";
import http from "../../api/HTTPService";
import { useLocation } from "react-router-dom";
import ULLogo from '../../content/images/ul-logo-colorful.svg';
import './login.css';
import InputPassword from "../../components/InputPassword";
import { API_USER_AUTHENTICATE } from "../../constant/APIUrls";

class Login extends React.Component
{
    constructor (props)
    {
        super(props);
        this.state = {
            errMessages: [],
            username: '',
            password: '',
            validationErrorUsername: false,
            validationErrorPassword: false,
        }
        this.refBtnLogin = createRef();
    }

    isValidForm (ruleIndex)
    {
        let valid = true;
        if (!this.state.username.length)
        {
            this.setState({
                validationErrorUsername: true,
            })
            valid = false;
        }
        if (!this.state.password.length)
        {
            this.setState({
                validationErrorPassword: true,
            })
            valid = false;
        }
        return valid;
    }

    submit (e)
    {
        if (!this.isValidForm()) return;
        this.setState({
            errMessages: [],
        })
        e.target.disabled = true;
        const loadingSpinner = e.target.querySelector('span');
        loadingSpinner.classList.remove('hidden');

        const username = this.state.username;
        const password = this.state.password;
        http.post(API_USER_AUTHENTICATE, {
            username: username,
            password: password,
        })
            .then((response) =>
            {
                localStorage.setItem('userId', response.data.data.userId);
                localStorage.setItem('username', response.data.data.username);
                localStorage.setItem('fullName', response.data.data.fullName);
                localStorage.setItem('roles', JSON.stringify(response.data.data.roles));

                const { location } = this.props;
                const searchParams = new URLSearchParams(location.search);
                const redirectUrl = searchParams.get('redirect');
                if (redirectUrl)
                    window.location.href = redirectUrl
                else
                    window.location.href = '/'
            })
            .catch((error) =>
            {
                this.setState({
                    errMessages: error.response ? error.response.data?.messages ?? [{ code: "", message: "Error desconocido" }] : [{ code: "", message: "Error de red" }],
                })
                window.scrollTo({ top: 0, behavior: 'smooth' });
            })
            .finally(() =>
            {
                loadingSpinner.classList.add('hidden');
                e.target.disabled = false;
            });
    }

    handleUsernameChange (e)
    {
        this.setState({
            username: e.target.value,
            validationErrorUsername: false,
        })
    }

    handlePasswordChange (e)
    {
        this.setState({
            password: e.target.value,
            validationErrorPassword: false,
        })
    }

    handleKeyPress = (event) =>
    {
        if (event.key === 'Enter')
        {
            this.refBtnLogin.current.click();
        }
    }
    componentDidMount ()
    {
        document.addEventListener('keydown', this.handleKeyPress, false);
    }

    componentWillUnmount ()
    {
        document.removeEventListener('keydown', this.handleKeyPress, false);
    }

    render ()
    {
        const submit = this.submit.bind(this);
        const handleUsernameChange = this.handleUsernameChange.bind(this);
        const handlePasswordChange = this.handlePasswordChange.bind(this);

        return (
            <>
                <div className="container">
                    <div className="row" style={{ height: '100vh' }}>
                        <div className="col-12 col-md-7 col-xl-4 mx-auto align-self-center">
                            <div className="card animate__animated animate__zoomIn animate__faster">
                                <div className="card-body">
                                    {
                                        (this.state.errMessages && this.state.errMessages.length) ? <div className="alert alert-danger" role="alert">
                                            {
                                                this.state.errMessages.map((err, index) =>
                                                {
                                                    return (
                                                        <Fragment key={index}>
                                                            <p className='mb-0'> {err.code?.length ? `[${err.code}]` : ""} {err.message}</p>
                                                        </Fragment>
                                                    )
                                                })
                                            }
                                        </div> : <></>
                                    }
                                    <form className="text-center">
                                        <img className="app-logo-login mb-3" src={ULLogo} alt="" />
                                        <h4 className="mb-3">Inicia sesión</h4>
                                        <div className="form-floating mb-3">
                                            <input type="email" className="form-control" id="floatingInput" placeholder="Nombre de usuario" autoComplete="username" value={this.state.username} onChange={handleUsernameChange} />
                                            <label htmlFor="floatingInput">Nombre de usuario</label>
                                            {
                                                this.state.validationErrorUsername ?
                                                    <>
                                                        <div className="d-block invalid-feedback">
                                                            Debe introducir un nombre de usuario
                                                        </div>
                                                    </> : <></>
                                            }
                                        </div>
                                        <div className="form-floating mb-3">
                                            <InputPassword id="floatingPassword" placeholder="Contraseña" name="password" value={this.state.password} onChange={handlePasswordChange} />
                                            <label htmlFor="floatingPassword">Contraseña</label>
                                            {
                                                this.state.validationErrorPassword ?
                                                    <>
                                                        <div className="d-block invalid-feedback">
                                                            Debe introducir una contraseña
                                                        </div>
                                                    </> : <></>
                                            }
                                        </div>
                                        <button ref={this.refBtnLogin} className="w-100 btn btn-lg btn-primary" type="button" onClick={(e) => submit(e)}>
                                            <span className="spinner-border spinner-border-sm me-2 hidden" role="status" aria-hidden="true"></span>
                                            Iniciar sesión</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const LoginWrapper = () =>
{
    const location = useLocation();
    return <Login location={location} />;
};

export default LoginWrapper;