import React, { Component, Fragment, createRef } from 'react';
import './BaseExternalSystem.css';
import http from '../../../api/HTTPService';
import Loading from '../../../components/Loading';
import { Roles } from '../../../constant/Enums';
import { API_EXTERNAL_SYSTEM_BASE, API_EXTERNAL_SYSTEM_DETAILS_REL, API_EXTERNAL_SYSTEM_FETCH_REL, API_EXTERNAL_SYSTEM_SAVE, API_RULE_LIST } from '../../../constant/APIUrls';
import { ROUTE_REL_RULE_VIEW, ROUTE_RULE_MODULE, ROUTE_SYSTEM_MODULE } from '../../../constant/AppRoutes';

class BaseExternalSystem extends Component
{
    constructor (props)
    {
        super(props);
        this.state = {
            name: '',
            active: true,
            rules: [],
            ruleOptions: [],

            isLoadingRules: false,
            isLoadingSystem: false,

            errMessages: [],

            deletedRule: { index: -1 },
        };
        this.refBtnCancel = createRef();
        this.refBtnSave = createRef();
        this.refBtnConfirmRuleDelete = createRef();
        this.refBtnCloseCancel = createRef();
        this.refBtnCloseSave = createRef();
        this.refBtnCloseConfirmRuleDelete = createRef();
        this.refForm = createRef();
        this.refBtnBack = createRef();
    }

    getRulesList ()
    {
        this.setState({
            isLoadingRules: true,
        })
        const username = localStorage.getItem('username');
        http.post(API_RULE_LIST, {
            username: username
        })
            .then((response) =>
            {
                this.setState({
                    ruleOptions: response.data.data?.list ?? [],
                });
            })
            .catch((error) =>
            {
                console.log(error);
            })
            .finally(() =>
            {
                this.setState({
                    isLoadingRules: false,
                })
            })
    }

    isValidForm ()
    {
        const rules = this.state.rules;
        let valid = true;
        let addedRules = [];
        let updatedRules = rules.map((rule) =>
        {
            const added = addedRules.some((added) => added === rule.ruleId);
            addedRules.push(rule.ruleId);
            added && (valid = false);
            return added ? { ...rule, valid: false } : { ...rule }
        })
        this.setState({ rules: updatedRules });
        return valid;
    }

    handleKeyPress = (event) =>
    {
        if (event.key === 'Enter')
        {
            if (this.props.mode === "view")
                this.refBtnBack.current.click();
        }
    }

    handleSaveChanges (e)
    {
        e.preventDefault();
        if (!this.isValidForm())
        {
            e.stopPropagation();
        }
        else
        {

            this.refBtnSave.current.click();
        }
    }

    componentDidMount ()
    {
        this.getRulesList();
        this.props.mode === "view" && sessionStorage.removeItem("newEditRuleFields");
        if (this.resumeSession()) return;
        this.props.mode !== "create" && this.handleFetchSystem();
        document.addEventListener('keydown', this.handleKeyPress, false);
    }

    componentWillUnmount ()
    {
        document.removeEventListener('keydown', this.handleKeyPress, false);
    }

    handleInputChange = (event) =>
    {
        const { name, value, type, checked } = event.target;
        const newValue = type === 'checkbox' ? checked : value;
        this.setState({ [name]: newValue });
    };

    handleRuleChange = (index, value) =>
    {
        const { rules } = this.state;
        const updatedRules = [...rules];
        updatedRules[index] = { ruleId: value };
        this.setState({ rules: updatedRules });
    };

    handleAddRule = () =>
    {
        this.setState((prevState) => ({
            rules: [...prevState.rules, { ruleId: this.state.ruleOptions.length > 0 ? this.state.ruleOptions[0].ruleId : null }]
        }));
    };

    handleRemoveRule = (index) =>
    {
        if (index < 0) return false;
        const { rules } = this.state;
        const updatedRules = [...rules];
        updatedRules.splice(index, 1);
        this.setState({ rules: updatedRules });
        return true;
    };

    handleFetchSystem = () =>
    {
        if (!this.props.systemId) return;
        this.setState({
            isLoadingSystem: true,
        })
        const username = localStorage.getItem('username');
        http.post(`${API_EXTERNAL_SYSTEM_BASE}/${this.props.mode === "view" ? API_EXTERNAL_SYSTEM_DETAILS_REL : API_EXTERNAL_SYSTEM_FETCH_REL}`, {
            systemId: this.props.systemId,
            username: username,
        })
            .then((response) =>
            {
                let data = response.data.data;
                if (this.props.mode === "copy")
                    data.name += ' (copia)';
                this.setState({
                    name: data.name,
                    active: data.active,
                    rules: data.rules,
                });
            })
            .catch((error) =>
            {
                console.log(error);
            })
            .finally(() =>
            {
                this.setState({
                    isLoadingSystem: false,
                })
            })
    };

    handleSaveSystem = (e) =>
    {
        if (!this.isValidForm()) return;
        const username = localStorage.getItem('username');
        const system = {
            systemId: this.props.systemId,
            name: this.state.name,
            username: username,
            active: this.state.active,
            rules: this.state.rules
        }
        if (this.props.mode === "copy" || this.props.mode === "create")
            delete system.systemId;
        e.target.disabled = true;
        const loadingSpinner = e.target.querySelector('span');
        loadingSpinner.classList.remove('hidden');
        this.setState({
            errMessages: [],
        })
        http.post(API_EXTERNAL_SYSTEM_SAVE, system)
            .then((response) =>
            {
                localStorage.setItem("snackbar", true);
                localStorage.setItem("msg", `El sistema externo ${system.name} ha sido ${this.props.mode === "create" ? "creado" : "actualizado"}.`)
                this.props.navigate(-1);
            })
            .catch((error) =>
            {
                this.setState({
                    errMessages: error.response ? error.response.data?.messages ?? [{ code: "", message: "Error desconocido" }] : [{ code: "", message: "Error de red" }],
                })
                window.scrollTo({ top: 0, behavior: 'smooth' });
            })
            .finally(() =>
            {
                loadingSpinner.classList.add('hidden');
                e.target.disabled = false;
            });
    }

    handleDiscardChanges ()
    {
        this.refBtnCancel.current.click();
    }

    handleRemoveRuleConfirm (index)
    {
        const selectedRule = this.state.rules[index];
        const selectedRuleOption = this.state.ruleOptions.find(o => o.ruleId === selectedRule.ruleId);
        selectedRuleOption.index = index;
        this.setState({
            deletedRule: selectedRuleOption,
        }, () =>
        {
            this.refBtnConfirmRuleDelete.current.click();
        })
    }

    storeSession ()
    {
        const system = {
            name: this.state.name,
            active: this.state.active,
            rules: this.state.rules
        }
        sessionStorage.setItem("newEditRuleFields", JSON.stringify(system));
    }

    resumeSession ()
    {
        const systemStr = sessionStorage.getItem("newEditRuleFields");
        if (systemStr)
        {
            const system = JSON.parse(systemStr);
            this.setState({
                name: system.name,
                active: system.active,
                rules: system.rules
            })
            sessionStorage.removeItem("newEditRuleFields");
            return true;
        }
        return false;
    }

    handleRuleDetails (rule)
    {
        let to = `${ROUTE_RULE_MODULE}/${rule.ruleId}${ROUTE_REL_RULE_VIEW}?from=systemCreate`;
        this.props.mode === "edit" && (to = `${ROUTE_RULE_MODULE}/${rule.ruleId}${ROUTE_REL_RULE_VIEW}?from=systemEdit&systemId=${this.props.systemId}`);
        this.props.mode === "view" && (to = `${ROUTE_RULE_MODULE}/${rule.ruleId}${ROUTE_REL_RULE_VIEW}?from=systemDetails&systemId=${this.props.systemId}`);
        this.props.navigate(to);
        this.props.mode !== "view" && this.storeSession();
    }

    render ()
    {
        const { name, active, rules, ruleOptions } = this.state;
        const handleRuleDetails = this.handleRuleDetails.bind(this);

        const allowedRoles = [Roles.ROLE_ADMIN, Roles.ROLE_MASTER];

        let roles = []
        try
        {
            roles = JSON.parse(localStorage.getItem('roles'));
        }
        catch {
            console.log("No se han definido roles para este usuario")
        }

        const username = localStorage.getItem('username');

        return (
            <>
                {
                    (this.state.isLoadingRules ||
                        this.state.isLoadingSystem) ?
                        <>
                            <Loading />
                        </> : <>
                            <form ref={this.refForm}>
                                <div className='external-system'>
                                    {
                                        this.props.mode === "view" ?
                                            <>
                                                <h6 className='subtitle-1 mb-3'>Datos del sistema externo:</h6>
                                            </> :
                                            <>
                                                <h6 className='subtitle-1 mb-3'>Complete los siguientes datos:</h6>
                                            </>
                                    }
                                    {
                                        (this.state.errMessages && this.state.errMessages.length) ? <div className="alert alert-danger" role="alert">
                                            {
                                                this.state.errMessages.map((err, index) =>
                                                {
                                                    return (
                                                        <Fragment key={index}>
                                                            <p className='mb-0'> {err.code?.length ? `[${err.code}]` : ""} {err.message}</p>
                                                        </Fragment>
                                                    )
                                                })
                                            }
                                        </div> : <></>
                                    }
                                    {
                                        this.props.mode === "view" ?
                                            <>
                                                <p><span className='subtitle-2'>Nombre del sistema externo: </span><span className='body-2'>{name}</span></p>
                                            </> :
                                            <>
                                                {
                                                    allowedRoles.includes(Roles.ALL) || roles.some(userRole => allowedRoles.includes(userRole.roleKey)) ?
                                                        <>
                                                            <div className="form-floating mb-3">
                                                                <input className='form-control' id="floatInputName" required type="text" name="name" placeholder='Nombre del sistema' value={name} onChange={this.handleInputChange} />
                                                                <label htmlFor='floatInputName'>Nombre del sistema</label>
                                                                <div className="invalid-feedback">
                                                                    Este campo es obligatorio
                                                                </div>
                                                            </div>
                                                        </> :
                                                        <>
                                                            <p><span className='subtitle-2'>Nombre del sistema: </span><span className='body-2'>{name}</span></p>
                                                        </>
                                                }
                                            </>
                                    }
                                    {
                                        this.props.mode === "view" ?
                                            <>
                                                <p><span className='subtitle-2'>Estado: </span><span className='body-2'>{active ? "Activo" : "Inactivo"}</span></p>
                                            </> : <></>
                                    }
                                    {
                                        this.props.mode === "edit" ?
                                            <>
                                                {
                                                    allowedRoles.includes(Roles.ALL) || roles.some(userRole => allowedRoles.includes(userRole.roleKey)) ?
                                                        <>
                                                            <div className="form-check mb-4">
                                                                <input className="form-check-input" type="checkbox" id="flexCheckActive" name="active" checked={active} onChange={this.handleInputChange} />
                                                                <label className="form-check-label" htmlFor="flexCheckActive">
                                                                    Mostrar como activo
                                                                </label>
                                                            </div>
                                                        </> :
                                                        <>
                                                            <p><span className='subtitle-2'>Estado: </span><span className='body-2'>{active ? "Activo" : "Inactivo"}</span></p>
                                                        </>
                                                }
                                            </> : <></>
                                    }
                                    <h6 className='subtitle-1 mb-3'>Lista de reglas:</h6>
                                    {rules.map((rule, index) => (
                                        <Fragment key={index}>
                                            <div className={`${this.props.mode === "view" ? "rule-item-container mb-3" : ""}`}>
                                                <div className={`row ${this.props.mode === "view" ? "" : "mb-3"}`}>
                                                    <div className={`${this.props.mode === "view" ? "col" : "col-4 col-sm-6 col-md-6 align-self-center"}`}>
                                                        {
                                                            this.props.mode !== "view" ?
                                                                <>
                                                                    <div className="form-floating">
                                                                        <select
                                                                            className="form-select"
                                                                            required
                                                                            id="floatingRuleInput"
                                                                            placeholder=''
                                                                            value={rule.ruleId}
                                                                            onChange={(e) => this.handleRuleChange(index, parseInt(e.target.value))}
                                                                            disabled={this.props.mode === "view"}
                                                                        >
                                                                            {
                                                                                ruleOptions.map((option, indexB) => (
                                                                                    <Fragment key={indexB}>
                                                                                        <option value={option.ruleId}>
                                                                                            {option.name}
                                                                                        </option>
                                                                                    </Fragment>))}
                                                                        </select>
                                                                        <label htmlFor="floatingRuleInput">Seleccione la regla que desea verificar</label>
                                                                        <div className="invalid-feedback">
                                                                            Debe seleccionar una regla
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        rule?.valid === false ?
                                                                            <>
                                                                                <div className="d-block invalid-feedback">
                                                                                    Lo sentimos, no se puede crear un sistema externo con reglas repetidas
                                                                                </div>
                                                                            </> : <></>
                                                                    }
                                                                </> : <>
                                                                    <p><span className="subtitle-2">Nombre de la regla: </span><span className="body-2">{rule.name}</span></p>
                                                                    <p><span className="subtitle-2">Características: </span><span className="body-2">{rule.characteristics}</span></p>
                                                                    <p><span className="subtitle-2">Mensaje de error: </span><span className="body-2">{rule.errorMessage}</span></p>
                                                                    <p><span className="subtitle-2">Formatos permitidos: </span><span className="body-2">{rule.imageFormats}</span></p>
                                                                    <p className='mb-0'><span className='subtitle-2'>Estado: </span><span className='body-2'>{rule.active ? "Activa" : "Inactiva"}</span></p>
                                                                </>
                                                        }
                                                    </div>
                                                    {
                                                        this.props.mode === "view" ?
                                                            <>
                                                            </> :
                                                            <>
                                                                <div className="col-4 col-sm-3 col-md-3 align-self-center">
                                                                    <button type='button' className={`btn btn-outline-primary w-100`} onClick={() => handleRuleDetails(rule)} >
                                                                        Ver detalle
                                                                    </button>
                                                                </div>
                                                                <div className='col-4 col-sm-3 col-md-3 align-self-center'>
                                                                    <button style={{ color: 'var(--ul-error-500)' }} className='btn btn-link w-100' type='button' onClick={() => { roles.some(userRole => userRole.roleKey === Roles.ROLE_MANAGER) ? this.handleRemoveRuleConfirm(index) : this.handleRemoveRule(index) }}>Eliminar</button>
                                                                </div>
                                                            </>
                                                    }
                                                </div>
                                                {
                                                    this.props.mode === "view" ?
                                                        <>
                                                            <div className='row mt-3'>
                                                                <div className="col text-end">
                                                                    <button type='button' className={`btn btn-outline-primary w-100`} onClick={() => handleRuleDetails(rule)} >
                                                                        Ver detalle
                                                                        <i className="bi bi-arrow-right ms-2"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </> :
                                                        <></>
                                                }
                                            </div>
                                        </Fragment>
                                    ))}
                                    {
                                        this.props.mode === "view" ?
                                            <>
                                                <div className='d-flex justify-content-end'>
                                                    <button ref={this.refBtnBack} className='btn btn-primary' type='button' onClick={() => { this.props.navigate(-1) }}>Volver</button>
                                                </div>
                                            </> :
                                            <>
                                                <button className='btn btn-link' type='button' onClick={this.handleAddRule}><i className='bi bi-plus-lg me-2'></i>Agregar Regla</button>
                                                <div className='d-flex justify-content-end mt-3'>
                                                    <button className='me-3 btn btn-outline-primary' type='button' onClick={() => this.handleDiscardChanges()}>Cancelar</button>
                                                    <button className='btn btn-primary' onClick={e => this.handleSaveChanges(e)}>
                                                        <span className="spinner-border spinner-border-sm me-2 hidden" role="status" aria-hidden="true"></span>
                                                        {this.props.mode === "create" ? "Crear sistema externo" : "Guardar cambios"}
                                                    </button>
                                                </div>
                                            </>
                                    }
                                </div>

                                <button ref={this.refBtnConfirmRuleDelete} type="button" hidden className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#confirmRuleDeleteModal">
                                    Delete Rule
                                </button>
                                <div className="modal fade" id="confirmRuleDeleteModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="confirmRuleDeleteModalLabel" aria-hidden="true">
                                    <div className="modal-dialog modal-dialog-centered">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className='mb-0' id="confirmRuleDeleteModalLabel">Eliminar regla</h5>
                                            </div>
                                            <div className="modal-body">
                                                <p className='mb-0'>
                                                    ¿Estás seguro(a) que deseas eliminar la regla {this.state.deletedRule?.createdBy === username && "propia"} "{this.state.deletedRule?.name}"{this.state.deletedRule?.createdBy !== username && ` del usuario ${this.state.deletedRule.createdBy}`}?
                                                </p>
                                            </div>
                                            <div className="modal-footer">
                                                <button ref={this.refBtnCloseConfirmRuleDelete} type="button" className="btn btn-outline-primary" data-bs-dismiss="modal">Cancelar</button>
                                                <button className="btn btn-primary" type='button' data-bs-dismiss="modal" onClick={() =>
                                                {
                                                    if (this.handleRemoveRule(this.state.deletedRule.index ?? -1))
                                                    {
                                                        this.refBtnCloseConfirmRuleDelete.current.click();
                                                        this.setState({
                                                            deletedRule: { index: -1 },
                                                        })
                                                    }
                                                }}>Confirmar</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <button ref={this.refBtnCancel} type="button" hidden className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#cancelCreationEditionModal">
                                    Cancel
                                </button>
                                <div className="modal fade" id="cancelCreationEditionModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="cancelCreationEditionModalLabel" aria-hidden="true">
                                    <div className="modal-dialog modal-dialog-centered">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className='mb-0' id="cancelCreationEditionModalLabel">Cancelar {this.props.mode === "create" ? "creación" : "edición"} del sistema externo</h5>
                                            </div>
                                            <div className="modal-body">
                                                <p className='mb-0'>
                                                    ¿Estás seguro(a) que deseas cancelar la {this.props.mode === "create" ? "creación" : "edición"} del sistema externo y volver a la pantalla de sistemas externos?
                                                </p>
                                            </div>
                                            <div className="modal-footer">
                                                <button ref={this.refBtnCloseCancel} type="button" className="btn btn-outline-primary" data-bs-dismiss="modal">Cerrar</button>
                                                <button className="btn btn-primary" type='button' data-bs-dismiss="modal" onClick={() =>
                                                {
                                                    this.refBtnCloseCancel.current.click();
                                                    sessionStorage.removeItem("newEditRuleFields");
                                                    this.props.navigate(ROUTE_SYSTEM_MODULE);
                                                }}>Confirmar cancelación</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <button ref={this.refBtnSave} type="button" hidden className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#saveCreationEditionModal">
                                    Save
                                </button>
                                <div className="modal fade" id="saveCreationEditionModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="saveCreationEditionModalLabel" aria-hidden="true">
                                    <div className="modal-dialog modal-dialog-centered">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className='mb-0' id="saveCreationEditionModalLabel">
                                                    {
                                                        this.props.mode === "create" ?
                                                            <>
                                                                Crear nuevo sistema externo
                                                            </> :
                                                            <>
                                                                Guardar cambios
                                                            </>
                                                    }
                                                </h5>
                                            </div>
                                            <div className="modal-body">
                                                <p className='mb-0'>
                                                    {
                                                        this.props.mode === "create" ?
                                                            <>
                                                                ¿Estás seguro(a) de que deseas crear el sistema externo "{name}"?
                                                            </> :
                                                            <>
                                                                ¿Estás seguro(a) de que deseas guardar los cambios realizados en el sistema externo "{name}"?
                                                            </>
                                                    }
                                                </p>
                                            </div>
                                            <div className="modal-footer">
                                                <button ref={this.refBtnCloseSave} type="button" className="btn btn-outline-primary" data-bs-dismiss="modal">Cerrar</button>
                                                <button className="btn btn-primary" type="button" data-bs-dismiss="modal" onClick={(e) =>
                                                {
                                                    this.refBtnCloseSave.current.click();
                                                    this.handleSaveSystem(e);
                                                }}>
                                                    <span className="spinner-border spinner-border-sm me-2 hidden" role="status" aria-hidden="true"></span>
                                                    {
                                                        this.props.mode === "create" ?
                                                            <>
                                                                Confirmar creación
                                                            </> :
                                                            <>
                                                                Confirmar
                                                            </>
                                                    }
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </>
                }
            </>
        );
    }
}

export default BaseExternalSystem;
