import React, { Component, Fragment } from 'react';
import './Profile.css';
import http from '../../../api/HTTPService';
import Loading from '../../../components/Loading';
import Layout from '../../../components/Layout/Layout';
import { Link } from 'react-router-dom';
import Snackbar from '../../../components/Snackbar';
import { API_USER_PROFILE } from '../../../constant/APIUrls';

class Profile extends Component
{
    constructor (props)
    {
        super(props);
        this.state = {
            fullName: '',
            systemsStr: '',
            username: '',
            rolesStr: '',
            isLoadingUser: false,

            showSnackbar: false,
            snackbarMsg: "",
        }
    }

    handleFetchUser = (userId) =>
    {
        if (!userId) return;
        this.setState({
            isLoadingUser: true,
        })
        http.get(`${API_USER_PROFILE}?userId=${userId}`)
            .then((response) =>
            {
                this.setState({
                    fullName: response.data.data.fullName ?? '',
                    systemsStr: response.data.data.systems ?? 'No se asignaron sistemas',
                    username: response.data.data.username ?? '',
                    rolesStr: response.data.data.roles ?? 'No se asignaron roles',
                });
            })
            .catch((error) =>
            {
                console.log(error);
            })
            .finally(() =>
            {
                this.setState({
                    isLoadingUser: false,
                })
            })
    };

    loadSnackbar ()
    {
        const snackbar = localStorage.getItem("snackbar");
        if (snackbar)
        {
            const msg = localStorage.getItem("msg");
            this.setState({
                showSnackbar: true,
                snackbarMsg: msg,
            })
            window.setTimeout(() =>
            {
                this.setState({
                    showSnackbar: false,
                })
                localStorage.removeItem("snackbar");
                localStorage.removeItem("msg")
            }, 5000)
        }
    }

    componentDidMount ()
    {
        const userId = localStorage.getItem('userId');
        this.loadSnackbar();
        this.handleFetchUser(userId);
    }

    render ()
    {
        return (
            <>
                {
                    (this.state.isLoadingUser) ?
                        <>
                            <Loading />
                        </> : <>
                            <Layout noSidebar={true}>
                                <Snackbar showSnackbar={this.state.showSnackbar} snackbarMsg={this.state.snackbarMsg} />
                                <div className='row my-3'>
                                    <div className='col'>
                                        <h4 className='mb-0'>Mi perfil</h4>
                                    </div>
                                </div>
                                <div className='row mb-3'>
                                    <div className='col'>
                                        <div className="card search-filters-container">
                                            <div className="card-body">
                                                <h6 className='subtitle-1 mb-3'>Datos del usuario:</h6>
                                                <p><span className="subtitle-2">Usuario: </span><span className="body-2">{this.state.username}</span></p>
                                                <p><span className="subtitle-2">Roles: </span><span className="body-2">{this.state.rolesStr}</span></p>
                                                <p><span className="subtitle-2">Nombre completo: </span><span className="body-2">{this.state.fullName}</span></p>
                                                <p><span className="subtitle-2">Sistema(s) asignado(s): </span><span className="body-2">{this.state.systemsStr}</span></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col d-flex justify-content-end'>
                                        <Link to={-1} className='btn btn-outline-primary me-3' type='button'>Volver</Link>
                                        <Link to='/password' className='btn btn-primary' type='button'>Actualizar contraseña</Link>
                                    </div>
                                </div>
                            </Layout>
                        </>
                }
            </>
        );
    }
}

export default Profile;
