import React, { Component, Fragment, createRef } from 'react';
import './BaseUser.css';
import http from '../../../api/HTTPService';
import { Link } from 'react-router-dom';
import Loading from '../../../components/Loading';
import { MultiSelect } from 'react-multi-select-component';
import InputPassword from '../../../components/InputPassword';
import { API_EXTERNAL_SYSTEM_LIST, API_USER_BASE, API_USER_DETAILS_REL, API_USER_FETCH_REL, API_USER_ROLES, API_USER_SAVE, API_USER_VERIFY_USERNAME } from '../../../constant/APIUrls';
import { ROUTE_REL_SYSTEM_VIEW, ROUTE_SYSTEM_MODULE, ROUTE_USER_MODULE } from '../../../constant/AppRoutes';

class BaseUser extends Component
{
    constructor (props)
    {
        super(props);
        this.state = {
            fullName: '',
            usernameToSave: '',
            usernameToSaveFetched: '',
            passwordToSave: '',
            systems: [],
            roles: [],
            rolesStr: '',
            systemOptions: [],
            roleOptions: [],

            isLoadingSystems: false,
            isLoadingUser: false,
            isLoadingRoles: false,

            errMessages: [],
            errUsernameTaken: false,
            errUsername: '',
            errFullname: '',
            errPassword: '',
            errRoles: '',
            errSystems: '',
        };
        this.refBtnCancel = createRef();
        this.refBtnSave = createRef();
        this.refBtnCloseCancel = createRef();
        this.refBtnCloseSave = createRef();
    }

    getSystemsList()
    {
        this.setState({
            isLoadingSystems: true,
        })
        http.get(API_EXTERNAL_SYSTEM_LIST)
            .then((response) =>
            {
                this.setState({
                    systemOptions: response.data.data?.list ?? [],
                });
            })
            .catch((error) =>
            {
                console.log(error);
            })
            .finally(() =>
            {
                this.setState({
                    isLoadingSystems: false,
                })
            });
    }

    getRolesList ()
    {
        this.setState({
            isLoadingRoles: true,
        })
        http.get(API_USER_ROLES)
            .then((response) =>
            {
                const roles = response.data.data.list.map((role) => ({ label: role.name, value: role.roleId }));
                this.setState({
                    roleOptions: roles,
                });
            })
            .catch((error) =>
            {
                console.log(error);
            })
            .finally(() =>
            {
                this.setState({
                    isLoadingRoles: false,
                })
            })
    }

    isValidForm ()
    {
        const systems = this.state.systems;
        let valid = true;
        let addedSystems = [];
        this.setState({
            errFullname: '',
            errUsername: '',
            errPassword: '',
            errRoles: '',
            errSystems: '',
        })
        if (!this.state.systems.length)
        {
            this.setState({ errSystems: 'Debe agregar al menos un sistema externo' });
            valid = false;
        }
        else
        {
            let updatedSystems = systems.map((system) =>
            {
                const added = addedSystems.some((added) => added === system.systemId);
                addedSystems.push(system.systemId);
                added && (valid = false);
                return added ? { ...system, valid: false } : { ...system }
            })
            this.setState({ systems: updatedSystems });
        }
        if (this.state.errUsernameTaken) valid = false;
        if (!this.state.fullName.length)
        {
            this.setState({ errFullname: 'Este campo es obligatorio' });
            valid = false;
        }
        if (!this.state.usernameToSave.length)
        {
            this.setState({ errUsername: 'Este campo es obligatorio' });
            valid = false;
        }
        if (this.props.mode === "create" && !this.state.passwordToSave.length)
        {
            this.setState({ errPassword: 'Este campo es obligatorio' });
            valid = false;
        }
        if (!this.state.roles.length)
        {
            this.setState({ errRoles: 'Debe seleccionar al menos un rol' });
            valid = false;
        }
        return valid;
    }

    handleSaveChanges (e)
    {
        if (!this.isValidForm())
        {
            e.stopPropagation();
        }
        else
        {
            this.refBtnSave.current.click();
        }
    }

    componentDidMount ()
    {
        this.props.mode !== "view" && this.getSystemsList();
        this.props.mode !== "view" && this.getRolesList();
        this.props.mode !== "create" && this.handleFetchUser();
    }

    handleInputChange = (event) =>
    {
        const { name, value, type, checked } = event.target;
        const newValue = type === 'checkbox' ? checked : value;
        this.setState({ [name]: newValue });
    };

    handleSystemChange = (index, value) =>
    {
        const { systems } = this.state;
        const updatedSystems = [...systems];
        updatedSystems[index] = { systemId: value };
        this.setState({ systems: updatedSystems });
    };

    handleAddSystem = () =>
    {
        this.setState((prevState) => ({
            errSystems: '',
            systems: [...prevState.systems, { systemId: this.state.systemOptions.length > 0 ? this.state.systemOptions[0].systemId : null }]
        }));
    };

    handleRemoveSystem = (index) =>
    {
        const { systems } = this.state;
        const updatedSystems = [...systems];
        updatedSystems.splice(index, 1);
        this.setState({ systems: updatedSystems });
    };

    handleFetchUser = () =>
    {
        if (!this.props.userId) return;
        this.setState({
            isLoadingUser: true,
        })
        http.get(`${API_USER_BASE}/${this.props.mode === "view" ? API_USER_DETAILS_REL : API_USER_FETCH_REL}?userId=${this.props.userId}`)
            .then((response) =>
            {
                if (this.props.mode === "view")
                {
                    this.setState({
                        fullName: response.data.data.fullName ?? '',
                        systems: response.data.data.systems ?? [],
                        usernameToSave: response.data.data.username ?? '',
                        usernameToSaveFetched: response.data.data.username ?? '',
                        rolesStr: response.data.data.roles,
                    });
                }
                else
                {
                    const roles = response.data.data.roles.map((role) => ({ value: role.roleId, label: role.name }));
                    this.setState({
                        fullName: response.data.data.fullName ?? '',
                        systems: response.data.data.systems ?? [],
                        usernameToSave: response.data.data.username ?? '',
                        usernameToSaveFetched: response.data.data.username ?? '',
                        roles: roles ?? [],
                    });
                }
            })
            .catch((error) =>
            {
                console.log(error);
            })
            .finally(() =>
            {
                this.setState({
                    isLoadingUser: false,
                })
            })
    };

    handleSaveUser = (e) =>
    {
        if (!this.isValidForm()) return;
        const username = localStorage.getItem('username');
        const roles = this.state.roles.map((role) => ({ roleId: role.value }));
        const user = {
            userId: this.props.userId,
            fullName: this.state.fullName,
            usernameToSave: this.state.usernameToSave,
            passwordToSave: this.state.passwordToSave,
            username: username,
            systems: this.state.systems,
            roles: roles,
        }
        if (this.props.mode === "edit" && !this.state.passwordToSave.length)
            delete user.passwordToSave;
        if (this.props.mode === "create")
            delete user.userId;
        e.target.disabled = true;
        const loadingSpinner = e.target.querySelector('span');
        loadingSpinner.classList.remove('hidden');
        this.setState({
            errMessages: [],
        })
        http.post(API_USER_SAVE, user)
            .then((response) =>
            {
                localStorage.setItem("snackbar", true);
                localStorage.setItem("msg", `${this.props.mode === "create" ? "Creación" : "Actualización"} de usuario ${user.usernameToSave} realizada correctamente.`)
                this.props.navigate(ROUTE_USER_MODULE);
            })
            .catch((error) =>
            {
                this.setState({
                    errMessages: error.response ? error.response.data?.messages ?? [{ code: "", message: "Error desconocido" }] : [{ code: "", message: "Error de red" }],
                })
                window.scrollTo({ top: 0, behavior: 'smooth' });
            })
            .finally(() =>
            {
                loadingSpinner.classList.add('hidden');
                e.target.disabled = false;
            })
    }

    handleCheckUsernameAvailability ()
    {
        if (this.state.usernameToSave === this.state.usernameToSaveFetched) return;
        this.setState({
            errUsernameTaken: false,
            errUsername: '',
        });
        http.get(`${API_USER_VERIFY_USERNAME}?username=${this.state.usernameToSave}`)
            .then((response) =>
            {
                if (!response.data.data.valid)
                {
                    this.setState({
                        errUsernameTaken: true,
                        errUsername: 'Usuario ya registrado',
                    })
                }
            })
            .catch((error) =>
            {
                console.log(error);
            })
    }

    handleDiscardChanges ()
    {
        this.refBtnCancel.current.click();
    }

    render ()
    {
        const {
            fullName, usernameToSave, passwordToSave, roles, rolesStr, systems,
            systemOptions, roleOptions,
            errUsername, errFullname, errPassword, errRoles, errSystems,
            isLoadingRoles, isLoadingUser, isLoadingSystems
        } = this.state;
        return (
            <>
                {
                    (isLoadingSystems ||
                        isLoadingUser || isLoadingRoles) ?
                        <>
                            <Loading />
                        </> : <>
                            {
                                this.props.mode === "view" ?
                                    <>
                                        <div>
                                            <h6 className='subtitle-1 mb-3'>Datos del usuario:</h6>
                                            <p><span className="subtitle-2">Nombre completo: </span><span className="body-2">{fullName}</span></p>
                                            <p><span className="subtitle-2">Usuario: </span><span className="body-2">{usernameToSave}</span></p>
                                            <p><span className="subtitle-2">Roles: </span><span className="body-2">{rolesStr}</span></p>
                                            <p><span className="subtitle-2">Sistema(s) asignado(s): </span></p>
                                            {
                                                systems.map((system, index) => (
                                                    <Fragment key={index}>
                                                        <div>
                                                            <div className={`row mb-3`}>
                                                                <div className={`col align-self-center`}>
                                                                    <p className='mb-0'>{system.name}</p>
                                                                </div>
                                                                <div className={`col align-self-center`}>
                                                                    <Link to={`${ROUTE_SYSTEM_MODULE}/${system.systemId}${ROUTE_REL_SYSTEM_VIEW}?from=userDetails&userId=${this.props.userId}`} className={`btn btn-outline-primary w-100`} >
                                                                        Ver detalle
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Fragment>
                                                ))
                                            }
                                        </div>
                                    </> :
                                    <>
                                        <form>
                                            <div>
                                                <h6 className='subtitle-1 mb-3'>Complete los siguientes datos:</h6>
                                                {
                                                    (this.state.errMessages && this.state.errMessages.length) ? <div className="alert alert-danger" role="alert">
                                                        {
                                                            this.state.errMessages.map((err, index) =>
                                                            {
                                                                return (
                                                                    <Fragment key={index}>
                                                                        <p className='mb-0'> {err.code?.length ? `[${err.code}]` : ""} {err.message}</p>
                                                                    </Fragment>
                                                                )
                                                            })
                                                        }
                                                    </div> : <></>
                                                }
                                                <div className='row row-cols-1 row-cols-sm-2 row-cols-lg-4 g-3 mb-3'>
                                                    <div className='col'>
                                                        <div className="form-floating">
                                                            <input className='form-control' id="floatInputFullName" type="text" name="fullName" placeholder='Nombres completos' value={fullName} onChange={this.handleInputChange} />
                                                            <label htmlFor='floatInputFullName'>Nombres completos</label>
                                                            {
                                                                errFullname.length ?
                                                                    <>
                                                                        <div className="d-block invalid-feedback">
                                                                            {errFullname}
                                                                        </div>
                                                                    </> : <></>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className='col'>
                                                        <div className="form-floating">
                                                            <input className='form-control' autoComplete="off" id="floatInputUsername" type="text" name="usernameToSave" placeholder='Usuario' value={usernameToSave} onChange={this.handleInputChange} onBlur={this.handleCheckUsernameAvailability.bind(this)} />
                                                            <label htmlFor='floatInputUsername'>Usuario</label>
                                                            {
                                                                errUsername.length ?
                                                                    <>
                                                                        <div className="d-block invalid-feedback">
                                                                            {errUsername}
                                                                        </div>
                                                                    </> : <></>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className='col'>
                                                        <div className="form-floating">
                                                            {/* <input className='form-control' autoComplete="new-password" id="floatInputPassword" type="password" name="passwordToSave" placeholder='Contraseña' value={passwordToSave} onChange={this.handleInputChange} /> */}
                                                            <InputPassword id="floatInputPassword" name="passwordToSave" placeholder='Contraseña' value={passwordToSave} onChange={this.handleInputChange} />
                                                            <label htmlFor='floatInputPassword'>Contraseña</label>
                                                            {
                                                                errPassword.length ?
                                                                    <>
                                                                        <div className="d-block invalid-feedback">
                                                                            {errPassword}
                                                                        </div>
                                                                    </> : <></>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className='col'>
                                                        <div className="form-floating">
                                                            <MultiSelect
                                                                options={roleOptions}
                                                                value={roles}
                                                                onChange={(value) =>
                                                                {
                                                                    this.setState({ roles: value })
                                                                }}
                                                                labelledBy="Select"
                                                                className='ul-multi-select form-control'
                                                                overrideStrings={{
                                                                    allItemsAreSelected: "Todos",
                                                                    clearSearch: "Limpiar",
                                                                    clearSelected: "Limpiar seleccionados",
                                                                    noOptions: "Sin opciones",
                                                                    search: "Buscar",
                                                                    selectAll: "Seleccionar todo",
                                                                    selectAllFiltered: "Seleccionar todo (filtrado)",
                                                                    selectSomeItems: "Selecciona los roles",
                                                                    create: "Crear",
                                                                }}
                                                                placeholder='Selecciona los roles'
                                                                id="floatInputRole"
                                                            />
                                                            <label htmlFor='floatInputRole'>Selecciona los roles</label>
                                                            {
                                                                errRoles.length ?
                                                                    <>
                                                                        <div className="d-block invalid-feedback">
                                                                            {errRoles}
                                                                        </div>
                                                                    </> : <></>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                {systems.map((system, index) => (
                                                    <Fragment key={index}>
                                                        <div>
                                                            <div className={`row mb-3`}>
                                                                <div className={`col-4 col-sm-6 col-md-6 align-self-center`}>
                                                                    <div className="form-floating">
                                                                        <select
                                                                            className="form-select"
                                                                            id="floatingSystemInput"
                                                                            placeholder=''
                                                                            value={system.systemId}
                                                                            onChange={(e) => this.handleSystemChange(index, parseInt(e.target.value))}
                                                                        >
                                                                            {
                                                                                systemOptions.map((option, indexB) => (
                                                                                    <Fragment key={indexB}>
                                                                                        <option value={option.systemId}>
                                                                                            {option.name}
                                                                                        </option>
                                                                                    </Fragment>))}
                                                                        </select>
                                                                        <label htmlFor="floatingSystemInput">Seleccione el sistema externo que desea asignar al nuevo usuario</label>
                                                                    </div>
                                                                    {
                                                                        system?.valid === false ?
                                                                            <>
                                                                                <div className="d-block invalid-feedback">
                                                                                    Lo sentimos, no se puede crear un usuario con sistemas externos repetidos
                                                                                </div>
                                                                            </> : <></>
                                                                    }
                                                                </div>
                                                                <div className="col-4 col-sm-3 col-md-3 align-self-center">
                                                                    <Link to={`${ROUTE_SYSTEM_MODULE}/${system.systemId}${ROUTE_REL_SYSTEM_VIEW}?from=${this.props.mode === "create" ? "userCreation" : "userEdition"}&userId=${this.props.userId}`} className={`btn btn-outline-primary w-100`} >
                                                                        Ver detalle
                                                                    </Link>
                                                                </div>
                                                                <div className='col-4 col-sm-3 col-md-3 align-self-center'>
                                                                    <button style={{ color: 'var(--ul-error-500)' }} className='btn btn-link w-100' type='button' onClick={() => this.handleRemoveSystem(index)}>Eliminar</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Fragment>
                                                ))}
                                                <button className='btn btn-link' type='button' onClick={this.handleAddSystem}><i className='bi bi-plus-lg me-2'></i>Agregar Sistema</button>
                                                {
                                                    errSystems.length ?
                                                        <>
                                                            <div className="d-block invalid-feedback">
                                                                {errSystems}
                                                            </div>
                                                        </> : <></>
                                                }

                                                <div className='d-flex justify-content-end mt-3'>
                                                    <button className='me-3 btn btn-outline-primary' type='button' onClick={() => this.handleDiscardChanges()}>Cancelar</button>
                                                    <button className='btn btn-primary' type='button' onClick={e => this.handleSaveChanges(e)}>
                                                        <span className="spinner-border spinner-border-sm me-2 hidden" role="status" aria-hidden="true"></span>
                                                        {this.props.mode === "create" ? "Confirmar creación" : "Guardar cambios"}
                                                    </button>
                                                </div>
                                            </div>


                                            <button ref={this.refBtnCancel} type="button" hidden className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#cancelCreationEditionModal">
                                                Cancel
                                            </button>
                                            <div className="modal fade" id="cancelCreationEditionModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="cancelCreationEditionModalLabel" aria-hidden="true">
                                                <div className="modal-dialog modal-dialog-centered">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5 className='mb-0' id="cancelCreationEditionModalLabel">Cancelar {this.props.mode === "create" ? "creación" : "edición"} del usuario</h5>
                                                        </div>
                                                        <div className="modal-body">
                                                            <p className='mb-0'>
                                                                ¿Estás seguro(a) que deseas cancelar la {this.props.mode === "create" ? "creación" : "edición"} del usuario y volver a la pantalla de gestión de usuarios?
                                                            </p>
                                                        </div>
                                                        <div className="modal-footer">
                                                            <button ref={this.refBtnCloseCancel} type="button" className="btn btn-outline-primary" data-bs-dismiss="modal">Cerrar</button>
                                                            <button className="btn btn-primary" type='button' data-bs-dismiss="modal" onClick={() =>
                                                            {
                                                                this.refBtnCloseCancel.current.click();
                                                                this.props.navigate(ROUTE_USER_MODULE);
                                                            }}>Confirmar cancelación</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <button ref={this.refBtnSave} type="button" hidden className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#saveCreationEditionModal">
                                                Save
                                            </button>
                                            <div className="modal fade" id="saveCreationEditionModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="saveCreationEditionModalLabel" aria-hidden="true">
                                                <div className="modal-dialog modal-dialog-centered">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5 className='mb-0' id="saveCreationEditionModalLabel">
                                                                {
                                                                    this.props.mode === "create" ?
                                                                        <>
                                                                            Crear nuevo usuario
                                                                        </> :
                                                                        <>
                                                                            Guardar cambios
                                                                        </>
                                                                }
                                                            </h5>
                                                        </div>
                                                        <div className="modal-body">
                                                            <p className='mb-0'>
                                                                {
                                                                    this.props.mode === "create" ?
                                                                        <>
                                                                            ¿Estás seguro(a) de que deseas crear el nuevo usuario "{fullName}"?
                                                                        </> :
                                                                        <>
                                                                            ¿Estás seguro(a) de que deseas guardar los cambios realizados para el usuario "{fullName}"?
                                                                        </>
                                                                }
                                                            </p>
                                                        </div>
                                                        <div className="modal-footer">
                                                            <button ref={this.refBtnCloseSave} type="button" className="btn btn-outline-primary" data-bs-dismiss="modal">Cerrar</button>
                                                            <button className="btn btn-primary" type="button" data-bs-dismiss="modal" onClick={(e) =>
                                                            {
                                                                this.refBtnCloseSave.current.click();
                                                                this.handleSaveUser(e);
                                                            }}>
                                                                <span className="spinner-border spinner-border-sm me-2 hidden" role="status" aria-hidden="true"></span>
                                                                {
                                                                    this.props.mode === "create" ?
                                                                        <>
                                                                            Confirmar creación
                                                                        </> :
                                                                        <>
                                                                            Confirmar
                                                                        </>
                                                                }
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </>
                            }
                        </>
                }
            </>
        );
    }
}

export default BaseUser;
