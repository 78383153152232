import React, { Fragment, createRef } from "react";
import './VerificationVerify.css';
import http from "../../../api/HTTPService";
import Layout from "../../../components/Layout/Layout";
import Loading from "../../../components/Loading";
import { API_SECURITY_AUTHENTICATE_EXTERNAL, API_VERIFICATION_DOCUMENT_TYPES, API_VERIFICATION_FIND_PERSON_BY_DOCUMENT, API_VERIFICATION_RULES, API_VERIFICATION_RULE_SYSTEMS, API_VERIFICATION_SAVE_B64, API_VERIFICATION_VERIFY } from "../../../constant/APIUrls";
import { ROUTE_REL_RULE_VIEW, ROUTE_REL_VERIFICATION_RESULT, ROUTE_REL_VERIFICATION_RESULT_SUMMARY, ROUTE_RULE_MODULE, ROUTE_VERIFICATION_MODULE } from "../../../constant/AppRoutes";
import UserAvatarEditor from "../../../components/UserAvatarEditor/UserAvatarEditor";

export default class VerificationVerify extends React.Component
{
    constructor (props)
    {
        super(props);
        this.state = {
            uploadProgress: 0,
            errMessages: [],
            documentTypes: [],
            optionsRules: [],
            optionsExternalSystems: [],
            inputSelectedDocumentType: 0,
            inputNames: '',
            inputFathersLastName: '',
            inputMothersLastName: '',
            inputDNI: '',
            inputPhone: '',
            inputEmail: '',
            inputSelectedRule: 0,
            inputSelectedSystem: 0,

            errDocumentType: "",
            errNames: "",
            errFathersLastName: "",
            errMothersLastName: "",
            errDNI: "",
            errPhone: "",
            errEmail: "",
            errFile: "",

            errDocumentTypeMsg: "",
            errNamesMsg: "",
            errFathersLastNameMsg: "",
            errMothersLastNameMsg: "",
            errDNIMsg: "",
            errFileMsg: "",

            validating: false,

            isLoadingRules: false,

            extImageCode: '',
            extSourceCode: '',
            extUrlObject: '',
            extUserCode: '',
            extCharacteristics: '',
            extImageFormats: '',
            extMinSizeY: 288,
            extMinSizeX: 240,
            extUsername: '',
            extToken: '',
            extRender: true,
        }
        this.refBtnCancel = createRef();
        this.refBtnCloseCancel = createRef();
        this.refUserAvatarEditor = createRef();
    }

    static defaultProps = {
        external: false,
    }

    handleInputChange(key, value)
    {
        const keyErr = "err" + key.substring(5)
        this.setState({
            [key]: value,
            [keyErr]: "",
        })
    }

    handleDocumentTypeChange(e)
    {
        this.setState({
            inputSelectedDocumentType: parseInt(e.target.value),
            errDocumentType: '',
        }, () =>
        {
            this.findPersonById(this.state.inputDNI);
        })
    }

    handleRuleChange(value)
    {
        this.setState({
            inputSelectedRule: parseInt(value),
        });
        this.getRulesSystemsList(parseInt(value));
    }

    handleSystemChange(value)
    {
        this.setState({
            inputSelectedSystem: parseInt(value),
        });
    }

    getRulesSystemsList(ruleId)
    {
        if (!ruleId) return;
        const username = localStorage.getItem('username');
        http.post(API_VERIFICATION_RULE_SYSTEMS, {
            ruleId: ruleId,
            username: username,
        })
            .then((response) =>
            {
                this.setState({
                    optionsExternalSystems: response.data.data?.list ?? [],
                    inputSelectedSystem: response.data.data?.list.length > 0 ? response.data.data.list[0].systemId : -1,
                })
            })
            .catch((error) =>
            {
                console.log(error);
            });
    }

    getRulesList()
    {
        this.setState({
            isLoadingRules: true,
        })
        const username = localStorage.getItem('username');
        http.post(API_VERIFICATION_RULES, {
            username: username,
        })
            .then((response) =>
            {
                this.setState({
                    optionsRules: response.data.data?.list ?? [],
                    inputSelectedRule: response.data.data?.list.length > 0 ? response.data.data.list[0].ruleId : 0,
                }, () =>
                {
                    this.getRulesSystemsList(this.state.inputSelectedRule);
                });
                this.resumeSession();
            })
            .catch((error) =>
            {
                console.log(error);
            })
            .finally(() =>
            {
                this.setState({
                    isLoadingRules: false,
                })
            });
    }

    getDocumentTypeList()
    {
        http.get(API_VERIFICATION_DOCUMENT_TYPES)
            .then((response) =>
            {
                this.setState({
                    documentTypes: response.data.data?.list ?? [],
                });
                if (!this.props.external)
                {
                    this.setState({
                        inputSelectedDocumentType: response.data.data?.list.length > 0 ? response.data.data.list[0].documentType : 0,
                    })
                }
            })
            .catch((error) =>
            {
                console.log(error);
            });
    }

    storeSession()
    {
        const fields = {
            inputNames: this.state.inputNames,
            inputFathersLastName: this.state.inputFathersLastName,
            inputMothersLastName: this.state.inputMothersLastName,
            inputDNI: this.state.inputDNI,
            inputSelectedDocumentType: this.state.inputSelectedDocumentType,
            inputSelectedRule: this.state.inputSelectedRule,
            inputSelectedSystem: this.state.inputSelectedSystem,
            inputPhone: this.state.inputPhone,
            inputEmail: this.state.inputEmail,
        }
        sessionStorage.setItem("newVerificationFields", JSON.stringify(fields));
    }

    resumeSession()
    {
        const formDataStr = sessionStorage.getItem("newVerificationFields");
        if (formDataStr)
        {
            const formData = JSON.parse(formDataStr);
            this.setState({
                inputNames: formData.inputNames ?? '',
                inputFathersLastName: formData.inputFathersLastName ?? '',
                inputMothersLastName: formData.inputMothersLastName ?? '',
                inputDNI: formData.inputDNI ?? '',
                inputSelectedDocumentType: formData.inputSelectedDocumentType,
                inputSelectedRule: formData.inputSelectedRule,
                inputSelectedSystem: formData.inputSelectedSystem,
                inputPhone: formData.inputPhone ?? '',
                inputEmail: formData.inputEmail ?? '',
            })
            sessionStorage.removeItem("newVerificationFields");
            return true;
        }
        return false;
    }

    handleViewRule()
    {
        this.storeSession();
        this.props.navigate(`${ROUTE_RULE_MODULE}/${this.state.inputSelectedRule}${ROUTE_REL_RULE_VIEW}?from=newVerification`);
    }

    findPersonById(id)
    {
        if (id.length < 8) return;
        if (this.state.inputSelectedDocumentType < 1) return;
        http.get(`${API_VERIFICATION_FIND_PERSON_BY_DOCUMENT}?documentNumber=${id}&documentType=${this.state.inputSelectedDocumentType}`)
            .then((response) =>
            {
                const data = response.data.data;
                if (data)
                {
                    this.setState({
                        inputNames: data.name ?? "",
                        inputFathersLastName: data.firstSurname ?? "",
                        inputMothersLastName: data.secondSurname ?? "",
                        inputEmail: data.email ?? "",
                        inputPhone: data.phoneNumber ?? "",
                        errFile: "",
                        errNames: "",
                        errFathersLastName: "",
                        errMothersLastName: "",
                        errDNI: "",
                        errPhone: "",
                        errEmail: "",
                    })
                }
            })
            .catch((error) =>
            {
                console.log(error);
            });
    }

    isValidForm = async () =>
    {
        let valid = true;
        const regexName = /^[A-Za-záéíóúÁÉÍÓÚñÑ\s-]+$/;
        const regexPhone = /^\d{9}$/;
        const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        this.setState({
            errFile: "",
            errDocumentType: "",
            errNames: "",
            errFathersLastName: "",
            errMothersLastName: "",
            errDNI: "",
            errPhone: "",
            errEmail: "",
        });
        const file = await this.refUserAvatarEditor.current?.getPicture() ?? null;
        if (!file)
        {
            this.setState({
                errFile: "is-invalid",
                errFileMsg: "Debe seleccionar una foto",
            })
            valid = false;
        }

        if (this.state.inputSelectedDocumentType < 1)
        {
            this.setState({
                errDocumentType: "is-invalid",
                errDocumentTypeMsg: "Debe seleccionar un tipo de documento"
            })
            valid = false;
        }

        if (!this.state.inputNames.length)
        {
            this.setState({
                errNames: "is-invalid",
                errNamesMsg: "Este campo es obligatorio"
            })
            valid = false;
        }

        if (this.state.inputNames.length && !regexName.test(this.state.inputNames))
        {
            this.setState({
                errNames: "is-invalid",
                errNamesMsg: "El nombre debe contener solo letras y espacios",
            })
            valid = false;
        }
        if (!this.state.inputFathersLastName.length)
        {
            this.setState({
                errFathersLastName: "is-invalid",
                errFathersLastNameMsg: "Este campo es obligatorio"
            })
            valid = false;
        }
        if (this.state.inputFathersLastName.length && !regexName.test(this.state.inputFathersLastName))
        {
            this.setState({
                errFathersLastName: "is-invalid",
                errFathersLastNameMsg: "El apellido debe contener solo letras y espacios",
            })
            valid = false;
        }
        if (!this.state.inputMothersLastName.length)
        {
            this.setState({
                errMothersLastName: "is-invalid",
                errMothersLastNameMsg: "Este campo es obligatorio"
            })
            valid = false;
        }

        if (this.state.inputMothersLastName.length && !regexName.test(this.state.inputMothersLastName))
        {
            this.setState({
                errMothersLastName: "is-invalid",
                errMothersLastNameMsg: "El apellido debe contener solo letras y espacios",
            })
            valid = false;
        }
        if (!this.state.inputDNI.length)
        {
            this.setState({
                errDNI: "is-invalid",
                errDNIMsg: "Este campo es obligatorio"
            })
            valid = false;
        }
        if (!this.state.inputDNI.length)
        {
            this.setState({
                errDNI: "is-invalid",
                errDNIMsg: "Este campo es obligatorio"
            })
            valid = false;
        }
        else
        {
            const result = this.state.documentTypes.filter((docType) => docType.documentType === this.state.inputSelectedDocumentType);
            if (result.length)
            {
                const selectedDocType = result[0];
                if (selectedDocType.length && (this.state.inputDNI.length !== selectedDocType.length))
                {
                    this.setState({
                        errDNI: "is-invalid",
                        errDNIMsg: `El ${selectedDocType.name} debe tener exactamente ${selectedDocType.length} dígitos numéricos`
                    })
                    valid = false;
                }
            }
        }
        if (this.state.inputPhone.length && !regexPhone.test(this.state.inputPhone))
        {
            this.setState({
                errPhone: "is-invalid",
            })
            valid = false;
        }
        if (this.state.inputEmail.length && !regexEmail.test(this.state.inputEmail))
        {
            this.setState({
                errEmail: "is-invalid",
            })
            valid = false;
        }
        return valid;
    }

    upload = async (e) =>
    {
        const valid = await this.isValidForm();
        if (!valid) return;
        this.setState({ errMessages: [] });
        const username = !this.props.external ? localStorage.getItem('username') : this.state.extUsername;
        const file = await this.refUserAvatarEditor.current?.getPicture() ?? null;
        const data = {
            base64: file.base64,
            base64Original: file.base64Original,
            fileName: file.name,
            name: this.state.inputNames,
            firstSurname: this.state.inputFathersLastName,
            secondSurname: this.state.inputMothersLastName,
            documentType: this.state.inputSelectedDocumentType,
            documentNumber: this.state.inputDNI,
            ruleId: this.state.inputSelectedRule,
            username: username,
        };
        // Sistema externo
        (this.state.inputSelectedSystem !== -1) && (data.systemId = this.state.inputSelectedSystem);
        this.props.external && delete data.systemId;
        this.props.external && (data.imageCode = this.state.extImageCode);
        this.props.external && (data.sourceCode = this.state.extSourceCode);
        this.props.external && (data.urlObject = this.state.extUrlObject);
        this.props.external && (data.userCode = this.state.extUserCode);
        // Datos opcionales
        this.state.inputPhone.length && (data.phoneNumber = this.state.inputPhone);
        this.state.inputEmail.length && (data.email = this.state.inputEmail);
        // Token [externos]
        const headers = !this.props.external ? {} : {
            Authorization: 'Bearer ' + this.state.extToken,
            'Content-Type': 'application/json',
            accept: 'application/json',
        };

        e.target.disabled = true;
        const loadingSpinner = e.target.querySelector('span');
        loadingSpinner.classList.remove('hidden');
        http.post(API_VERIFICATION_SAVE_B64, data, {
            onUploadProgress: (progressEvent) =>
            {
                const { loaded, total } = progressEvent;
                const percentCompleted = Math.round((loaded * 100) / total);
                this.setState({
                    uploadProgress: percentCompleted
                })
            },
            headers: headers,
        })
            .then((response) =>
            {
                this.setState({ validating: true });
                http.post(API_VERIFICATION_VERIFY, {
                    verificationId: response.data.data.verificationId,
                    uuid: response.data.data.uuid,
                    username: username,
                }, {
                    headers: headers
                })
                    .then((res) =>
                    {
                        this.props.navigate(`${ROUTE_VERIFICATION_MODULE}/${response.data.data.verificationId}${!this.props.external ? ROUTE_REL_VERIFICATION_RESULT : ROUTE_REL_VERIFICATION_RESULT_SUMMARY}?from=creation&username=${username}`);
                    })
                    .catch((error) =>
                    {
                        this.setState({
                            errMessages: error.response ? error.response.data?.messages ?? [{ code: "", message: "Error desconocido" }] : [{ code: "", message: "Error de red" }],
                        })
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                    })
                    .finally(() =>
                    {
                        this.setState({
                            validating: false,
                        });
                    })
            })
            .catch((error) =>
            {
                this.setState({
                    errMessages: error.response ? error.response.data?.messages ?? [{ code: "", message: "Error desconocido" }] : [{ code: "", message: "Error de red" }],
                })
                window.scrollTo({ top: 0, behavior: 'smooth' });
            })
            .finally(() =>
            {
                loadingSpinner.classList.add('hidden');
                e.target.disabled = false;
            });
    }

    getExternalData(auth)
    {
        this.setState({
            isLoadingRules: true,
        })
        http.post(API_SECURITY_AUTHENTICATE_EXTERNAL, {
            encryptedParams: auth,
        })
            .then((response) =>
            {
                const data = response.data.data;
                this.setState({
                    inputSelectedDocumentType: data.documentType,
                    inputNames: data.name ?? '',
                    inputFathersLastName: data.firstSurname ?? '',
                    inputMothersLastName: data.secondSurname ?? '',
                    inputDNI: data.documentNumber ?? '',
                    inputSelectedRule: data.ruleId,
                    extImageCode: data.imageCode,
                    extSourceCode: data.sourceCode,
                    extUrlObject: data.urlObject,
                    extUserCode: data.userCode,
                    extCharacteristics: data.characteristics,
                    extImageFormats: data.imageFormats,
                    extMinSizeY: data.minSizeY ?? 288,
                    extMinSizeX: data.minSizeX ?? 240,
                    extUsername: data.username,
                    extToken: data.token,
                })
            })
            .catch((error) =>
            {
                this.setState({
                    errMessages: error.response ? error.response.data?.messages ?? [{ code: "", message: "Error desconocido" }] : [{ code: "", message: "Error de red" }],
                    extRender: false,
                })
                window.scrollTo({ top: 0, behavior: 'smooth' });
            })
            .finally(() =>
            {
                this.setState({
                    isLoadingRules: false,
                })
            });
    }

    componentDidMount()
    {
        this.getDocumentTypeList();
        if (!this.props.external)
        {
            this.getRulesList();
        }
        else
        {
            const search = this.props.location.search;
            const params = new URLSearchParams(search);
            const auth = params.get('auth');
            if (auth)
            {
                this.getExternalData(auth);
            }
        }
    }

    handleDiscardChanges()
    {
        this.refBtnCancel.current.click();
    }

    preventChars = (e) =>
    {
        const key = e.key;
        if (!(key === 'Backspace' || key === 'ArrowLeft' || key === 'ArrowRight' || key === 'Tab'))
        {
            if (!/[0-9\b]/.test(key))
            {
                e.preventDefault();
            }
        }
    }

    render()
    {
        const handleInputChange = this.handleInputChange.bind(this);
        const handleDocumentTypeChange = this.handleDocumentTypeChange.bind(this);
        const characteristics = this.state.optionsRules.find(item => item.ruleId === this.state.inputSelectedRule)?.characteristics;
        const imageFormats = this.state.optionsRules.find(item => item.ruleId === this.state.inputSelectedRule)?.imageFormats;
        const minSizeX = this.state.optionsRules.find(item => item.ruleId === this.state.inputSelectedRule)?.minSizeX;
        const minSizeY = this.state.optionsRules.find(item => item.ruleId === this.state.inputSelectedRule)?.minSizeY;
        const handleDiscardChanges = this.handleDiscardChanges.bind(this);
        const handleViewRule = this.handleViewRule.bind(this);
        const preventChars = this.preventChars.bind(this);

        return (
            <>
                <Layout active={ROUTE_VERIFICATION_MODULE} external={this.props.external}>
                    {
                        this.state.isLoadingRules ?
                            <>
                                <Loading />
                            </> :
                            <>
                                {
                                    !this.props.external ?
                                        <>
                                            <div className="row mt-3">
                                                <div className="col">
                                                    <nav aria-label="breadcrumb">
                                                        <ol className="caption breadcrumb mb-0">
                                                            <li onClick={handleDiscardChanges} className="breadcrumb-item"><span style={{ color: 'rgba(var(--ul-link-color-rgb), var(--ul-link-opacity, 1))', cursor: 'pointer' }}>Histórico de verificaciones</span></li>
                                                            <li className="breadcrumb-item active" aria-current={"page"}>Nueva verificación</li>
                                                        </ol>
                                                    </nav>
                                                </div>
                                            </div>
                                        </> : <></>
                                }
                                <form>
                                    {
                                        !this.props.external ?
                                            <>
                                                <div className='row my-3'>
                                                    <div className='col'>
                                                        <h4 className='mb-0'>Nueva verificación</h4>
                                                    </div>
                                                </div>
                                            </> : <></>
                                    }
                                    <div className={`row mb-3 ${this.props.external ? 'mt-3' : ''}`}>
                                        <div className='col'>
                                            <div className="card search-filters-container">
                                                <div className="card-body">
                                                    {
                                                        !this.props.external && !this.state.optionsRules.length ?
                                                            <>
                                                                <div className="alert alert-danger mb-0" role="alert">
                                                                    No tiene reglas asignadas
                                                                </div>
                                                            </> :
                                                            <>
                                                                {
                                                                    this.state.validating ?
                                                                        <>
                                                                            <div>
                                                                                <div className="row loader-container d-flex">
                                                                                    <div className="loader mx-auto">
                                                                                    </div>
                                                                                    <div className="col w-100 position-absolute align-self-center text-center">
                                                                                        <p className="mb-0">Validando...</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </> :
                                                                        <>
                                                                            {
                                                                                this.state.extRender ?
                                                                                    <>
                                                                                        <div className='row row-cols-1 row-cols-sm-2 row-cols-md-3 g-2 mb-3'>
                                                                                            <div className="col">
                                                                                                <div className="form-floating">
                                                                                                    <select
                                                                                                        disabled={this.props.external}
                                                                                                        className={`form-select ${this.state.errDocumentType}`}
                                                                                                        placeholder='Tipo de documento'
                                                                                                        value={this.state.inputSelectedDocumentType}
                                                                                                        onChange={handleDocumentTypeChange}
                                                                                                    >
                                                                                                        <option value="" disabled hidden>Tipo de documento</option>
                                                                                                        {
                                                                                                            this.state.documentTypes.map((dtype, index) =>
                                                                                                            {
                                                                                                                return (
                                                                                                                    <option key={index} value={dtype.documentType}>{dtype.name}</option>
                                                                                                                )
                                                                                                            })
                                                                                                        }
                                                                                                    </select>
                                                                                                    <label>Tipo de documento</label>
                                                                                                    <div className="invalid-feedback">
                                                                                                        {this.state.errDocumentTypeMsg}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className='col'>
                                                                                                <div className="form-floating">
                                                                                                    <input disabled={this.props.external} className={`form-control ${this.state.errDNI}`} required type="text" placeholder="Número de documento" value={this.state.inputDNI} onChange={(e) => { handleInputChange('inputDNI', e.target.value); this.findPersonById(e.target.value) }} onKeyDown={preventChars} />
                                                                                                    <label>Número de documento</label>
                                                                                                    <div className="invalid-feedback">
                                                                                                        {this.state.errDNIMsg}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className='col'>
                                                                                                <div className="form-floating">
                                                                                                    <input disabled={this.props.external} className={`form-control ${this.state.errNames}`} type="text" required placeholder="Nombre(s)" value={this.state.inputNames} onChange={(e) => handleInputChange('inputNames', e.target.value)} />
                                                                                                    <label>Nombre(s)</label>
                                                                                                    <div className="invalid-feedback">
                                                                                                        {this.state.errNamesMsg}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className='col'>
                                                                                                <div className="form-floating">
                                                                                                    <input disabled={this.props.external} className={`form-control ${this.state.errFathersLastName}`} type="text" required placeholder="Primer apellido" value={this.state.inputFathersLastName} onChange={(e) => handleInputChange('inputFathersLastName', e.target.value)} />
                                                                                                    <label>Primer apellido</label>
                                                                                                    <div className="invalid-feedback">
                                                                                                        {this.state.errFathersLastNameMsg}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className='col'>
                                                                                                <div className="form-floating">
                                                                                                    <input disabled={this.props.external} className={`form-control ${this.state.errMothersLastName}`} type="text" required placeholder="Segundo apellido" value={this.state.inputMothersLastName} onChange={(e) => handleInputChange('inputMothersLastName', e.target.value)} />
                                                                                                    <label>Segundo apellido</label>
                                                                                                    <div className="invalid-feedback">
                                                                                                        {this.state.errMothersLastNameMsg}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            {
                                                                                                !this.props.external ?
                                                                                                    <>
                                                                                                        <div className='col'>
                                                                                                            <div className="form-floating">
                                                                                                                <input className={`form-control ${this.state.errPhone}`} type="tel" placeholder="Teléfono (opcional)" pattern="[0-9]{3}-[0-9]{3}-[0-9]{3}" value={this.state.inputPhone} onChange={(e) => handleInputChange('inputPhone', e.target.value)} />
                                                                                                                <label>Teléfono (opcional)</label>
                                                                                                                <div className="invalid-feedback">
                                                                                                                    El teléfono debe tener exactamente 9 dígitos numéricos
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className='col'>
                                                                                                            <div className="form-floating">
                                                                                                                <input className={`form-control ${this.state.errEmail}`} type="email" placeholder="Correo electrónico (opcional)" value={this.state.inputEmail} onChange={(e) => handleInputChange('inputEmail', e.target.value)} />
                                                                                                                <label>Correo electrónico (opcional)</label>
                                                                                                                <div className="invalid-feedback">
                                                                                                                    Ingrese un correo electrónico válido
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </> : <></>
                                                                                            }
                                                                                        </div>
                                                                                        {
                                                                                            !this.props.external ?
                                                                                                <>
                                                                                                    <div className="row">
                                                                                                        <div className="col-6 col-md-9 col-xl-10">
                                                                                                            <div className="form-floating">
                                                                                                                <select className="form-select" placeholder='Seleccione la regla que desea verificar' value={this.state.inputSelectedRule} onChange={(e) => this.handleRuleChange(e.target.value)} >
                                                                                                                    {
                                                                                                                        this.state.optionsRules && this.state.optionsRules.map((rule, index) =>
                                                                                                                        {
                                                                                                                            return (
                                                                                                                                <Fragment key={index}>
                                                                                                                                    <option value={rule.ruleId}>{rule.name}</option>
                                                                                                                                </Fragment>
                                                                                                                            )
                                                                                                                        })
                                                                                                                    }
                                                                                                                </select>
                                                                                                                <label>Seleccione la regla que desea verificar</label>
                                                                                                                <div className="invalid-feedback">
                                                                                                                    Debe seleccionar una regla
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="col-6 col-md-3 col-xl-2 align-self-center">
                                                                                                            <button type="button" onClick={() => handleViewRule()} className="btn btn-link w-100">Ver detalle</button>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    {
                                                                                                        this.state.optionsExternalSystems.length ?
                                                                                                            <>
                                                                                                                {
                                                                                                                    (this.state.optionsExternalSystems.length === 1) ?
                                                                                                                        <>
                                                                                                                            <div className="row mb-3">
                                                                                                                                <div className="col">
                                                                                                                                    <div className="form-text"><small>{this.state.optionsExternalSystems[0].name}</small></div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </> :
                                                                                                                        <>
                                                                                                                            <div className="row my-3">
                                                                                                                                <div className="col">
                                                                                                                                    <div className="form-floating">
                                                                                                                                        <select className="form-select" placeholder='Seleccione el sistema externo asignado a la regla' value={this.state.inputSelectedSystem} onChange={(e) => this.handleSystemChange(e.target.value)} >
                                                                                                                                            {
                                                                                                                                                this.state.optionsExternalSystems?.map((system, index) =>
                                                                                                                                                {
                                                                                                                                                    return (
                                                                                                                                                        <Fragment key={index}>
                                                                                                                                                            <option value={system.systemId}>{system.name}</option>
                                                                                                                                                        </Fragment>
                                                                                                                                                    )
                                                                                                                                                })
                                                                                                                                            }
                                                                                                                                        </select>
                                                                                                                                        <label>Seleccione el sistema externo asignado a la regla</label>
                                                                                                                                        <div className="invalid-feedback">
                                                                                                                                            Debe seleccionar un sistema externo
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </>
                                                                                                                }
                                                                                                            </> : <>
                                                                                                                <div className="row mb-3">
                                                                                                                    <div className="col">
                                                                                                                        <div className="form-text"><small>Esta regla no tiene sistemas externos</small></div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </>
                                                                                                    }
                                                                                                </> : <></>
                                                                                        }
                                                                                        <div className='row row-cols-1'>
                                                                                            <div className='col'>
                                                                                                {
                                                                                                    !this.props.external ?
                                                                                                        <>
                                                                                                            <p className="mb-1"><span className="subtitle-2">Características: </span><span className="body-2">{characteristics}</span></p>
                                                                                                            <p><span className="subtitle-2">Formatos permitidos: </span><span className="body-2">{imageFormats}</span></p>
                                                                                                        </> :
                                                                                                        <>
                                                                                                            <div className="row mb-3 g-3">
                                                                                                                <div className="col">
                                                                                                                    <p className="mb-1"><span className="subtitle-2">Características: </span><span className="body-2">{this.state.extCharacteristics}</span></p>
                                                                                                                    <p><span className="subtitle-2">Formatos permitidos: </span><span className="body-2">{this.state.extImageFormats}</span></p>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </>
                                                                                                }
                                                                                                {
                                                                                                    !this.props.external ?
                                                                                                        <>
                                                                                                            <UserAvatarEditor ref={this.refUserAvatarEditor} height={minSizeY ?? 288} width={minSizeX ?? 240} />
                                                                                                        </> :
                                                                                                        <>
                                                                                                            <UserAvatarEditor ref={this.refUserAvatarEditor} height={this.state.extMinSizeY} width={this.state.extMinSizeX} />
                                                                                                        </>
                                                                                                }
                                                                                                <div className={`${this.state.errFile.length ? "d-block" : ""} invalid-feedback text-center`}>{this.state.errFileMsg}</div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </> : <></>
                                                                            }
                                                                            <div className="row-row-cols-1">
                                                                                <div className="col">
                                                                                    {
                                                                                        (this.state.errMessages && this.state.errMessages.length) ? <div className="alert mt-3 alert-danger" role="alert">
                                                                                            {
                                                                                                this.state.errMessages.map((err, index) =>
                                                                                                {
                                                                                                    return (
                                                                                                        <Fragment key={index}>
                                                                                                            <p className='mb-0'> {err.code?.length ? `[${err.code}]` : ""} {err.message}</p>
                                                                                                        </Fragment>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </div> : <></>
                                                                                    }
                                                                                    {
                                                                                        this.state.extRender ?
                                                                                            <>
                                                                                                <div className="mt-3 d-flex justify-content-end">
                                                                                                    {
                                                                                                        !this.props.external ?
                                                                                                            <>
                                                                                                                <button onClick={handleDiscardChanges} type="button" className="btn btn-outline-primary me-2">Cancelar</button>
                                                                                                            </> : <></>
                                                                                                    }
                                                                                                    <button className="btn btn-primary" type="button" onClick={this.upload.bind(this)}>
                                                                                                        <span className="spinner-border spinner-border-sm me-2 hidden" role="status" aria-hidden="true"></span>
                                                                                                        Verificar
                                                                                                    </button>
                                                                                                </div>
                                                                                            </> : <></>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                }
                                                            </>
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <button ref={this.refBtnCancel} type="button" hidden className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#cancelCreationEditionModal">
                                    Cancel
                                </button>
                                <div className="modal fade" id="cancelCreationEditionModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="cancelCreationEditionModalLabel" aria-hidden="true">
                                    <div className="modal-dialog modal-dialog-centered">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className='mb-0' id="cancelCreationEditionModalLabel">Cancelar la nueva verificación</h5>
                                            </div>
                                            <div className="modal-body">
                                                <p className='mb-0'>
                                                    ¿Estás seguro(a) que deseas cancelar la nueva verificación?
                                                </p>
                                            </div>
                                            <div className="modal-footer">
                                                <button ref={this.refBtnCloseCancel} type="button" className="btn btn-outline-primary" data-bs-dismiss="modal">Cerrar</button>
                                                <button className="btn btn-primary" type='button' data-bs-dismiss="modal" onClick={() =>
                                                {
                                                    this.refBtnCloseCancel.current.click();
                                                    sessionStorage.removeItem("newVerificationFields");
                                                    this.props.navigate(ROUTE_VERIFICATION_MODULE);
                                                }}>Confirmar cancelación</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                    }
                </Layout>
            </>
        );
    }
}