export const ROUTE_LOGIN = '/login';
export const ROUTE_PROFILE = '/profile';
export const ROUTE_PASSWORD_UPDATE = '/password';

export const ROUTE_USER_MODULE = '/user';
export const ROUTE_REL_USER_CREATE = '/create';
export const ROUTE_REL_USER_EDIT = '/edit';
export const ROUTE_REL_USER_VIEW = '/view';

export const ROUTE_HOME = '/';
export const ROUTE_RULE_MODULE = '/rule';
export const ROUTE_REL_RULE_CREATE = '/create';
export const ROUTE_REL_RULE_EDIT = '/edit';
export const ROUTE_REL_RULE_VIEW = '/view';
export const ROUTE_REL_RULE_COPY = '/copy';

export const ROUTE_SYSTEM_MODULE = '/system';
export const ROUTE_REL_SYSTEM_CREATE = '/create';
export const ROUTE_REL_SYSTEM_EDIT = '/edit';
export const ROUTE_REL_SYSTEM_VIEW = '/view';

export const ROUTE_VERIFICATION_MODULE = '/verification';
export const ROUTE_REL_VERIFICATION_CREATE = '/verify';
export const ROUTE_REL_VERIFICATION_EXTERNAL_CREATE = '/verify/external';
export const ROUTE_REL_VERIFICATION_RESULT = '/result';
export const ROUTE_REL_VERIFICATION_RESULT_SUMMARY = '/result/summary';