import React, { Component, Fragment } from 'react';
import { v4 as uuidv4 } from 'uuid';
import './Parameter.css';
import parameterDefaultsData from './ParameterDefaultsData.json';

class Parameter extends Component
{
    constructor (props)
    {
        super(props);
        this.id = uuidv4();
        this.state = {
            selected: this.props.groupTypes.find(o => o.parameterId === (this.props.parameter ? (this.props.parameter.parameterId ?? 1) : 1)),
        }
    }

    static defaultProps = {
        ruleId: "",
        groupTypes: [],
        parameter: parameterDefaultsData,
        mode: "create",
    }

    preventChars = (e) =>
    {
        const key = e.key;
        if (!(key === 'Backspace' || key === 'ArrowLeft' || key === 'ArrowRight' || key === 'Tab'))
        {
            if (this.state.selected.measurementUnit === 'hex')
            {
                if (!/^[0-9a-fA-F#\b]$/.test(key))
                {
                    e.preventDefault();
                }
            }
            else
            {
                if (!/[0-9\b]/.test(key))
                {
                    e.preventDefault();
                }
            }
        }
    }

    handleChangeParameterSelect (e)
    {
        const { parameter, onChange } = this.props;
        const updatedParamState = { ...parameter, parameterId: parseInt(e.target.value), errorMessage: this.props.groupTypes.find(o => o.parameterId === parseInt(e.target.value)).errorMessage ?? "" };
        updatedParamState.minValue = '';
        updatedParamState.maxValue = '';
        updatedParamState.minX = '';
        updatedParamState.maxX = '';
        updatedParamState.minY = '';
        updatedParamState.maxY = '';
        this.setState({
            selected: this.props.groupTypes.find(o => o.parameterId === parseInt(e.target.value)),
        }, () => onChange(updatedParamState));
    }

    handleDataChange (key, value)
    {
        const { parameter, onChange } = this.props;
        if (this.state.selected.measurementUnit === "hex")
        {
            if (value && value.length === 6 && value.indexOf('#') === -1)
            {
                const updatedParamState = { ...parameter, [key]: `#${value}` };
                onChange(updatedParamState);
            }
            else
            {
                const updatedParamState = { ...parameter, [key]: value };
                onChange(updatedParamState);
            }
        }
        else
        {
            const updatedParamState = { ...parameter, [key]: value };
            onChange(updatedParamState);
        }
    }

    componentDidUpdate (prevProps)
    {
        if (prevProps.parameter !== this.props.parameter)
        {
            this.setState({
                selected: this.props.groupTypes.find(o => o.parameterId === (this.props.parameter ? (this.props.parameter.parameterId ?? 1) : 1)),
            })
        }
    }
    render ()
    {
        const handleDataChange = this.handleDataChange.bind(this);
        const preventChars = this.preventChars.bind(this);

        return (
            <>
                {
                    this.props.groupTypes.length ?
                        <>
                            <div className='row mb-3'>
                                <div className='col'>
                                    {
                                        this.props.mode === "view" ?
                                            <>
                                                <p className='mb-0'><span className='subtitle-2'>Nombre: </span><span className='body-2'>{this.props.parameter.parameterName}</span></p>
                                            </> : <>
                                                <div className="form-floating">
                                                    <select className="form-select" id={`groupTypeSelect${this.props.ruleId}${this.id}`}
                                                        value={this.state.selected.parameterId} onChange={this.handleChangeParameterSelect.bind(this)}>
                                                        {
                                                            this.props.groupTypes.map((parameter, index) =>
                                                            {
                                                                return (
                                                                    <Fragment key={index}>
                                                                        <option value={parameter.parameterId}>{parameter.name}</option>
                                                                    </Fragment>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    <label htmlFor={`groupTypeSelect${this.props.ruleId}${this.id}`}>Tipo de parámetro</label>
                                                </div>
                                            </>
                                    }
                                </div>
                            </div>
                            <div className='row g-3 mb-3'>
                                {
                                    this.props.mode === "view" ?
                                        <>
                                            {
                                                this.props.parameter.parameterType === 1 ?
                                                    <>
                                                        <div className='col'>
                                                            <p><span className='subtitle-2'>Eje X Mínimo - Máximo: </span><span className='body-2'>{this.props.parameter.rangeXValues}</span></p>
                                                            <p className='mb-0'><span className='subtitle-2'>Eje Y Mínimo - Máximo: </span><span className='body-2'>{this.props.parameter.rangeYValues}</span></p>
                                                        </div>
                                                    </> : <></>
                                            }
                                            {
                                                this.props.parameter.parameterType === 2 ?
                                                    <>
                                                        <div className='col'>
                                                            <p className='mb-0'><span className='subtitle-2'>Mínimo - Máximo: </span><span className='body-2'>{this.props.parameter.rangeValues}</span></p>
                                                        </div>
                                                    </> : <></>
                                            }
                                        </> :
                                        <>
                                            {
                                                this.state.selected.groupType === 1 ?
                                                    <>
                                                        <div className='col-2 col-md-1 align-self-center'>
                                                            Eje X:
                                                        </div>
                                                        <div className='col-10 col-md-5'>
                                                            <div className='row g-2'>
                                                                <div className='col'>
                                                                    <div className="form-floating">
                                                                        <input
                                                                            type="text" className="form-control"
                                                                            onKeyDown={preventChars}
                                                                            required
                                                                            autoFocus
                                                                            id={`minX${this.props.ruleId}${this.id}`}
                                                                            placeholder="Mínimo" value={this.props.parameter.minX ?? ''}
                                                                            onChange={(e) => handleDataChange('minX', e.target.value)} />
                                                                        <label htmlFor={`minX${this.props.ruleId}${this.id}`}>Mínimo ({this.state.selected.measurementUnit})</label>
                                                                        {
                                                                            this.props.parameter?.validationErrorEmptyMinX ?
                                                                                <>
                                                                                    <div className="d-block invalid-feedback">
                                                                                        Este campo es obligatorio
                                                                                    </div>
                                                                                </> : <></>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className='col'>
                                                                    <div className="form-floating">
                                                                        <input type="text" className="form-control"
                                                                            id={`maxX${this.props.ruleId}${this.id}`}
                                                                            required
                                                                            placeholder="Máximo" value={this.props.parameter.maxX ?? ''}
                                                                            onKeyDown={preventChars}
                                                                            onChange={(e) => handleDataChange('maxX', e.target.value)} />
                                                                        <label htmlFor={`maxX${this.props.ruleId}${this.id}`}>Máximo ({this.state.selected.measurementUnit})</label>
                                                                        {
                                                                            this.props.parameter?.validationErrorEmptyMaxX ?
                                                                                <>
                                                                                    <div className="d-block invalid-feedback">
                                                                                        Este campo es obligatorio
                                                                                    </div>
                                                                                </> : <></>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {
                                                                this.props.parameter?.validationErrorX ?
                                                                    <>
                                                                        <div className="d-block invalid-feedback">
                                                                            El valor mínimo debe ser menor que el valor máximo
                                                                        </div>
                                                                    </> : <></>
                                                            }
                                                        </div>
                                                        <div className='col-2 col-md-1 align-self-center'>
                                                            Eje Y:
                                                        </div>
                                                        <div className='col-10 col-md-5'>
                                                            <div className='row g-2'>
                                                                <div className='col'>
                                                                    <div className="form-floating">
                                                                        <input type="text" className="form-control"
                                                                            id={`minY${this.props.ruleId}${this.id}`}
                                                                            required
                                                                            placeholder="Mínimo" value={this.props.parameter.minY ?? ''}
                                                                            onKeyDown={preventChars}
                                                                            onChange={(e) => handleDataChange('minY', e.target.value)} />
                                                                        <label htmlFor={`minY${this.props.ruleId}${this.id}`}>Mínimo ({this.state.selected.measurementUnit})</label>
                                                                        {
                                                                            this.props.parameter?.validationErrorEmptyMinY ?
                                                                                <>
                                                                                    <div className="d-block invalid-feedback">
                                                                                        Este campo es obligatorio
                                                                                    </div>
                                                                                </> : <></>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className='col'>
                                                                    <div className="form-floating">
                                                                        <input type="text" className="form-control"
                                                                            id={`maxY${this.props.ruleId}${this.id}`}
                                                                            required
                                                                            placeholder="Máximo" value={this.props.parameter.maxY ?? ''}
                                                                            onKeyDown={preventChars}
                                                                            onChange={(e) => handleDataChange('maxY', e.target.value)} />
                                                                        <label htmlFor={`maxY${this.props.ruleId}${this.id}`}>Máximo ({this.state.selected.measurementUnit})</label>
                                                                        {
                                                                            this.props.parameter?.validationErrorEmptyMaxY ?
                                                                                <>
                                                                                    <div className="d-block invalid-feedback">
                                                                                        Este campo es obligatorio
                                                                                    </div>
                                                                                </> : <></>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {
                                                                this.props.parameter?.validationErrorY ?
                                                                    <>
                                                                        <div className="d-block invalid-feedback">
                                                                            El valor mínimo debe ser menor que el valor máximo
                                                                        </div>
                                                                    </> : <></>
                                                            }
                                                        </div>
                                                    </>
                                                    : <></>
                                            }
                                            {
                                                this.state.selected.groupType === 2 ?
                                                    <>
                                                        <div className='col'>
                                                            <div className={`form-floating ${this.state.selected.measurementUnit === "hex" ? 'ul-custom-colorpicker' : ''}`}>
                                                                <input
                                                                    type="text"
                                                                    style={{ textTransform: 'uppercase' }}
                                                                    onKeyDown={preventChars}
                                                                    maxLength={this.state.selected.measurementUnit === "hex" ? 7 : undefined}
                                                                    className="form-control"
                                                                    required
                                                                    autoFocus
                                                                    id={`minValue${this.props.ruleId}${this.id}`}
                                                                    placeholder="Mínimo" value={this.props.parameter.minValue}
                                                                    onChange={(e) => handleDataChange('minValue', e.target.value)}
                                                                />
                                                                {
                                                                    this.state.selected.measurementUnit === "hex" ?
                                                                        <>
                                                                            < input
                                                                                type="color" className="form-control form-control-color"
                                                                                required
                                                                                id={`minValue${this.props.ruleId}${this.id}`}
                                                                                placeholder="Mínimo" value={this.props.parameter.minValue}
                                                                                onChange={(e) => handleDataChange('minValue', e.target.value)}
                                                                            />
                                                                        </> : <></>
                                                                }
                                                                <label htmlFor={`minValue${this.props.ruleId}${this.id}`}>Mínimo ({this.state.selected.measurementUnit})</label>
                                                                {
                                                                    this.props.parameter?.validationErrorEmptyMinValue ?
                                                                        <>
                                                                            <div className="d-block invalid-feedback">
                                                                                Este campo es obligatorio
                                                                            </div>
                                                                        </> :
                                                                        this.props.parameter?.validationErrorInvalidHexMinValue ?
                                                                            <>
                                                                                <div className="d-block invalid-feedback">
                                                                                    Debe tener el formato #RRGGBB
                                                                                </div>
                                                                            </> : <></>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className='col'>
                                                            <div className={`form-floating ${this.state.selected.measurementUnit === "hex" ? 'ul-custom-colorpicker' : ''}`}>
                                                                <input type="text" className="form-control"
                                                                    onKeyDown={preventChars}
                                                                    style={{ textTransform: 'uppercase' }}
                                                                    maxLength={this.state.selected.measurementUnit === "hex" ? 7 : undefined}
                                                                    id={`maxValue${this.props.ruleId}${this.id}`}
                                                                    required
                                                                    placeholder="Máximo" value={this.props.parameter.maxValue}
                                                                    onChange={(e) => handleDataChange('maxValue', e.target.value)} />
                                                                {
                                                                    this.state.selected.measurementUnit === "hex" ?
                                                                        <>
                                                                            <input type="color" className="form-control form-control-color"
                                                                                id={`maxValue${this.props.ruleId}${this.id}`}
                                                                                required
                                                                                placeholder="Máximo" value={this.props.parameter.maxValue}
                                                                                onChange={(e) => handleDataChange('maxValue', e.target.value)} />
                                                                        </> : <></>
                                                                }
                                                                <label htmlFor={`maxValue${this.props.ruleId}${this.id}`}>Máximo ({this.state.selected.measurementUnit})</label>
                                                                {
                                                                    this.props.parameter?.validationErrorEmptyMaxValue ?
                                                                        <>
                                                                            <div className="d-block invalid-feedback">
                                                                                Este campo es obligatorio
                                                                            </div>
                                                                        </> :
                                                                        this.props.parameter?.validationErrorInvalidHexMaxValue ?
                                                                            <>
                                                                                <div className="d-block invalid-feedback">
                                                                                    Debe tener el formato #RRGGBB
                                                                                </div>
                                                                            </> : <></>
                                                                }
                                                            </div>
                                                        </div>
                                                    </> : <></>
                                            }
                                        </>
                                }
                            </div>
                            <div className='row'>
                                <div className='col'>
                                    {
                                        this.props.mode === "view" ?
                                            <>
                                                <p className='mb-0'><span className='subtitle-2'>Mensaje de error de la regla: </span><span className='body-2'>{this.props.parameter.errorMessage}</span></p>
                                            </> :
                                            <>
                                                <div className="form-floating">
                                                    <input type="text" className="form-control"
                                                        id="errorMessageInput" placeholder="Mensaje de error de la regla"
                                                        required
                                                        value={this.props.parameter.errorMessage}
                                                        onChange={(e) => handleDataChange('errorMessage', e.target.value)}
                                                        aria-labelledby="errorMessageHelp"
                                                    />
                                                    <label htmlFor="errorMessageInput">Mensaje de error de la regla</label>
                                                    {
                                                        this.props.parameter?.validationErrorMsgError ?
                                                            <>
                                                                <div className="d-block invalid-feedback">
                                                                    Este campo es obligatorio
                                                                </div>
                                                            </> : <></>
                                                    }
                                                    <div id="errorMessageHelp" className="form-text">
                                                        Este mensaje se mostrará en caso la imagen no cumpla con este parámetro.
                                                    </div>
                                                </div>
                                            </>
                                    }
                                </div>
                            </div>
                        </> : <></>
                }
            </>
        );
    }
}

export default Parameter;