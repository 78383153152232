import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import SidebarItems from './SidebarItems';
import { Roles } from '../../constant/Enums';
import ULLogo from '../../content/images/ul-logo-colorful.svg';
import './layout.css';
import DefaultProfile from './defaultprofile.svg';
import { ROUTE_HOME } from '../../constant/AppRoutes';

class Layout extends Component
{
    static defaultProps = {
        noSidebar: false,
        external: false,
    }

    signOut ()
    {
        localStorage.removeItem('username');
        localStorage.removeItem('fullName');
        localStorage.removeItem('roles');
        window.location.reload();
    }

    render ()
    {
        const fullName = localStorage.getItem('fullName');

        let userRoles = []
        try
        {
            userRoles = JSON.parse(localStorage.getItem('roles'));
            !userRoles && (userRoles = []);
        }
        catch {
            console.log("No se han definido roles para este usuario")
        }

        let rolesStr = '';
        if (!this.props.external)
            rolesStr = userRoles.map(role => role.name)?.join(', ') ?? '';

        return (
            <>
                <div className={`${this.props.noSidebar ? "no-sidebar" : ""}`}>
                    <nav className="navbar app-navbar fixed-top">
                        <div className="container-fluid p-3 d-flex justify-content-between">
                            <div className='d-flex justify-content-start'>
                                <Link to={ROUTE_HOME}>
                                    <img className="app-logo" src={ULLogo} alt="" />
                                </Link>
                                <h5 className='mb-0 ms-4 align-self-center'>Sistema verificador de fotos</h5>
                            </div>
                            {
                                !this.props.external ?
                                    <>
                                        <div>
                                            <div className="dropdown">
                                                <button className="menu-btn d-flex align-items-center link-body-emphasis text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <img src={DefaultProfile} alt="" className="user-profile-pic me-2" />
                                                    <span className='d-none d-sm-block'>
                                                        <strong className='d-block'>{fullName}</strong>
                                                        <small className='d-block'>{rolesStr}</small>
                                                    </span>
                                                </button>
                                                <ul className="dropdown-menu dropdown-menu-end text-small shadow">
                                                    <li><Link to={'/profile'} className="dropdown-item"><i className="bi bi-person-circle me-2"></i>Perfil</Link></li>
                                                    <li><button className="dropdown-item" onClick={this.signOut}><i className="bi bi-door-closed me-2"></i>Cerrar sesión</button></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </> : <></>
                            }
                        </div>
                    </nav>
                    {
                        !this.props.external ?
                            <>
                                <div className='sidebar-container shadows z-2 border-end'>
                                    <div className="d-flex flex-shrink-0 app-sidebar">
                                        <button className='btn mt-4'>
                                            <span className='subtitle-1'>Menú</span>
                                        </button>
                                        <ul className="nav d-block mb-auto text-center sidebar-options">
                                            {
                                                SidebarItems.map((item, i) =>
                                                {
                                                    return (
                                                        <Fragment key={i}>
                                                            {
                                                                item.allowed.includes(Roles.ALL) || userRoles.some(role => item.allowed.includes(role.roleKey)) ?
                                                                    <>
                                                                        <li className={`mx-0 nav-item ${item.to === this.props.active ? 'active' : ''}`}>
                                                                            <Link to={item.to}
                                                                                className="nav-link"
                                                                                aria-current="page">
                                                                                <i className={`d-block bi ${item.icon}`}></i>
                                                                                <span className='d-block nav-item-label'>{item.label}</span>
                                                                            </Link>
                                                                        </li>
                                                                    </> : <></>
                                                            }
                                                        </Fragment>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>
                                <div className='main-container'>
                                    <div className='container-fluid'>
                                        {this.props.children}
                                    </div>
                                </div>
                            </> : <>
                                <div className='container-fluid' style={{ paddingTop: 'var(--ul-navbar-height)' }}>
                                    {this.props.children}
                                </div>
                            </>
                    }
                </div>
            </>
        )
    }
}
export default Layout;